import React from "react";
import DataContainer from "../components/dataContainer/DataContainer";
import { Typography } from "antd";

const TnC = () => {
  return (
    <div className="tnc-root">
      <DataContainer
        title={"Terms & Condition"}
        description={
          <>
            <Typography>
            The website, https://www.camellias.co.in/ (“Site”) is an electronic web based platform basically for online payments. It is owned by the Camellia, having its registered office at First Floor Devika Tower, Nehru Place New Delhi – 110019 and headquartered at B-121, Sector-5, Noida – 201301, Email: customercare@XXXX .
            </Typography>
            <Typography>
            Any and all use of this website is subject to, and constitutes acknowledgment and acceptance of, the following Terms and Conditions (“Terms”). It is mandatory for all Users of the Site to have read carefully, fully understood and be in total agreement to the below mentioned Terms before they proceed to use any of the services of the Site (“Services”). The Services are available only to those individuals, who are registered property holders in the Camellia.
            </Typography>
            <Typography className="data-container-heading">
            DEFINITION CLAUSE:-
            </Typography>
            <Typography>
            User: For purposes of this Agreement, a “User” is any person who accesses the Site for whatever purpose. A User includes the person using this Site and any legal entity which may be represented by such person under actual or apparent authority.
            </Typography>
            <Typography>
            Un-Authorized User: – Any person who is not a registered user, will fall within the definition of an ‘unauthorized User’ and will be subject to the terms and conditions, and expressly so with respect to respecting the intellectual property rights of the provider, and abiding by licensing terms and conditions.
            </Typography>
            <Typography>
            Access to the Site and certain features are provided to all Users. However, the Site reserves the right, without prior notice, to restrict access to certain areas or features of the Site to Users who undergo a specific registration process. 
            </Typography>
            <Typography>
            The term RERDA shall mean and include the Real Estate (Regulation and Development) Act, 2016 as amended read with any rules or regulations that might be framed thereunder. By using the Site and its Services, the Users represent and warrant that (a) All registration information submitted is truthful and accurate; (b) The User will maintain the accuracy of such information; (c) The User is 18 years of age or older; and (d) The use of the Site does not violate any applicable law or regulation. (e) User’s profile may be deleted / terminated without warning, if we believe that user is in breach of any of the above terms. (f) In no event the Site will be liable for any damages including, without limitation, indirect or consequential damages, or any damages whatsoever arising from use or loss of use, data, or profits, whether in action of contract, negligence or other tortuous action, arising out of or in connection with the use of the site.
            </Typography>
            <Typography>
            That the Users agree to use this site only for lawful purposes, and in a manner which does not infringe the rights of, or restrict or inhibit the use and enjoyment of this site by any third party. Such restriction or inhibition includes, without limitation, conduct which is unlawful, or which may harass or cause distress or inconvenience to any person and the transmission of obscene or offensive content or disruption of normal flow of dialogue within this site. If any User/individual; /entity become aware of any inappropriate content by any User of this site, or otherwise please contact us by clicking on the “Feedback” tab of the website.
            </Typography>
            <Typography>
            The Site has no expertise in the domain of intellectual property rights of anyone. It is beyond our scope to verify that the User of the Site have posted ONLY products and services on which they have complete authorization / ownership / dealership / selling rights. As an INTERMEDIARY, our role is limited and in case you have any concern related to copyright infringement then we shall appreciate if the same is to be brought to our notice. The Site neither advocates / endorses such products that may be deemed to be infringing by the virtue of their being listed on the Site nor does it endorse the infringement by removing such listings.
            </Typography>
            <Typography>
            That the Site shall not be liable for any such information or data which is not within its knowledge / acknowledgment / contraventions / copyright issues committed by its Users and which is beyond the control of verification.
            </Typography>
            <Typography>
            That the Site shall have a sole discretion regarding deletion of his User’s classifieds/listing/if the same is found to be in contravention of the various copyright right/intellectual property right rights of third party. That the present clause is in compliance with the INFORMATION TECHNOLOGY ACT 2000 and hence no discussion/argument shall be entertained in any case and in any circumstance.
            </Typography>
            <Typography>
            This Agreement applies to Service in addition to any terms and conditions that may be applicable Service provided, however, that in the event of any conflict or inconsistency between any provision of the terms and conditions that may be applicable to such Paid Service and any provision of this Agreement, such conflict or inconsistency shall (except as otherwise expressly provided or agreed) be resolved in a manner favorable to the Camellias/and/or its affiliates; and only to the extent that such conflict or inconsistency cannot be so resolved, the provisions of the terms and conditions applicable to such specific Service shall prevail.
            </Typography>
            <Typography>
            The Camellias may amend this Agreement at any time by posting the amended and restated Agreement on the Site. The amended and restated Agreement shall be effective immediately upon posting. Posting by the Camellias of the amended and restated Agreement and your continued use of the Site shall be deemed to be acceptance of the amended terms. 
            </Typography>
            <Typography>
            Users be aware that the servers belongs to third party and the continuation to access the services are subject to availability as the same is interrupted at times by technical problems etc. and the Camellias or the parent company is not responsible for the costs/damages/extension of activation period etc. for the same in any manner under any circumstances. The Users are advised to spool the messages etc. offline and save them elsewhere in order to avoid any technical problem.
            </Typography>
            <Typography>
            Users agree to accept communications (through Calls/Chat/ Mails/SMS) on the numbers made available during registration or subsequently, via registration forms, posting requirements, feedbacks or any such form that has provision for phone number / mobile number irrespective of being on Do Not Call Registry; which include company / your number / an assigned point of contact; with respect to the subscribed services of the Camellias.
            </Typography>
            <Typography className="data-container-heading">
            USER’S OBLIGATIONS
            </Typography>
            <Typography>
            The User agrees and expressly states that he/she is solely responsible for the accuracy and completeness of the Data and other information given to the Company in the application to use the Service. The User will ensure that the data shared with https://www.camellias.co.in/ doesn’t contain any fraudulent, false, misleading or manipulated facts.
            </Typography>
            <Typography>
            The Site may also require the User to support his/her claims with respect to the status of the property with such documents as may be specified by it from time to time.
            </Typography>
            <Typography>
            The User is responsible for the set-up or configuration of all the necessary equipment required to access the Service.
            </Typography>
            <Typography>
            The User will comply with all notices or instructions given by the Company from time to time in respect of the use of the Service.
            </Typography>
            <Typography>
            The User shall be solely responsible for all information retrieved, stored and transmitted through the Service by him.
            </Typography>
            <Typography>
            The User is solely responsible for the maintenance of confidentiality of the provided information.
            </Typography>
            <Typography>
            The User will immediately notify the Company of any unauthorized use of the User’s account or any other breach of security known to the User.
            </Typography>
            <Typography>
            The User will take all such measures as may be necessary.
            </Typography>
            <Typography className="data-container-heading">
            INTELLECTUAL PROPERTY RIGHTS
            </Typography>
            <Typography>
            All logos, brands, trademarks, registered marks (“Marks”) appearing in https://www.camellias.co.in/ are the properties either owned or used under license by the Company and / or its associates. All the rights accruing from the same, statutory or otherwise and intellectual property rights wholly vest with the Company / its associates. All rights not otherwise claimed under this Agreement or by the Company / its associates are hereby reserved.
            </Typography>
            <Typography>
            The User will not allow any person other than the authorized person(s) named in the application form to use the Service.
            </Typography>
            <Typography>
            The User shall use the Service only for the purpose for which it is subscribed.
            </Typography>
            <Typography>
            The User will comply with all applicable laws and shall not contravene any applicable law of India relating to the Services, including any regulation made pursuant thereto.
            </Typography>
            <Typography>
            The User shall not use the Service for any unlawful purpose.
            </Typography>
            <Typography>
            The User is prohibited to introduce, upload, post, distribute, publish or transmit any information or software that:
            </Typography>
            <Typography>
            Contains a virus, worm or other harmful component into the Internet or https://www.camellias.co.in/ network system.
            </Typography>
            <Typography>
            Breaches on any intellectual property rights of any person or retain information in any computer system or otherwise with such intention.
            </Typography>
            <Typography>
            Is unlawful, or which may potentially be perceived as being abusive, defamatory, libelous, harmful, threatening, harassing, vulgar, obscene, or racially, ethnically, or otherwise objectionable.
            </Typography>
            <Typography>
            Is meant to mislead or deceive the other Users
            </Typography>
            <Typography>
            Infringes any trademark, patent or copyright rights
            </Typography>
            <Typography>
            In the event of a violation of any of the above mentioned covenants by the User, and same comes to the Company’s knowledge, the Company shall have the right to delete any material relating to the violations without prior notice to the User. The Company shall issue a warning to the User to discontinue any activity, which leads to the said violations, and in the event the User continues with such activity, the Company has the sole authority to terminate or suspend the membership at https://www.camellias.co.in/ and/or any other related facility. In addition to the right to indemnity available to the Company, the Company shall have the right to any legal remedy, against the User to recover the loss suffered by the Company and the harm caused to the goodwill of the Company, due to such violation by the User.
            </Typography>
            <Typography className="data-container-heading">
            COPYRIGHT POLICY
            </Typography>
            <Typography>
            You may not post, distribute, or reproduce in any way copyrighted material, trademarks, or other proprietary information without obtaining the prior written consent of the owner of such proprietary rights. Without limiting the foregoing, if you believe that your work has been copied and posted on the  https://www.camellias.co.in/ service in a way that constitutes copyright infringement, please provide our Copyright Agent with the following information: an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest; a description of the copyrighted work that you claim has been infringed; a description of where the material that you claim is infringing and located at our Site; your address, telephone number, and email address; a written statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law; a statement by you, made under penalty of perjury, that the above information in your Notice is accurate and that you are the copyright owner or authorized to act on the copyright owner’s behalf
            </Typography>
            <Typography className="data-container-heading">
            CONFIDENTIALITY
            </Typography>
            <Typography>
            As https://www.camellias.co.in//is an online platform so it cannot guarantee confidentiality of information provided by Users who use the Site, so any breach in privacy due to technical fault or any other means is not the responsibility of the Camellias
            </Typography>
            <Typography>
            The User has entitled access to his own data and information stored in the database at https://www.camellias.co.in/ (subject to prior confirmation of identity) and may edit or amend such data and information at any time.
            </Typography>
            <Typography>
            The Users need to be aware that when they voluntarily reveal identification oriented information (name, e-mail address) to anyone resulting in unsolicited messages from third parties then such interaction is beyond the control and liability of https://www.camellias.co.in/
            </Typography>
            <Typography>
            https://www.camellias.co.in/is liable to share all information available with it in response to any legal proceedings including and not restricted to court orders, notices, subpoena, FIR etc.
            </Typography>
            <Typography>
            The copyright, know how or any other related intellectual property to the Service or https://www.camellias.co.in//shall be the sole and exclusive property of the Company. In the event the User has contributed any content to https://www.camellias.co.in//in any manner whatsoever, the intellectual property of the same shall stand automatically assigned to the Company and the User shall have no right or claim over the same. In the event the User during the term of his agreement or any time thereafter uses such intellectual property in any other website or related activity, the same can be construed to be an infringement of the intellectual property belonging to the Company and the Company shall have the right to legal recourse in this regard.
            </Typography>
            <Typography className="data-container-heading">
            LIMITATION OF LIABILITY
            </Typography>
            <Typography>
            https://www.camellias.co.in/ only offers a platform to showcase the property / project listings and has no role in any transaction or communications and hence shall not be liable for any loss or damage of any sort arising out of the use and/or temporary or permanent discontinuation of its services. 
            </Typography>
            <Typography>
            Notwithstanding anything to the contrary contained herein, https://www.camellias.co.in/, liability to you for any cause whatsoever, and regardless of the form of the action, will at all times be limited to the amount paid, if any, by you tothe Camellias, for the Electric meter recharge Service.
            </Typography>
            <Typography>
            The User is warned against disclosure of any personal / confidential / sensitive information to any previous or current employee of the company. The User would do so solely at his / her own risk and Site / Company shall not be liable for the outcome of any such transaction of information including and not limited to damages for loss of profits or savings, business interruption, loss of information or repute.
            </Typography>
            <Typography>
            The User hereby agrees to take full responsibility to research well before making use of any information on the Site. https://www.camellias.co.in/ shall not be liable for any loss or damage of any sort arising out of the use and/or temporary or permanent discontinuation of its services. Notwithstanding anything to the contrary contained herein, https://www.camellias.co.in/ liability to you for any cause whatsoever, and regardless of the form of the action, will at all times be limited to the amount paid, if any, by you to https://www.camellias.co.in/, for the Service provided.
            </Typography>
            <Typography className="data-container-heading">
            DISCLAIMER
            </Typography>
            <Typography>
            The Company shall not be liable for any loss of information howsoever caused whether as a result of any interruption, suspension, or termination of the Service or otherwise, or for the contents, accuracy or quality of information available, received or transmitted through the Service.
            </Typography>
            <Typography>
            The User shall be solely responsible, and the Company shall not be liable in any manner whatsoever, for ensuring that in using the Service, all applicable laws, rules and regulations for the use of systems, service or equipment shall be at all times complied with.
            </Typography>
            <Typography>
            The Company makes no representations and warranties of any kind, whether expressed or implied, for the Services and in relation to the accuracy or quality of any information transmitted or obtained through the Services of the Camellias
            </Typography>
            <Typography>
            The Company shall not be liable for any loss or damages sustained by reason of any disclosure (inadvertent or otherwise) of any information concerning the User’s account and particulars nor for any error, omission or inaccuracy with respect to any information so disclosed.
            </Typography>
            <Typography>
            The Company neither guarantees nor offers any warranty about the credentials, status or authenticity of the buyer/seller/real estate agent / builder etc. contacting the Users after accessing the information/data posted by the Users.
            </Typography>
            <Typography>
            The Company does not warrant that https://www.camellias.co.in/or any of the web sites linked to https://www.camellias.co.in/ be free of any operational errors or that it will be free of any virus, worm, or other harmful component.
            </Typography>
            <Typography>
            The User acknowledges that it is not the Company’s policy to exercise editorial control over and to edit or amend any data or contents of any emails or posting or any information that may be inserted or made available or transmitted to a third party in or through https://www.camellias.co.in/. The Company may refuse, suspend, terminate, delete or amend any artwork, materials, information or content of any data or information or posting so as, in the sole opinion of the Company, to comply with the legal or moral obligations as placed on the Company and to avoid infringing a third party’s rights or any other rules, standards or codes of practices that may be applicable to the posting or https://www.camellias.co.in/ or the internet. It is the sole prerogative and responsibility of the User to check the authenticity of all or any response/ inquiry received or information displayed. https://www.camellias.co.in/is a public site with free access and assumes no liability for the quality and genuineness of responses.
            </Typography>
            <Typography>
            The data fed by the User can be updated by the User alone and is solely their liability so https://www.camellias.co.in/is not responsible for the updated status of the information.
            </Typography>
            <Typography>
            The User shall ensure that while using the Service, all prevailing and applicable laws, rules and regulations, directly or indirectly for the use of systems, service or equipment shall at all times, be strictly complied with by the User and the Company shall not be liable in any manner whatsoever for default of any nature regarding the same, by the User.
            </Typography>
            <Typography>
            The Site is controlled and operated from India and the Site makes no representation that the materials are appropriate or will be available for use in other parts of the World. If you use this Site from outside India, you are entirely responsible for compliance with all applicable local laws as well as international conventions and treats.
            </Typography>
            <Typography>
            Users need to make necessary background checks and verification on any information provided by the Users related to the Projects / Properties before making investments. https://www.camellias.co.in/assumes no liability for the quality and genuineness of the Projects / Properties displayed.
            </Typography>
            <Typography className="data-container-heading">
            VARIATION
            </Typography>
            <Typography>
            The Company reserves the right to amend the terms and conditions contained herein and in the Services Guide at any time upon notice (in such form as may be determined by the Company) to the User.
            </Typography>
            <Typography>
            The Terms and Conditions of this agreement will be updated from time to time and posted atthe Camellias. The User should visit the site periodically to review the Terms and Conditions. For the avoidance of doubt, the User’s continued use of the Service constitutes an affirmation and acknowledgement of the amended terms and conditions.
            </Typography>
            <Typography className="data-container-heading">
            PAYMENT POLICY
            </Typography>
            <Typography>Payment for Maintenance/Recharge:</Typography>
            <Typography>
            All payments for services at https://www.camellias.co.in/have to be made through tab “Pay Only”. We have not authorized any individual or organization to collect payments in any other name You are informed that under no circumstances will https://www.camellias.co.in/be liable for any damage caused due to your transactions / payments made to / in favour of such fraudulent individuals or organizations. To protect your interests, please contact us at customercare@XXXXX (Mob-7XXXXXXXXX) immediately if any such fraudulent individual or organization tries to mislead you.
            </Typography>
            <Typography className="data-container-heading">
            USER DISPUTES
            </Typography>
            <Typography>
            The Users are solely responsible for your interactions at https://www.camellias.co.in/and they reserve the right, but have no obligation, to monitor disputes between Members.
            </Typography>
            <Typography>
            The Camellias will not be party to any legal proceedings between User and any other registered User for any transactions through the Site. https://www.camellias.co.in/ will always abide by governing / legal authorities order and cooperate fully through the process, but the costs of the same shall be recovered from the party that has implicated the Camelliasin such case.
            </Typography>
            <Typography>
            Note: Being an Intermediary, the role of https://www.camellias.co.in/is limited to provide an online platform that is acting in the capacity of a search engine to make their maintenance and other related payments. The Users would be responsible for all necessary verifications prior to any transaction(s). We do not guarantee, control, be party in manner to any of the Users and shall neither be responsible nor liable for any disputes / damages / disagreements arising from any transactions.
            </Typography>
            <Typography className="data-container-heading">
            REFUNDS & CANCELLATIONS
            </Typography>
            <Typography>
            All sales of prepaid recharge on the Platform are final and no refund or exchange is permitted in case of wrong details by the user. Users are also responsible for the safe custody of information relating to 20 digit numeric code handed over by this office.the Camellias is not responsible for any purchase of prepaid recharge for an incorrect mobile number or account number or any other incorrect information. However, if in a transaction performed by any user on the Platform, money has been charged to your card or bank account and a recharge is not delivered within 24 hours of the completion of the transaction, then you shall inform us by sending an e mail to our customer services e-mail address mentioned on the ‘Contact Us’ page on the web platform. Please include in the e-mail the following details –the mobile number (or account number or data card information), operator name, recharge value, transaction date and order number. This office will investigate the incident and, if it is found that money was indeed charged to your card or bank account without delivery of the recharge, then you will be refunded the money within/ less than 7 working days from the date of receipt of your e mail.
            </Typography>
            <Typography>
            In case of vacation / non- consumption of the amount paid, the refund will be issued to user by the (Email Address of Merchant). All requests should be addressed to the official mail ID (helpdesk@XXXX). Time period for the refund in these cases will be similar to the conditions mentioned above. No transaction by cash for the refund will be carried out.
            </Typography>
            <Typography>
              <ul>
                <li>
                Transaction fees charged would be borne by cardholder for any payment.
                </li>
                <li>
                Transaction fee charges would not be refunded/ reversed under any circumstances for any refund/ reversal /chargeback and any other reasons.
                </li>
                <li>
                Fees once paid which are nonrefundable for any reason or any clause.
                </li>
                <li>
                Just for your information :-
                </li>
                <li>
                Doing Recharge using (Merchant Website Address) agrees for terms & condition and other payments.
                </li>
                <li>
                All refunds will be credited to same account as used for recharge. It will take 3-21 working days for the money to show in your bank account depending on the user’s bank’s policy.
                </li>
              </ul>
            </Typography>
          </>
        }
      />
    </div>
  );
};

export default TnC;
