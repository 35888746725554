import React, { useEffect } from "react";
import { Divider, Typography, Tooltip } from "antd";
import { useSelector } from "react-redux";
import {
  generateDynamicStyling,
  numberToCurrency,
} from "../../../../Utils/utils";
import { selectCurrencyCode } from "../../../../slices/PaymentSessionDetailsSlice";
import getSymbolFromCurrency from "currency-symbol-map";
import { selectIsMobileLayout } from "../../../../slices/MobileLayoutSlice";

export default function ProductOrderSummary() {
  const sessionDetails = useSelector(
    (state) => state.paymentSessionDetails.sessionDetails
  );
  const isMobileLayout = useSelector((state) => selectIsMobileLayout(state));

  const currencyCode = useSelector((state) => selectCurrencyCode(state));
  const timeConvert = (dates, locale) => {
    const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const timeZoneAbbreviation = browserTimeZone === "GMT" ? "GMT" : "IST";
    locale = locale.split("_").join("-");
    const date = new Date(dates);
    let istOptions = {
      timeZone: browserTimeZone,
      weekday: "short",
      day: "numeric",
      month: "short",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    let Time;
    Time = date.toLocaleString(locale, istOptions);
    let arr = Time.split(",");
    let formattedTime = `${arr.pop()} ${timeZoneAbbreviation}`;
    arr = [arr.join(" "), formattedTime.toUpperCase()];
    return arr;
  };
  const currencySymbol = sessionDetails?.paymentDetails?.money?.currencySymbol;
  useEffect(() => {
    if (sessionDetails) {
      generateDynamicStyling(sessionDetails);
    }
  }, [sessionDetails]);

  return (
    <>
      {sessionDetails?.paymentDetails?.order?.items?.length > 0 && (
        <>
          {!isMobileLayout && (
            <div className="deliveryAddressHeader">
              <div className="headerDiv">
                <img
                  src="/assets/cardHeader/OrderSummary.svg"
                  alt="card"
                  style={{ height: "16px", widht: "16px" }}
                />
                <Typography className="deeliveryHeading dynamic-font-family">
                  Order Summary
                </Typography>
              </div>
            </div>
          )}
          <div className="orderSummaryCard">
            <div className="orderSummaryScroll">
              {sessionDetails?.paymentDetails?.context?.orderId && (
                <div style={{ display: "flex", gap: "4px" }}>
                  <Typography
                    className="dynamic-font-family payment-link-productName"
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    Ref. Id:
                  </Typography>
                  <Tooltip
                    placement="topLeft"
                    title={sessionDetails?.paymentDetails?.context?.orderId}
                  >
                    <Typography className="dynamic-font-family payment-link-productName payment-link-orderid">
                      {sessionDetails?.paymentDetails?.context?.orderId}
                    </Typography>
                  </Tooltip>
                </div>
              )}
              {sessionDetails?.paymentDetails?.order?.items.map((item, idx) => {
                const isLastItem =
                  idx === sessionDetails.paymentDetails.order.items.length - 1;

                return (
                  <div key={idx} style={{ marginTop: "4px" }}>
                    <div className="mainOrderDiv">
                      <div className="displayFlex">
                        {item?.imageUrl?.length ? (
                          <div className="imageAndQuantityDiv">
                            <img
                              src={item?.imageUrl}
                              alt="item"
                              className="itemImage"
                            />
                            <div>
                              <Typography className="dynamic-font-family extraSmallBold quantityStyling">
                                {item?.quantity}
                              </Typography>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div>
                          <Tooltip placement="topLeft" title={item?.itemName}>
                            <Typography className="dynamic-font-family semiBold14Heading itemTitle productItemNameEllipse ">
                              {item?.itemName}
                            </Typography>
                          </Tooltip>

                          {item?.size && (
                            <div className="displayFlex">
                              <Typography className="dynamic-font-family regular14Heading orderItemHeading">
                                Size:
                              </Typography>
                              <Typography className="dynamic-font-family regular14Heading subHeadingValue marginLeft">
                                {item?.size}
                              </Typography>
                            </div>
                          )}
                          {item?.quantity > 0 && !item?.imageUrl?.length ? (
                            <div className="displayFlex">
                              <Typography className="dynamic-font-family regular14Heading orderItemHeading">
                                Quantity:
                              </Typography>
                              <Typography className="dynamic-font-family regular14Heading subHeadingValue marginLeft">
                                {item?.quantity}
                              </Typography>
                            </div>
                          ) : (
                            ""
                          )}
                          {item?.color && (
                            <div className="displayFlex">
                              <Typography className="dynamic-font-family regular14Heading orderItemHeading">
                                Color:
                              </Typography>
                              <Typography className="dynamic-font-family regular14Heading subHeadingValue marginLeft">
                                {item?.color}
                              </Typography>
                            </div>
                          )}
                          {item?.gender && (
                            <div className="displayFlex">
                              <Typography className="dynamic-font-family regular14Heading orderItemHeading">
                                Gender:
                              </Typography>
                              <Typography className="dynamic-font-family regular14Heading subHeadingValue marginLeft">
                                {item?.gender}
                              </Typography>
                            </div>
                          )}
                          {item?.timestamp && (
                            <>
                              <Typography className="dynamic-font-family medium14Heading orderItemHeading itemTitle">
                                Date:{" "}
                                <span className="dynamic-font-family medium14Heading subHeadingValue">
                                  {
                                    timeConvert(
                                      item?.timestamp,
                                      sessionDetails?.merchantDetails?.locale
                                    )[0]
                                  }
                                </span>
                              </Typography>
                              <Typography className="dynamic-font-family medium14Heading orderItemHeading itemTitle">
                                Time:{" "}
                                <span className="dynamic-font-family medium14Heading subHeadingValue">
                                  {
                                    timeConvert(
                                      item?.timestamp,
                                      sessionDetails?.merchantDetails?.locale
                                    )[1]
                                  }
                                </span>
                              </Typography>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="displayFlex price">
                        <Typography className="dynamic-font-family itemAmount semiBold16Heading price">
                          <span className="currency-symbol">
                            {getSymbolFromCurrency(currencyCode)}{" "}
                          </span>
                          {numberToCurrency(
                            item?.amountWithoutTax,
                            currencyCode
                          )}
                        </Typography>
                      </div>
                    </div>
                    {isLastItem ? null : <Divider className="divider" />}
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </>
  );
}
