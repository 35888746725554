import React, { useEffect, useMemo, useRef, useState } from "react";
import "./paymentButton.css";
import { useDispatch, useSelector } from "react-redux";
import {
  selectActivePaymentMethod,
  selectButtonError,
  selectButtonLoading,
  selectPaymentMethodInfo,
  toggleButtonError,
} from "../../../slices/PaymentButtonSlice";
import {
  resetErrorMsg,
  selectCurrencyCode,
  selectCurrencySymbol,
  selectToatalAmount,
  selectToken,
} from "../../../slices/PaymentSessionDetailsSlice";
import CustomButton from "../../resuableComponents/CustomButton";
import { selectIsMobileLayout } from "../../../slices/MobileLayoutSlice";
import { isEmpty, numberToCurrency, getStroage, updateStroage } from "../../../Utils/utils";
import {
  handleUpiError,
  intentAppName,
  resetUpiStatus,
  selectShowUpiVpaTimer,
  selectUpiVpa,
  setVpaValidationDetails,
  toggleUpiVpaTimer,
  validUpiId,
} from "../../../slices/UpiSlice";
import { resetTransactionStatus } from "../../../slices/TransactionIdSlice";
import { buildPaymentRequest } from "../../../Utils/paymentUtils";
import {
  initiatePayment,
  resetPaymentInitiation,
  showFailedModal,
} from "../../../slices/PaymentInitiationSlice";
import { selectSelectedIntrument } from "../../../slices/SavedInstrumentSlice";
import PaymentResponseHandler from "../../../Utils/paymentResponseHandler";
import Loading from "../loadingScreen/Loading";
import {
  cardCvcData,
  cardExpiryDateData,
  cardHolderNameData,
  cardNumberData,
  cardbrandName,
  disableStateOfButton,
} from "../../../slices/CardSlice";
import ErrorModal from "../modals/errorModal/ErrorModal";
import { InfoCircleOutlined } from "@ant-design/icons";
import {
  getActiveStatus,
  resetFinalStatusResponse,
} from "../../../slices/finalStatusSlice";
import UpiTimerModal from "../paymentOptions/Upi/UpiTimerModal";
import {
  durationValue,
  emiButton,
  emiCodeValue,
} from "../../../slices/EmiSlice";
import { paymentInitiatedEvent } from "../../../Utils/uiAnalytics";
import getSymbolFromCurrency from "currency-symbol-map";
import { handleTotalPaymentSummaryError } from "../../../slices/HandleAdditionalComponentsSlice";
import axios from "axios";
import { getTransactionStatus, selectTransactionData } from "../../../slices/TransactionIdSlice";

const PyamentButton = () => {
  const isMobileLayout = useSelector((state) => selectIsMobileLayout(state));
  const token = useSelector((state) => selectToken(state));
  const buttonError = useSelector((state) => selectButtonError(state));
  const buttonLoading = useSelector((state) => selectButtonLoading(state));
  const currencyCode = useSelector((state) => selectCurrencyCode(state));
  const currencySymbol = useSelector((state) => selectCurrencySymbol(state));
  const amount = useSelector((state) => selectToatalAmount(state));
  const paymentSuccessData = useSelector((state) => selectTransactionData(state));
  const selectedInstrument = useSelector((state) =>
    selectSelectedIntrument(state)
  );
  const activePaymentMethod = useSelector((state) =>
    selectActivePaymentMethod(state)
  );
  const buttonDisableState = useSelector((state) =>
    disableStateOfButton(state)
  );
  const upiVpa = useSelector((state) => selectUpiVpa(state));
  const cardNumber = useSelector((state) => cardNumberData(state));
  const cardHolderName = useSelector((state) => cardHolderNameData(state));
  const cardExpiryDate = useSelector((state) => cardExpiryDateData(state));
  const cardCvc = useSelector((state) => cardCvcData(state));
  const latestBrandName = useSelector((state) => cardbrandName(state));
  const selectedTabValue = useSelector(
    (state) => state.emiCardDetails.selectedTabValue
  );
  const sessionDetails = useSelector(
    (state) => state.paymentSessionDetails.sessionDetails
  );
  const sessionDetailsStatusReason = useSelector(
    (state) => state.paymentSessionDetails.sessionDetailsStatusReason
  );
  const sampleToken = useSelector(
    (state) => state.paymentSessionDetails.sampleToken
  );
  const paymentMethodInfo = useSelector((state) =>
    selectPaymentMethodInfo(state)
  );
  const paymentInitiationData = useSelector(
    (state) => state.paymentInitiation.paymentInitiationData
  );
  const paymentInitiationError = useSelector(
    (state) => state.paymentInitiation.paymentInitiationError
  );
  const isPaymentRejectedOrFailed = useSelector(
    (state) => state.paymentInitiation.isPaymentRejectedOrFailed
  );
  const paymentInitiationLoading = useSelector(
    (state) => state.paymentInitiation.paymentInitiationLoading
  );
  const failedModal = useSelector(
    (state) => state.paymentInitiation.failedModal
  );
  const updatedShopperDetails = useSelector(
    (state) => state.paymentSessionDetails.updatedShopperDetails
  );
  const [validUpiIdData, setValidUpiIdData] = useState();
  const [validUpiIdErrorMessage, setValidUpiIdErrorMessage] = useState();
  const [validUpiIdLoading, setValidUpiIdLoading] = useState();

  const showTotalPaymentSummaryField = useSelector(
    (state) => state.additionalComponents.showTotalPaymentSummaryField
  );
  const totalPaymentSummaryValue = useSelector(
    (state) => state.additionalComponents.totalPaymentSummaryValue
  );
  const emiPaymentButton = useSelector((state) => emiButton(state));
  const dispatch = useDispatch();
  const [showPaymentLoading, setShowPaymentLoading] = useState(false);
  const intervalRef = useRef(null);
  const showUpiVpaTimer = useSelector((state) => selectShowUpiVpaTimer(state));
  const statusResponse = useSelector(
    (state) => state.finalStatus.activeStatusData
  );
  const activeStatusReason = useSelector(
    (state) => state.finalStatus.activeStatusReason
  );
  const callerType = useSelector(
    (state) => state.paymentSessionDetails.callerType
  );
  const duration = useSelector((state) => durationValue(state));
  const emiCode = useSelector((state) => emiCodeValue(state));
  const upiIntentName = useSelector((state) => intentAppName(state));
  const transactionId = getStroage("session", "t_id") || getStroage("session", "s_d")?.lastTransactionId;
  useEffect(() => {
    dispatch(resetPaymentInitiation());
  }, [activePaymentMethod]);

  useEffect(() => {
    if (paymentInitiationData === null) {
      stopSatusCall();
    }
  }, [paymentInitiationData]);

  // UPI -- starts
  // handleQrPay in UpiQr and handleIntentPay in UpiIntent
  const handleUpiValidation = async (payload) => {
    setValidUpiIdLoading(true);
    const baseUrl = process.env.REACT_APP_BASE_URL;
    try {
      const response = await axios.post(
        `${baseUrl}/platform/vpa-validation`,
        payload
      );
      setValidUpiIdLoading(false);
      setValidUpiIdData(response.data);
      dispatch(setVpaValidationDetails({ data: response.data }));
    } catch (error) {
      setValidUpiIdLoading(false);
      if (error?.response) {
        dispatch(setVpaValidationDetails(error?.response?.data));
        setValidUpiIdErrorMessage(error?.response?.data);
        return error?.response?.data;
      } else {
        dispatch(setVpaValidationDetails(error?.message));
        setValidUpiIdErrorMessage(error?.message);
        return error?.message;
      }
    }
  };

  const handleUpiPay = () => {
    const vpaPayload = {
      vpa: upiVpa,
      legalEntity: sessionDetails?.paymentDetails?.context?.legalEntity?.code,
      merchantId: sessionDetails?.merchantId,
      countryCode: "IN",
    };
    handleUpiValidation(vpaPayload);
  };

  useEffect(() => {
    if (!isEmpty(validUpiIdData)) {
      if (validUpiIdData?.vpaValid === true) {
        let paymentRequest = buildPaymentRequest(
          paymentMethodInfo?.instrumentTypeValue ||
          selectedInstrument?.instrumentTypeValue ||
          "upi/collect",
          updatedShopperDetails,
          sessionDetails
        );
        if (activePaymentMethod === "UpiSaved") {
          paymentRequest.payload.instrumentDetails.upi = {
            shopperVpa: selectedInstrument?.value,
          };
        } else {
          paymentRequest.payload.instrumentDetails.upi = {
            shopperVpa: upiVpa || selectedInstrument?.value,
          };
        }
        if (totalPaymentSummaryValue) {
          paymentRequest.payload.money = {
            amount: totalPaymentSummaryValue,
            currencyCode: sessionDetails?.paymentDetails?.money?.currencyCode,
          };
        }
        let token = paymentRequest?.token;
        let payload = paymentRequest?.payload;
        if (showTotalPaymentSummaryField && totalPaymentSummaryValue === "") {
          dispatch(handleTotalPaymentSummaryError("Please enter amount"));
          window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top of the page
        } else {
          dispatch(initiatePayment({ token, payload }));
          paymentInitiatedEvent(
            sessionDetails,
            "Upi",
            paymentMethodInfo?.instrumentTypeValue ||
            selectedInstrument?.instrumentTypeValue ||
            "UpiCollect",
            dispatch
          );
        }
      } else if (validUpiIdData?.vpaValid === false) {
        dispatch(toggleButtonError(true));
        dispatch(handleUpiError("Invalid UPI Id"));
      }
    } else if (!isEmpty(validUpiIdErrorMessage)) {
      let paymentRequest = buildPaymentRequest(
        paymentMethodInfo?.instrumentTypeValue ||
        selectedInstrument?.instrumentTypeValue ||
        "upi/collect",
        updatedShopperDetails,
        sessionDetails
      );
      if (activePaymentMethod === "UpiSaved") {
        paymentRequest.payload.instrumentDetails.upi = {
          shopperVpa: selectedInstrument?.value,
        };
      } else {
        paymentRequest.payload.instrumentDetails.upi = {
          shopperVpa: upiVpa || selectedInstrument?.value,
        };
      }
      if (totalPaymentSummaryValue) {
        paymentRequest.payload.money = {
          amount: totalPaymentSummaryValue,
          currencyCode: sessionDetails?.paymentDetails?.money?.currencyCode,
        };
      }
      let token = paymentRequest?.token;
      let payload = paymentRequest?.payload;
      if (showTotalPaymentSummaryField && totalPaymentSummaryValue === "") {
        dispatch(handleTotalPaymentSummaryError("Please enter amount"));
        window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top of the page
      } else {
        dispatch(initiatePayment({ token, payload }));
        paymentInitiatedEvent(
          sessionDetails,
          "Upi",
          paymentMethodInfo?.instrumentTypeValue ||
          selectedInstrument?.instrumentTypeValue ||
          "UpiCollect",
          dispatch
        );
      }
    }
  }, [validUpiIdData, validUpiIdErrorMessage]);

  useEffect(() => {
    if (
      paymentInitiationData &&
      paymentInitiationData?.paymentMethod?.type === "Upi"
    ) {
      let token = sessionDetails?.token;
      if (paymentInitiationData.paymentMethod?.brand === "UpiCollect") {
        dispatch(toggleUpiVpaTimer(true));
      }
      intervalRef.current = setInterval(() => {
        dispatch(getTransactionStatus({ token, transactionId }));
        // dispatch(getActiveStatus({ token }));
      }, 4000);
      return () => {
        clearInterval(intervalRef.current);
      };
    }
  }, [paymentInitiationData]);

  useEffect(() => {
    if (paymentSuccessData?.status === "PAID" || paymentSuccessData?.status === "PROCESSING") {
      if (sessionDetails?.callerType === "CHECKOUT") {
        let redirectUrl = paymentSuccessData?.actions[0]?.url;
        window.location.replace(redirectUrl);
      } else {
        // in case of payment link after payment, it will always go to final status page.
        window.location.replace(`/finalStatus`);
      }
      stopSatusCall();
    } else if (paymentSuccessData?.status === "FAILED" || paymentSuccessData?.status === "Rejected") {
      stopSatusCall();
      dispatch(showFailedModal(true));
    }
  }, [paymentSuccessData])

  const stopSatusCall = () => {
    clearInterval(intervalRef.current);
    if (
      paymentInitiationData &&
      paymentInitiationData?.paymentMethod?.brand === "UpiCollect"
    ) {
      dispatch(toggleUpiVpaTimer(false));
      dispatch(resetPaymentInitiation());
      dispatch(resetUpiStatus());
    }
  };

  const handleWalletBNPLNetBankingPay = () => {
    let paymentRequest = buildPaymentRequest(
      selectedInstrument?.instrumentTypeValue,
      updatedShopperDetails,
      sessionDetails
    );
    if (totalPaymentSummaryValue) {
      paymentRequest.payload.money = {
        amount: totalPaymentSummaryValue,
        currencyCode: sessionDetails?.paymentDetails?.money?.currencyCode,
      };
    }
    let token = paymentRequest?.token;
    let payload = paymentRequest?.payload;
    if (showTotalPaymentSummaryField && totalPaymentSummaryValue === "") {
      dispatch(handleTotalPaymentSummaryError("Please enter amount"));
      window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top of the page
    } else {
      dispatch(initiatePayment({ token, payload }));
      paymentInitiatedEvent(
        sessionDetails,
        selectedInstrument?.type,
        selectedInstrument?.brand,
        dispatch
      );
    }
  };

  const handleCardPay = () => {
    let paymentRequest = buildPaymentRequest(
      "card/meta",
      updatedShopperDetails,
      sessionDetails
    );
    if (sessionDetails?.context?.countryCode === "IN") {
      paymentRequest = buildPaymentRequest(
        "card/meta",
        updatedShopperDetails,
        sessionDetails
      );
      paymentRequest.payload.instrumentDetails.cardMeta = {
        brand: latestBrandName,
      };
      if (totalPaymentSummaryValue) {
        paymentRequest.payload.money = {
          amount: totalPaymentSummaryValue,
          currencyCode: sessionDetails?.paymentDetails?.money?.currencyCode,
        };
      }
    } else {
      const [expiry_month, expiry_year] = cardExpiryDate?.split("/");
      paymentRequest = buildPaymentRequest(
        "card/plain",
        updatedShopperDetails,
        sessionDetails
      );
      paymentRequest.payload.instrumentDetails.card = {
        number: cardNumber,
        expiry: `20${expiry_year}-${expiry_month}`,
        cvc: cardCvc,
        holderName: cardHolderName,
      };
      if (totalPaymentSummaryValue) {
        paymentRequest.payload.money = {
          amount: totalPaymentSummaryValue,
          currencyCode: sessionDetails?.paymentDetails?.money?.currencyCode,
        };
      }
    }
    let token = paymentRequest?.token;
    let payload = paymentRequest?.payload;
    if (showTotalPaymentSummaryField && totalPaymentSummaryValue === "") {
      dispatch(handleTotalPaymentSummaryError("Please enter amount"));
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      updateStroage("session", "remove", "t_id");
      dispatch(initiatePayment({ token, payload }));
      paymentInitiatedEvent(sessionDetails, "Card", latestBrandName, dispatch);
    }
  };

  const handleCardlessEmi = () => {
    const paymentRequest = buildPaymentRequest(
      "emi/cardless",
      updatedShopperDetails,
      sessionDetails
    );
    paymentRequest.payload.instrumentDetails.emi = {
      provider: selectedInstrument?.emiMethod?.cardlessEmiProviderValue,
    };
    if (totalPaymentSummaryValue) {
      paymentRequest.payload.money = {
        amount: totalPaymentSummaryValue,
        currencyCode: sessionDetails?.paymentDetails?.money?.currencyCode,
      };
    }
    if (showTotalPaymentSummaryField && totalPaymentSummaryValue === "") {
      dispatch(handleTotalPaymentSummaryError("Please enter amount"));
      window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top of the page
    } else {
      dispatch(initiatePayment(paymentRequest));
      paymentInitiatedEvent(sessionDetails, "Emi", "CardlessEMI", dispatch);
    }
  };

  const handleCardEmi = () => {
    const [expiry_month, expiry_year] = cardExpiryDate?.split("/");
    const paymentRequest = buildPaymentRequest(
      selectedTabValue,
      updatedShopperDetails,
      sessionDetails,
      emiCode
    );

    paymentRequest.payload.instrumentDetails.card = {
      number: cardNumber,
      expiry: `20${expiry_year}-${expiry_month}`,
      cvc: cardCvc,
      holderName: cardHolderName,
    };
    if (totalPaymentSummaryValue) {
      paymentRequest.payload.money = {
        amount: totalPaymentSummaryValue,
        currencyCode: sessionDetails?.paymentDetails?.money?.currencyCode,
      };
    }
    paymentRequest.payload.instrumentDetails.emi = {
      duration: duration,
    };
    if (showTotalPaymentSummaryField && totalPaymentSummaryValue === "") {
      dispatch(handleTotalPaymentSummaryError("Please enter amount"));
      window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top of the page
    } else {
      dispatch(initiatePayment(paymentRequest));
      paymentInitiatedEvent(sessionDetails, "Emi", "CreditCardEMI", dispatch);
    }
  };

  const handlePay = () => {
    let handlePayment =
      activePaymentMethod === "Recommended"
        ? selectedInstrument?.type
        : activePaymentMethod;
    if (handlePayment === "Emi") {
      if (selectedInstrument && selectedInstrument.brand === "CardlessEMI") {
        handleCardlessEmi();
      } else {
        handleCardEmi();
      }
    } else {
      switch (handlePayment) {
        case "Upi":
        case "UpiSaved":
          handleUpiPay();
          break;
        case "Wallet":
        case "NetBanking":
        case "BuyNowPayLater":
          handleWalletBNPLNetBankingPay();
          break;
        case "Card":
          handleCardPay();
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (paymentInitiationLoading && activePaymentMethod !== "UpiQr") {
      setShowPaymentLoading(true);
    } else if (isPaymentRejectedOrFailed) {
      setShowPaymentLoading(false);
    } else if (
      paymentInitiationData &&
      paymentInitiationData?.actions?.length
    ) {
      const redirectUrl = paymentInitiationData?.actions[0]?.url;
      if (
        redirectUrl &&
        window.location.href !== redirectUrl &&
        paymentInitiationData.paymentMethod?.type !== "Upi"
      ) {
        setShowPaymentLoading(true);
      } else {
        setShowPaymentLoading(false);
      }
    } else {
      setShowPaymentLoading(false);
    }
  }, [
    paymentInitiationLoading,
    isPaymentRejectedOrFailed,
    activePaymentMethod,
  ]);

  useEffect(() => {
    if (!paymentInitiationLoading && paymentInitiationData) {
      if (paymentInitiationData?.status?.status === "Approved") {
        if (callerType === "PAYMENT_LINK") {
          window.location.replace(`/finalStatus`);
        }
      }
    }
  }, [paymentInitiationLoading, paymentInitiationData, callerType, dispatch]);
  useEffect(() => {
    if (isPaymentRejectedOrFailed) {
      dispatch(showFailedModal(true));
    }
  }, [isPaymentRejectedOrFailed, dispatch]);

  const handleExitButton = () => {
    const redirectUrl = sessionDetails?.paymentDetails?.frontendBackUrl;
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  };

  const clearPnsFromUrl = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete("pns");
    window.history.pushState({}, "", url.toString());
  };

  return (
    <div className={isMobileLayout ? "domestic-mbtn-root" : ""}>
      <UpiTimerModal
        upiId={selectedInstrument?.value || upiVpa}
        showTimer={showUpiVpaTimer}
        callback={stopSatusCall}
      />
      <CustomButton
        text={
          <div className="dynamic-font-family dynamic-button-text">
            Pay{" "}
            <span className="currency-symbol">
              {" "}
              {getSymbolFromCurrency(currencyCode)}{" "}
            </span>
            {showTotalPaymentSummaryField
              ? totalPaymentSummaryValue
              : numberToCurrency(amount, currencyCode)}
          </div>
        }
        buttonType={"primary-btn"}
        block={true}
        loading={
          buttonLoading ||
          paymentInitiationLoading ||
          validUpiIdLoading ||
          showPaymentLoading
        }
        disabled={
          buttonError ||
          !activePaymentMethod ||
          (activePaymentMethod === "Upi" && !upiVpa) ||
          (activePaymentMethod === "UpiSaved" && !selectedInstrument) ||
          (activePaymentMethod === "Wallet" && !selectedInstrument) ||
          (activePaymentMethod === "BuyNowPayLater" && !selectedInstrument) ||
          (activePaymentMethod === "NetBanking" && !selectedInstrument) ||
          (activePaymentMethod === "Card" && buttonDisableState) ||
          (activePaymentMethod === "Emi" && emiPaymentButton) ||
          (activePaymentMethod === "Recommended" && !selectedInstrument) ||
          activePaymentMethod === "UpiQr"
        }
        onClick={handlePay}
      />
      {showPaymentLoading === true && (
        <Loading
          text="Please wait. We are processing your payment"
          subText="Note: Kindly avoid using back button"
          redirectionName={
            activePaymentMethod === "Upi"
              ? paymentInitiationData?.paymentMethod?.subBrand
              : paymentInitiationData?.paymentMethod?.brand
          }
        />
      )}

      {paymentInitiationData !== null && <PaymentResponseHandler />}
      {sampleToken ? (
        <ErrorModal
          icon={
            <InfoCircleOutlined
              style={{ color: "#FF4D4F", fontSize: "22px" }}
            />
          }
          title={"Payment failed"}
          desc="This is a sample Checkout Page so no actual payments can be initiated"
          showModal={failedModal}
          primaryOnClick={() => {
            dispatch(resetPaymentInitiation());
            dispatch(showFailedModal(false));
          }}
          primaryBtnText={"Cancel"}
        />
      ) : (
        <ErrorModal
          icon={
            <InfoCircleOutlined
              style={{ color: "#FF4D4F", fontSize: "22px" }}
            />
          }
          title={"Payment Failed"}
          desc={(() => {
            const baseMessage =
              "Please retry using other payment method or try again in some time.";
            let errorMessage = "";

            if (
              sessionDetails?.lastTransactionDetails &&
              sessionDetails?.lastTransactionDetails?.status?.reasonCode?.startsWith(
                "UF"
              ) &&
              sessionDetailsStatusReason
            ) {
              errorMessage = sessionDetailsStatusReason;
            } else if (paymentInitiationError) {
              errorMessage = paymentInitiationError;
            } else if (
              paymentSuccessData?.status === "FAILED" &&
              paymentSuccessData?.reasonCode?.startsWith("UF")
            ) {
              errorMessage = activeStatusReason;
            } else if (
              paymentInitiationData?.paymentMethod?.brand === "UpiIntent"
            ) {
              return (
                <div>
                  Payment failed with {upiIntentName}. Please retry payment with
                  a different UPI app
                </div>
              );
            }

            return (
              <div>
                {baseMessage}
                {errorMessage && (
                  <>
                    <br />
                    <br />
                    <b>Error Msg: </b>
                    {errorMessage}
                  </>
                )}
              </div>
            );
          })()}
          showModal={failedModal}
          primaryOnClick={() => {
            dispatch(resetTransactionStatus())
            clearPnsFromUrl();
            dispatch(resetPaymentInitiation());
            dispatch(showFailedModal(false));
            dispatch(resetFinalStatusResponse());
            dispatch(resetUpiStatus());
            dispatch(resetErrorMsg());
          }}
          secondaryOnClick={() => handleExitButton()}
          primaryBtnText={"Retry"}
          secondaryBtnText={"Cancel"}
          showSecondaryButton={callerType === "CHECKOUT" && true}
        />
      )}
    </div>
  );
};

export default PyamentButton;
