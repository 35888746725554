import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const validUpiId = createAsyncThunk(
  "upiStatus/validUpiId",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${baseUrl}/platform/vpa-validation`,
        payload
      );
      return response.data;
    } catch (error) {
      if (error?.response) {
        return rejectWithValue(error?.response?.data);
      } else {
        return rejectWithValue(error?.message);
      }
    }
  }
);

const initialState = {
  showUpiVpaTimer: false,
  showUpiVpaError: false,
  upiVpa: "",
  upiVpaError: "",
  upiIntentName: "",
  upiButtonId: "",

  validUpiIdLoading: false,
  validUpiIdData: [],
  validUpiIdErrorMessage: '',
};

export const upiStatusSlice = createSlice({
  name: "upiStatus",
  initialState,
  reducers: {
    toggleUpiVpaTimer(state, action) {
      state.showUpiVpaTimer = action.payload;
    },
    toggleUpiVpaError(state) {
      state.showUpiVpaError = !state.showUpiVpaError;
    },
    handleIdForButton(state, action) {
      state.upiButtonId = action.payload;
    },
    changeUpiVpa(state, action) {
      state.upiVpa = action.payload;
    },
    handleUpiError(state, action) {
      state.upiVpaError = action.payload;
    },
    handleIntentAppName(state, action) {
      state.upiIntentName = action.payload;
    },
    resetUpiStatus(state, action) {
      state.validUpiIdData = [];
      state.validUpiIdErrorMessage = "";
      state.validUpiIdLoading = false;
    },
    resetUpiFields(state, action) {
      state.upiVpa = "";
      state.upiVpaError = "";
    },
    setVpaValidationDetails(state, action) {
      state.validUpiIdLoading = false;
      state.validUpiIdData = action.payload.data;
      state.validUpiIdErrorMessage = action.payload.error;
    }
  },
  extraReducers: {
    [validUpiId.pending]: (state) => {
      state.validUpiIdLoading = true;
      state.validUpiIdData = [];
      state.validUpiIdErrorMessage = "";
    },
    [validUpiId.fulfilled]: (state, action) => {
      state.validUpiIdLoading = false;
      state.validUpiIdData = action.payload;
      state.validUpiIdErrorMessage = "";
    },
    [validUpiId.rejected]: (state, action) => {
      state.validUpiIdLoading = false;
      state.validUpiIdErrorMessage = action.payload;
    },
  },
});
export const {
  toggleUpiVpaTimer,
  toggleUpiVpaError,
  changeUpiVpa,
  handleIdForButton,
  setSavedUpi,
  handleUpiError,
  handleIntentAppName,
  resetUpiStatus,
  resetUpiFields,
  setVpaValidationDetails
} = upiStatusSlice.actions;

export const selectShowUpiVpaTimer = (state) => state.upiStatus.showUpiVpaTimer;
export const selectShowUpiVpaError = (state) => state.upiStatus.showUpiVpaError;
export const selectUpiButtonId = (state) => state.upiStatus.upiButtonId;
export const selectUpiVpa = (state) => state.upiStatus.upiVpa;
export const vpaError = (state) => state.upiStatus.upiVpaError;
export const intentAppName = (state) => state.upiStatus.upiIntentName;

export default upiStatusSlice.reducer;
