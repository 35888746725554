import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const getCardBinData = createAsyncThunk(
  "binDetails/getCardBinData",
  async ({ token, inputData }) => {
    const sanitizedInputData = inputData.replace(/\s/g, "").slice(0, 9);
    const response = await axios.post(
      `${baseUrl}/checkout/sessions/${token}/bank-identification-numbers/${sanitizedInputData}`
    );
    return response.data;
  }
);

const initialState = {
  cardBinData: {},
  cardBinLoading: false,
  cardBinErrorMessage: "",
};

export const binDetailSlice = createSlice({
  name: "binDetails",
  initialState,
  reducers: {
    resetCardBinData: (state) => {
      state.cardBinData = {};
    },
  },
  extraReducers: {
    [getCardBinData.pending]: (state) => {
      state.cardBinLoading = true;
    },
    [getCardBinData.fulfilled]: (state, action) => {
      state.cardBinLoading = false;
      state.cardBinData = action.payload;
    },
    [getCardBinData.rejected]: (state, action) => {
      state.cardBinLoading = false;
      state.cardBinData = [];
      state.cardBinErrorMessage = action.error.payload;
    },
  },
});

export const { resetCardBinData } = binDetailSlice.actions;
export default binDetailSlice.reducer;
