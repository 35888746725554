import { Drawer, Typography } from "antd";
import React, { useEffect } from "react";
import "../../templates/domesticCheckout/styles.css";
import { useSelector } from "react-redux";
import { generateDynamicStyling } from "../../../Utils/utils";
import PyamentButton from "../../components/paymentButton/PaymentButton";

const BottomSheet = ({ open, handleClose, title, children, type }) => {
  const sessionDetails = useSelector(
    (state) => state.paymentSessionDetails.sessionDetails
  );
  useEffect(() => {
    if (sessionDetails) {
      generateDynamicStyling(sessionDetails);
    }
  }, [sessionDetails]);

  return (
    <Drawer
      title={
        <Typography className="semiBold16Heading dynamic-font-family ">
          {title}
        </Typography>
      }
      placement="bottom"
      onClose={handleClose}
      open={open}
    >
      <>
        <div
          style={{
            marginBottom: type === "pay" ? "72px" : "",
          }}
        >
          {children}
        </div>

        {type === "pay" && <PyamentButton />}
      </>
    </Drawer>
  );
};

export default BottomSheet;
