import React, { useEffect, useState } from "react";
import { Modal, Progress, Typography } from "antd";
import "./upiTimerModal.css";
import timer_worker_script from "../../../../web-workers/timerWorker";
import { formatOtpTime } from "../../../../Utils/utils";
import CustomButton from "../../../resuableComponents/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { showFailedModal } from "../../../../slices/PaymentInitiationSlice";

const UpiTimerModal = ({ upiId, showTimer, callback }) => {
  const dispatch = useDispatch();
  const paymentInitiationData = useSelector(
    (state) => state.paymentInitiation.paymentInitiationData
  );

  const defaultDuration = 300;
  const expiryDuration = paymentInitiationData?.actions?.[0]?.expirySec;
  const duration = expiryDuration || defaultDuration;

  const [countdown, setCountdown] = useState(duration);

  useEffect(() => {
    setCountdown(duration);
  }, [showTimer]);

  useEffect(() => {
    if (showTimer) {
      const timerWorker = new Worker(timer_worker_script);
      timerWorker.postMessage({ duration: duration });
      timerWorker.onmessage = ({ data }) => {
        if (data === "done") {
          setCountdown(0);
          clearInterval(timerWorker);
          callback();
          dispatch(showFailedModal(true));
        } else {
          setCountdown(data);
        }
      };
      if (!showTimer) {
        timerWorker.terminate();
      }
      return () => {
        timerWorker.terminate();
      };
    }
  }, [showTimer]);

  return (
    <Modal
      centered
      open={showTimer}
      footer={null}
      closable={false}
      style={{ padding: "24px" }}
    >
      <div className="upi-timer-modal-root">
        <div className="upi-timer-modal-header dynamic-font-family">
          <Typography>Open your UPI application and</Typography>
          <Typography>confirm the payment before the time expires</Typography>
        </div>
        <div className="upi-id-conatiner dynamic-font-family">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="14"
            viewBox="0 0 15 14"
            fill="none"
          >
            <path
              d="M7.5088 11.8265L10.3173 1.92327L12.8449 6.95874L7.5088 11.8265Z"
              stroke="#2D2B32"
              strokeWidth="1.4"
            />
            <path
              d="M2.63481 11.8263L5.44323 1.9234L7.97049 6.95873L2.63481 11.8263Z"
              stroke="#2D2B32"
              strokeWidth="1.4"
            />
          </svg>
          <Typography>{upiId}</Typography>
        </div>
        <div className="upi-timer-modal-timer dynamic-font-family">
          <Typography className="modal-timer-expiry-font">
            Expires in
          </Typography>
          <Progress
            type="circle"
            percent={countdown && (countdown / duration) * 100}
            strokeColor={"#FAA4A4"}
            format={() => (
              <div className="timer-font dynamic-font-family">
                {" "}
                {formatOtpTime(countdown)}
              </div>
            )}
            size={140}
          />
        </div>
        <div className="upi-timer-modal-footer dynamic-font-family">
          <Typography>
            Note: Kindly avoid using the back button until the transaction
            process is complete
          </Typography>
        </div>
        <CustomButton
          text={"Cancel Payment"}
          buttonType={"primary-btn"}
          containerType={"link"}
          onClick={callback}
          block={true}
        />
      </div>
    </Modal>
  );
};

export default UpiTimerModal;
