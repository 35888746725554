import React, { useEffect, useState } from "react";
import PaymentOptionsContainer from "./PaymentOptionsContainer";
import Upi from "./Upi/Upi";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton } from "antd";
import Wallet from "./wallet/Wallet";
import { generateDynamicPaymentMethodLogo } from "../../../Utils/utils";
import Bnpl from "./bnpl/bnpl";
import { selectIsMobileLayout } from "../../../slices/MobileLayoutSlice";
import PaymentOptionsMobileContainer from "./PaymentOptionsMobileContainer";
import CardPaymentOption from "./cardPayment/cardPaymentOption";
import CardMobilePayment from "./cardPayment/cardMobilePayment/cardMobilePayment";
import {
  selectBaseColor,
  selectDarkColor,
  selectLightColor,
  selectSelectionColor,
} from "../../../slices/PaymentSessionDetailsSlice";
import NetBanking from "./netBanking/NetBanking";
import Recommended from "./recommended/Recommended";
import Emi from "./emi/emi";
import { categorySelectedEvent } from "../../../Utils/uiAnalytics"; // Ensure correct import

const PaymentOptions = () => {
  const dispatch = useDispatch();
  const [selectedTabType, setSelectedTabType] = useState(null);
  const darkColor = useSelector((state) => selectDarkColor(state));
  const lightColor = useSelector((state) => selectLightColor(state));
  const baseColor = useSelector((state) => selectBaseColor(state));
  const selectionColor = useSelector((state) => selectSelectionColor(state));
  const sessionDetails = useSelector(
    (state) => state.paymentSessionDetails.sessionDetails
  );
  const paymentMethodDictionary = useSelector(
    (state) => state.paymentSessionDetails.paymentMethodDictionary
  );
  const isMobileLayout = useSelector((state) => selectIsMobileLayout(state));

  const tabList = [
    {
      id: 0,
      type: "Recommended",
      logo: generateDynamicPaymentMethodLogo(
        "Recommended",
        darkColor,
        lightColor,
        "18px",
        "18px"
      ),
      content: (
        <Recommended
          recommendedMethodInfo={
            paymentMethodDictionary &&
            "Recommended" in paymentMethodDictionary &&
            paymentMethodDictionary["Recommended"]
          }
        />
      ),
    },
    {
      id: 1,
      type: "Upi",
      logo: generateDynamicPaymentMethodLogo(
        "Upi",
        darkColor,
        lightColor,
        "18px",
        "18px"
      ),
      content: (
        <Upi
          upiMethodInfo={
            paymentMethodDictionary &&
            "Upi" in paymentMethodDictionary &&
            paymentMethodDictionary["Upi"]
          }
        />
      ),
    },
    {
      id: 2,
      type: "Card",
      logo: generateDynamicPaymentMethodLogo(
        "Card",
        darkColor,
        lightColor,
        "18px",
        "18px"
      ),
      content: (
        <CardPaymentOption
          cardMethodInfo={
            paymentMethodDictionary &&
            "Card" in paymentMethodDictionary &&
            paymentMethodDictionary["Card"]
          }
        />
      ),
      mobileContent: (
        <CardMobilePayment
          cardMethodInfo={
            paymentMethodDictionary &&
            "Card" in paymentMethodDictionary &&
            paymentMethodDictionary["Card"]
          }
        />
      ),
    },
    {
      id: 3,
      type: "Wallet",
      logo: generateDynamicPaymentMethodLogo(
        "Wallet",
        darkColor,
        lightColor,
        "18px",
        "18px"
      ),
      content: (
        <Wallet
          walletMethodInfo={
            paymentMethodDictionary &&
            "Wallet" in paymentMethodDictionary &&
            paymentMethodDictionary["Wallet"]
          }
          darkColor={darkColor}
          lightColor={lightColor}
        />
      ),
    },
    {
      id: 4,
      type: "NetBanking",
      logo: generateDynamicPaymentMethodLogo(
        "NetBanking",
        darkColor,
        lightColor,
        "18px",
        "18px"
      ),
      content: (
        <NetBanking
          netBankingMethodInfo={
            paymentMethodDictionary &&
            "NetBanking" in paymentMethodDictionary &&
            paymentMethodDictionary["NetBanking"]
          }
          darkColor={darkColor}
          lightColor={lightColor}
        />
      ),
    },
    {
      id: 5,
      type: "Emi",
      logo: generateDynamicPaymentMethodLogo(
        "Emi",
        darkColor,
        lightColor,
        "18px",
        "18px"
      ),
      content: (
        <Emi
          emiMethodInfo={
            paymentMethodDictionary &&
            "Emi" in paymentMethodDictionary &&
            paymentMethodDictionary["Emi"]
          }
        />
      ),
    },
    {
      id: 6,
      type: "BuyNowPayLater",
      logo: generateDynamicPaymentMethodLogo(
        "BuyNowPayLater",
        darkColor,
        lightColor,
        "18px",
        "18px"
      ),
      content: (
        <Bnpl
          walletMethodInfo={
            paymentMethodDictionary &&
            "BuyNowPayLater" in paymentMethodDictionary &&
            paymentMethodDictionary["BuyNowPayLater"]
          }
          darkColor={darkColor}
          lightColor={lightColor}
        />
      ),
    },
  ];

  const handleSelectedTabId = (item) => {
    const selectedTab = tabList.find((tab) => tab.id === item);
    if (selectedTab) {
      setSelectedTabType(selectedTab.type);
    }
  };

  useEffect(() => {
    if (selectedTabType) {
      categorySelectedEvent(sessionDetails, selectedTabType, dispatch);
    }
  }, [selectedTabType]);

  return paymentMethodDictionary ? (
    isMobileLayout ? (
      <>
        <PaymentOptionsMobileContainer
          tabList={tabList}
          darkColor={baseColor}
          lightColor={selectionColor}
        />
      </>
    ) : (
      <PaymentOptionsContainer
        tabList={tabList}
        darkColor={baseColor}
        lightColor={selectionColor}
        handleSelectedTabId={handleSelectedTabId}
      />
    )
  ) : (
    <Skeleton active large />
  );
};

export default PaymentOptions;
