import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const uiAnalyticsEvents = createAsyncThunk(
  "uiAnalytics/uiAnalyticsEvents",
  async (payload) => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has("spo")) {
      console.log("Caller token is null for this payload", payload);
      return;
    }
    if (payload.callerToken.includes("st_")) {
      return;
    }
    const payloadWithBrowserData = {
      ...payload,
      browserData: {
        userAgentHeader: navigator.userAgent,
        browserLanguage: navigator.language,
      },
    };
    const response = await axios.post(
      `${baseUrl}/ui-analytics`,
      payloadWithBrowserData
    );
    return response.data;
  }
);

const initialState = {
  uiEventData: [],
  uiEventLoading: false,
  uiEventErrorMessage: "",
};

export const uiAnalyticsEventSlice = createSlice({
  name: "uiAnalytics",
  initialState,

  extraReducers: {
    [uiAnalyticsEvents.pending]: (state) => {
      state.uiEventLoading = true;
    },
    [uiAnalyticsEvents.fulfilled]: (state, action) => {
      state.uiEventLoading = false;
      state.uiEventData = action.payload;
    },
    [uiAnalyticsEvents.rejected]: (state, action) => {
      state.uiEventLoading = false;
      state.isSuccess = false;
      state.uiEventErrorMessage = action.error.payload;
    },
  },
});

export default uiAnalyticsEventSlice.reducer;
