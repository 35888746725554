import React, { useEffect, useState } from "react";
import { Typography } from "antd";
import CardPaymentForm from "../cardPayment/cardPaymentForm";
import PaymentIntrumentSelection from "../paymentInstrumentSelection/PaymentIntrumentSelection";
import { useSelector } from "react-redux";

export default function SelectedEmiBankForm({ selectedEmiBank }) {
  const sessionDetails = useSelector(
    (state) => state.paymentSessionDetails.sessionDetails
  );
  const [filteredEmiMethodInfo, setFilteredEmiMethodInfo] = useState([]);

  useEffect(() => {
    if (selectedEmiBank && sessionDetails?.configs?.paymentMethods) {
      const filteredMethods = sessionDetails.configs.paymentMethods.filter(
        (method) =>
          method.brand === selectedEmiBank.brand &&
          method.emiMethod.issuer === selectedEmiBank.value
      );
      setFilteredEmiMethodInfo(filteredMethods);
    }
  }, [selectedEmiBank, sessionDetails]);
  return (
    <>
      <CardPaymentForm paymentType="Emi" />
      <Typography className="selectEmiText">Select EMI plan</Typography>
      <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <PaymentIntrumentSelection data={filteredEmiMethodInfo} />
      </div>
    </>
  );
}
