import React, { useEffect, useState } from "react";
import "./paymentSuccess.css";
import { Divider, Skeleton, Typography } from "antd";
import Lottie from "lottie-react";
import animationData from "../lotties/successLogo.json";
import dateFormat from "dateformat";
import { useDispatch, useSelector } from "react-redux";
import {
  generateDynamicStyling,
  getStroage,
  numberToCurrency,
} from "../../../Utils/utils";
import {
  getTransactionStatus,
  selectTransactionData,
} from "../../../slices/TransactionIdSlice";

const PaymentSuccess = () => {
  const dispatch = useDispatch();
  const paymentSuccessData = useSelector((state) =>
    selectTransactionData(state)
  );
  const sessionDetails = getStroage("session", "s_d");
  const transactionId =
    getStroage("session", "t_id") ||
    getStroage("session", "s_d")?.lastTransactionId;
  const token = getStroage("session", "tkn");

  generateDynamicStyling(sessionDetails);
  const [paymentSuccessDetails, setPaymentSuccessDetails] = useState(null);
  const [showOnlyText, setShowOnlyText] = useState();

  useEffect(() => {
    if (token && transactionId) {
      dispatch(getTransactionStatus({ token, transactionId }));
      setShowOnlyText(false);
    } else {
      setShowOnlyText(true);
    }
  }, [token, transactionId]);

  useEffect(() => {
    if (paymentSuccessData) {
      let paymentSummaryDictionary = [
        {
          title: "Transaction ID",
          value: paymentSuccessData?.transactionId,
        },
        {
          title: "Status",
          value: paymentSuccessData?.status,
        },
        {
          title: "Date & Time",
          value: dateFormat(
            paymentSuccessData?.transactionTimestamp,
            "dddd, mmmm dS, yyyy, h:MM:ss TT"
          ),
        },
      ];
      setPaymentSuccessDetails(paymentSummaryDictionary);
    }
  }, [paymentSuccessData]);

  return (
    <div style={{ minHeight: "calc(100vh - 72px)", display: "flex" }}>
      {showOnlyText === undefined ? (
        <></>
      ) : (
        <>
          {!showOnlyText ? (
            <div className="domestic-s-root dynamic-font-styling">
              <div className="domestic-s-header">
                <Lottie
                  animationData={animationData}
                  loop={true}
                  autoplay={true}
                  style={{ width: "100px", height: "100px" }}
                  speed={4.5}
                />
                <Typography
                  className="semiBold24Heading dynamic-font-styling"
                  style={{
                    padding: showOnlyText && "0px 40px",
                    textAlign: "center",
                  }}
                >
                  {showOnlyText
                    ? "Payment has already been completed on this link"
                    : "Payment Successful!"}{" "}
                </Typography>
              </div>
              <div className="domestic-s-divider-root">
                <div className="domestic-s-cut-1"></div>
                <div className="domestic-s-divider"></div>
                <div className="domestic-s-cut-2"></div>
              </div>
              <div className="domestic-s-summary dynamic-font-styling">
                {paymentSuccessDetails
                  ? paymentSuccessDetails?.map((data) => {
                      return (
                        <div className="domestic-s-summary-item">
                          <Typography className="domestic-s-summary-item-title">
                            {data?.title}
                          </Typography>
                          <Typography className="domestic-s-summary-item-value">
                            {data?.value}
                          </Typography>
                        </div>
                      );
                    })
                  : [...Array(3)].map(() => (
                      <Skeleton.Button active size="small" block />
                    ))}
                <div className="domestic-s-divider-2"></div>
                <div className="domestic-s-summary-item dynamic-font-styling">
                  {paymentSuccessData ? (
                    <>
                      <Typography className="domestic-s-summary-item-title">
                        Amount
                      </Typography>
                      <Typography className="domestic-s-amount">
                        <span>{paymentSuccessData?.currencyCode} </span>{" "}
                        {numberToCurrency(
                          paymentSuccessData?.amount,
                          paymentSuccessData?.currencyCode
                        )}
                      </Typography>
                    </>
                  ) : (
                    <Skeleton.Button active size="small" block />
                  )}
                </div>
              </div>
              <div className="domestic-s-footer-divider">
                <Divider />
              </div>

              <div className="domestic-s-footer dynamic-font-styling">
                <Typography className="domestic-s-summary-item-title dynamic-font-styling">
                  You may close the window.
                </Typography>
              </div>
            </div>
          ) : (
            <div
              style={{
                margin: "auto",
              }}
            >
              <div style={{ width: "258px" }}>
                <img
                  src="/assets/errorImage/oppsImage.svg"
                  style={{ width: "calc(166 / 161 * 100%)" }}
                />
              </div>
              <Typography className="oppsHeading">Oops!! </Typography>
              <Typography className="oppsSubHeading">
                Seems like you are lost.
              </Typography>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PaymentSuccess;
