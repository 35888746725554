import React from "react";
import PaymentIntrumentSelection from "../paymentInstrumentSelection/PaymentIntrumentSelection";
import PyamentButton from "../../paymentButton/PaymentButton";

export default function Bnpl({ walletMethodInfo, darkColor, lightColor }) {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <PaymentIntrumentSelection data={walletMethodInfo} />
      <PyamentButton />
    </div>
  );
}
