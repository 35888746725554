import { Card } from "antd";
import React from "react";
import "./cardContainer.css";

const CardContainer = ({ header, content, extra }) => {
  return (
    <Card title={header} className="domestic-mo-card" extra={extra}>
      {content}
    </Card>
  );
};

export default CardContainer;
