import React from "react";
import "./logoutMobile.css";
import Typography from "antd/es/typography/Typography";
import { useDispatch, useSelector } from "react-redux";
import {
  handleGuestLogedIn,
  resetShopperInfo,
} from "../../../slices/ShopperInfoSlice";
import { generateDynamicLogo } from "../../../Utils/utils";
import { selectBaseColor } from "../../../slices/PaymentSessionDetailsSlice";

const GuestLogoutMobile = () => {
  const baseColor = useSelector((state) => selectBaseColor(state));
  const dispatch = useDispatch();

  return (
    <div className="domestic-guest-logout-root dynamic-font-styling">
      <div
        className="domestic-mb-logout"
        onClick={() => {
          dispatch(handleGuestLogedIn(false));
          dispatch(resetShopperInfo());
        }}
      >
        {generateDynamicLogo("login", "12px", "12px", baseColor)}
        <Typography className="dynamic-title semiBold12Heading">
          Login
        </Typography>
      </div>
      <Typography
        className="regular12Heading"
        style={{ color: "var(--Neutral-Grey-1000)" }}
      >
        <span className="semiBold12Heading">for better experience</span>
      </Typography>
    </div>
  );
};

export default GuestLogoutMobile;
