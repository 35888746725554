import React, { useEffect } from "react";
import { Collapse, Typography } from "antd";
import { useSelector } from "react-redux";
import { generateDynamicStyling, numberToCurrency } from "../../../Utils/utils";

import "../fontStyling.css";

const OrderSummaryMobileContainer = ({ children }) => {
  const items = useSelector(
    (state) =>
      state.paymentSessionDetails.sessionDetails?.paymentDetails?.order?.items
  );

  const number_of_items = items?.reduce((acc, val) => {
    acc += val.quantity;
    return acc;
  }, 0);

  const getExtra = () => {
    return (
      <>
        {number_of_items && (
          <Typography
            className="semiBold12Heading dynamic-font-family"
            style={{ color: "var(--Neutral-Grey-1000)" }}
          >
            {`${number_of_items} ${number_of_items > 1 ? "items" : "item"}`}
          </Typography>
        )}
      </>
    );
  };
  return (
    <Collapse
      expandIconPosition="end"
      defaultActiveKey={["paymentSummary"]}
      items={[
        {
          key: "paymentSummary",
          label: (
            <Typography className="domestic-ps-mobile-header dynamic-font-family">
              Order Summary
            </Typography>
          ),
          children: <div className="dynamic-font-family">{children}</div>,
          extra: getExtra(),
        },
      ]}
    />
  );
};

export default OrderSummaryMobileContainer;
