export const utilityOptions = [
  {
    value: "electricity",
    label: "Electricity charges",
  },
  {
    value: "maintenance",
    label: "Maintenance charges",
  },
  {
    value: "water",
    label: "Water charges",
  },
];
