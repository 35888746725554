import { Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
import "./navBar.css";
import { Link } from "react-router-dom";
import navLogo from "../../assets/kutumb-nav-logo.svg";

const NavBar = () => {
  const [opneMenu, setOpenMenu] = useState(false);
  const navRef = useRef(null);

  const handleClickAway = (e) => {
    if (navRef.current && !navRef.current.contains(e.target)) {
      setOpenMenu(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickAway);
    return () => {
      document.removeEventListener("click", handleClickAway);
    };
  }, []);

  return (
    <div className="navbar-root" ref={navRef}>
      <div className="navbar-mobile">
        <Link
          to={"/kutumb"}
          onClick={() => {
            setOpenMenu(false);
          }}
        >
          <div className="nav-header">
            <div className="nav-logo">
              <img src={navLogo} height={24} width={24} />
            </div>

            <Typography className="kutumb-header-text">DLF Camellia</Typography>
          </div>
        </Link>
        <div
          className="nav-burger"
          onClick={() => {
            setOpenMenu((prev) => !prev);
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <div className={`nav-links ${opneMenu ? "open" : ""}`}>
        <Link
          to={"/kutumb/tnc"}
          onClick={() => {
            setOpenMenu((prev) => !prev);
          }}
        >
          <Typography className="kutumb-nav-link-text">
            Terms & Conditions
          </Typography>
        </Link>
        <Link
          to={"/kutumb/privacy_policy"}
          onClick={() => {
            setOpenMenu((prev) => !prev);
          }}
        >
          <Typography className="kutumb-nav-link-text">
            Privacy Policy
          </Typography>
        </Link>
      </div>
    </div>
  );
};

export default NavBar;
