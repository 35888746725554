import React from "react";
import { Collapse, Typography } from "antd";
import "./mobilePaymentNavigation.css";
import { useDispatch, useSelector } from "react-redux";
import {
  changeActivePaymentMethod,
  selectActivePaymentMethod,
} from "../../../../slices/PaymentButtonSlice";
import { categorySelectedEvent } from "../../../../Utils/uiAnalytics";
import { handleTotalPaymentSummaryError } from "../../../../slices/HandleAdditionalComponentsSlice";

const MobilePaymentNavigation = ({
  icon,
  title,
  subTitleStyling,
  children,
  collapseKey,
}) => {
  const activePaymentMethod = useSelector((state) =>
    selectActivePaymentMethod(state)
  );
  const dispatch = useDispatch();
  const sessionDetails = useSelector(
    (state) => state.paymentSessionDetails.sessionDetails
  );
  const onChange = (key) => {
    let latestSelcetedkey = key.slice(-1)[0];
    dispatch(changeActivePaymentMethod(latestSelcetedkey));
    categorySelectedEvent(sessionDetails, latestSelcetedkey, dispatch);
    dispatch(handleTotalPaymentSummaryError(""));
  };
  return (
    <Collapse
      expandIconPosition="end"
      className="payment-nav-root"
      activeKey={activePaymentMethod}
      onChange={onChange}
      items={[
        {
          key: collapseKey,
          label: (
            <div className=" dynamic-font-family" onClick={() => {}}>
              <div className="po-nav-logo-title">
                {icon}
                <Typography
                  className={
                    subTitleStyling
                      ? "po-nav-subtitle regular14Heading dynamic-font-family"
                      : "po-nav-title dynamic-font-family"
                  }
                >
                  {title}
                </Typography>
              </div>
            </div>
          ),
          children: <div className="dynamic-font-family">{children}</div>,
        },
      ]}
    />
  );
};

export default MobilePaymentNavigation;
