import React from "react";
import MobilePaymentNavigation from "../../mobilePaymentNavigation/MobilePaymentNavigation";
import { PlusCircleOutlined } from "@ant-design/icons";
import CardPaymentOption from "../cardPaymentOption";

export default function CardMobilePayment({ cardMethodInfo }) {
  return (
    <>
      <MobilePaymentNavigation
        icon={<PlusCircleOutlined style={{ color: "#4F4D55" }} />}
        title={"Pay via Card"}
        subTitleStyling={true}
        children={<CardPaymentOption />}
        collapseKey={"Card"}
      />
    </>
  );
}
