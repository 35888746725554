import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedTabValue: "",
  emiCreditBankSelected: "All Emi Options",
  emiDebitBankSelected: "All Emi Options",
  emiCode: [],
  emiPaymentButton: false,
};

export const emiCardDetailsSlice = createSlice({
  name: "emiCardDetails",
  initialState,
  reducers: {
    selectedTab(state, action) {
      state.selectedTabValue = action.payload;
    },
    changeBankDropdown(state, action) {
      state.emiCreditBankSelected = action.payload;
    },
    changeDebitBankDropdown(state, action) {
      state.emiDebitBankSelected = action.payload;
    },
    handleDurationValue(state, action) {
      state.duration = action.payload;
    },
    handleEMICode(state, action) {
      state.emiCode = action.payload;
    },
    handleEmiPayButton(state, action) {
      state.emiPaymentButton = action.payload;
    },
  },
});
export const {
  selectedTab,
  changeBankDropdown,
  changeDebitBankDropdown,
  handleDurationValue,
  handleEMICode,
  handleEmiPayButton,
} = emiCardDetailsSlice.actions;

export const tabValue = (state) => state.emiCardDetails.selectedTabValue;
export const creditBankSelected = (state) =>
  state.emiCardDetails.emiCreditBankSelected;
export const debitBankSelected = (state) =>
  state.emiCardDetails.emiDebitBankSelected;
export const durationValue = (state) => state.emiCardDetails.duration;
export const emiCodeValue = (state) => state.emiCardDetails.emiCode;
export const emiButton = (state) => state.emiCardDetails.emiPaymentButton;

export default emiCardDetailsSlice.reducer;
