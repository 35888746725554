import { configureStore } from "@reduxjs/toolkit";
import paymentSessionDetailsSliceReducer from "../slices/PaymentSessionDetailsSlice";
import shopperInfoReducer from "../slices/ShopperInfoSlice";
import postalDetailsSliceReduer from "../slices/PostalCodeSlice";
import binDetailSliceReducer from "../slices/BinSlice";
import paymentInitiationReducer from "../slices/PaymentInitiationSlice";
import finalStatusSliceReducer from "../slices/finalStatusSlice";
import savedAddressDetailsSliceReducer from "../slices/SavedAddressSlice";
import successPaymentSlice from "../slices/SuccessSlice";
import upiStatusSlice from "../slices/UpiSlice";
import mobileLayoutSliceReducer from "../slices/MobileLayoutSlice";
import savedInstrumentSliceReducer from "../slices/SavedInstrumentSlice";
import paymentButtonSliceReducer from "../slices/PaymentButtonSlice";
import cardDetailsSlice from "../slices/CardSlice";
import apiErrorSliceReducer from "../slices/ApiErrorSlice";
import emiCardDetailsSlice from "../slices/EmiSlice";
import transactionStatusSliceReducer from "../slices/TransactionIdSlice";
import { uiAnalyticsEventSlice } from "../slices/UIAnalyticsSlice";
import additionalComponentsSlice from "../slices/HandleAdditionalComponentsSlice";

export default configureStore({
  reducer: {
    shopperInfo: shopperInfoReducer,
    transactionStatus: transactionStatusSliceReducer,
    paymentInitiation: paymentInitiationReducer,
    paymentSessionDetails: paymentSessionDetailsSliceReducer,
    postalDetails: postalDetailsSliceReduer,
    binDetails: binDetailSliceReducer,
    savedAddressDetails: savedAddressDetailsSliceReducer,
    finalStatus: finalStatusSliceReducer,
    upiStatus: upiStatusSlice,
    cardDetails: cardDetailsSlice,
    successPayment: successPaymentSlice,
    mobileLayout: mobileLayoutSliceReducer,
    savedInstrument: savedInstrumentSliceReducer,
    paymentButton: paymentButtonSliceReducer,
    apiError: apiErrorSliceReducer,
    emiCardDetails: emiCardDetailsSlice,
    uiAnalytics: uiAnalyticsEventSlice,
    additionalComponents: additionalComponentsSlice,
  },
});
