import React, { useState } from "react";
import "./logoutMobile.css";
import Typography from "antd/es/typography/Typography";
import { useDispatch, useSelector } from "react-redux";
import {
  resetShopperInfo,
  selectMobileNumber,
} from "../../../slices/ShopperInfoSlice";
import { generateDynamicLogo } from "../../../Utils/utils";
import {
  removeUpdatedShopperDetails,
  selectBaseColor,
} from "../../../slices/PaymentSessionDetailsSlice";
import ErrorModal from "../modals/errorModal/ErrorModal";
import { CloseCircleOutlined } from "@ant-design/icons";
import { selectSavedAddresses } from "../../../slices/SavedAddressSlice";

const LogoutMobile = () => {
  const mobileNumber = useSelector((state) => selectMobileNumber(state));
  const baseColor = useSelector((state) => selectBaseColor(state));
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const savedAddresses = useSelector((state) => selectSavedAddresses(state));
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(resetShopperInfo());
    dispatch(removeUpdatedShopperDetails());
    sessionStorage.removeItem("latestSaved");
    setOpenLogoutModal(false);
  };

  return (
    <div className="domestic-logout-root dynamic-font-styling">
      <ErrorModal
        icon={
          <CloseCircleOutlined style={{ color: "#FAAD14", fontSize: "22px" }} />
        }
        title={"Are you sure you want to logout?"}
        desc={"Your current session will be ended and changes will be lost."}
        showModal={openLogoutModal}
        primaryOnClick={() => handleLogout()}
        secondaryOnClick={() => setOpenLogoutModal(false)}
        primaryBtnText={"Yes, logout"}
        secondaryBtnText={"No"}
        showSecondaryButton={true}
      />
      {mobileNumber && (
        <Typography
          className="regular12Heading"
          style={{ color: "var(--Neutral-Grey-1000)" }}
        >
          Showing details for{" "}
          <span className="semiBold12Heading">{mobileNumber}</span>
        </Typography>
      )}
      <div
        className="domestic-mb-logout"
        onClick={() => setOpenLogoutModal(true)}
      >
        {generateDynamicLogo("logout", "12px", "12px", baseColor)}
        <Typography className="dynamic-title semiBold12Heading">
          Logout
        </Typography>
      </div>
    </div>
  );
};

export default LogoutMobile;
