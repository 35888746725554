import React from "react";
import ShopperLogin from "./ShopperLogin";
import "./shopperLogin.css";
import { Card } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Typography } from "antd";

const ShopperLoginWebConatiner = ({ children }) => {
  return (
    <Card
      className="domestic-login-card"
      title={
        <div className="domestic-login-header">
          <UserOutlined />
          <Typography className="semiBold16Heading domestic-login-header-title dynamic-font-family">
            Login
          </Typography>
        </div>
      }
      bordered={false}
    >
      {children}
    </Card>
  );
};

export default ShopperLoginWebConatiner;
