import { Modal, Typography } from "antd";
import React from "react";
import {
  selectCurrencyCode,
  selectCurrencySymbol,
  selectDarkColor,
  selectLightColor,
  selectPaymentDetails,
} from "../../../../slices/PaymentSessionDetailsSlice";
import { useSelector } from "react-redux";
import {
  generateDynamicPaymentMethodLogo,
  numberToCurrency,
} from "../../../../Utils/utils";
import CustomButton from "../../../resuableComponents/CustomButton";
import { CloseOutlined } from "@ant-design/icons";
import "./viewSavedInstruments.css";
import PyamentButton from "../../paymentButton/PaymentButton";

const ViewSavedInstruments = ({
  type,
  header,
  handlePay,
  openModal,
  children,
  handleClose,
}) => {
  const paymentDetails = useSelector((state) => selectPaymentDetails(state));
  const currencyCode = useSelector((state) => selectCurrencyCode(state));
  const currencySymbol = useSelector((state) => selectCurrencySymbol(state));
  const lightColor = useSelector((state) => selectLightColor(state));
  const darkColor = useSelector((state) => selectDarkColor(state));

  return (
    <Modal
      centered
      open={openModal}
      footer={null}
      closable={false}
      width={"393px"}
      style={{
        maxHeight: "442px",
      }}
    >
      <div className="domestic-vsi-root">
        <div className="domestic-vsi-header-root">
          <div className="domestic-vsi-header-conatiner">
            <div className="domestic-vsi-header dynamic-font-styling">
              {generateDynamicPaymentMethodLogo(
                type,
                darkColor,
                lightColor,
                "20px",
                "20px"
              )}
              <Typography
                className="semiBold16Heading"
                style={{ color: "var(--character-title-85)" }}
              >
                {header}
              </Typography>
            </div>
            <CloseOutlined
              style={{ color: "var(--character-title-85)" }}
              onClick={() => handleClose()}
            />
          </div>
        </div>
        <div className="domestic-vsi-content">{children}</div>
        <div className="domestic-vsi-footer">
          <div style={{ padding: "16px 24px" }}>
            <PyamentButton/>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ViewSavedInstruments;
