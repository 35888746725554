import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  SelectMerchantDetails,
  selectFrontendBackUrl,
  selectHeaderTextColor,
} from "../../../slices/PaymentSessionDetailsSlice";
import { generateDynamicLogo } from "../../../Utils/utils";
import "./navBarCheckout.css";
import { Typography } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import ErrorModal from "../../components/modals/errorModal/ErrorModal";

const Navbar = () => {
  const headerTextColor = useSelector((state) => selectHeaderTextColor(state));
  const merchantDetails = useSelector((state) => SelectMerchantDetails(state));
  const returnUrl = useSelector((state) => selectFrontendBackUrl(state));
  const [open, setOpen] = useState(false);
  const callerType = useSelector(
    (state) => state.paymentSessionDetails.callerType
  );
  const isMerchantLogo = useSelector(
    (state) => state.additionalComponents.isMerchantLogo
  );
  const sampleToken = useSelector(
    (state) => state.paymentSessionDetails.sampleToken
  );
  return (
    <div className="domestic-navbar-root dynamic-header">
      <ErrorModal
        icon={
          <CloseCircleOutlined style={{ color: "#FAAD14", fontSize: "22px" }} />
        }
        title={"Are you sure you want to cancel order?"}
        desc={"All progress will be lost and the order will not be completed"}
        showModal={open}
        primaryOnClick={() => {
          window.location.href = returnUrl;
        }}
        secondaryOnClick={() => setOpen(false)}
        primaryBtnText={"Yes, cancel"}
        secondaryBtnText={"No"}
        showSecondaryButton={true}
      />
      <div className="nav-header-checkout">
        {callerType === "CHECKOUT" && !sampleToken && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => setOpen(true)}
          >
            {generateDynamicLogo(
              "leftArrow",
              "24px",
              "24px",
              "",
              headerTextColor
            )}
          </div>
        )}
        {isMerchantLogo && merchantDetails?.logoUrl && (
          <img className="nav-logo-checkout" src={merchantDetails?.logoUrl} />
        )}
        <Typography
          style={{
            color: headerTextColor,
          }}
        >
          {merchantDetails?.merchantName}{" "}
        </Typography>
      </div>
    </div>
  );
};

export default Navbar;
