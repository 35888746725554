import React, { useEffect } from "react";
import BoxPayCheckout from "./orchestrator/BoxPayCheckout";
import { clarity } from "react-microsoft-clarity";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Kutumb from "./kutumb/Kutumb";
import { toggleMobileLayout, toggleTabletLayout, setIphone, setAndroid, setNativeApp } from "./slices/MobileLayoutSlice";
import { useDispatch } from "react-redux";

function App() {
  clarity.init("lseof46hc2");
  const dispatch = useDispatch();
  // sets flag based on screensize
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      dispatch(toggleMobileLayout(width <= 680));
      dispatch(toggleTabletLayout(width >= 681 && width <= 1060));
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // sets flag based on device seletion
  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isIphone = /iphone/.test(userAgent.toLowerCase());
    const isAndroid = /android/.test(userAgent.toLowerCase());
    const isNativeApp = isIphone || isAndroid;
    dispatch(setIphone(isIphone));
    dispatch(setAndroid(isAndroid));
    dispatch(setNativeApp(isNativeApp));
  }, [])

  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<BoxPayCheckout />} />
          <Route exact path="/kutumb/*" element={<Kutumb />} />
          <Route exact path="*" element={<BoxPayCheckout />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
