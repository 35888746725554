import React, { useEffect, useState } from "react";
import "./styles.css";
import "../../components/fontStyling.css";
import "./layout.css";

import ShopperLogin from "../../components/shopperLogin/ShopperLogin";
import { Divider, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  removeUpdatedShopperDetails,
  selectExpiryTimeStamp,
  setIsSessionExpired,
  setIsSessionInvalid,
  updatePaymentMethodDictionary,
  updateShopperDetails,
  updateUniqueReference,
} from "../../../slices/PaymentSessionDetailsSlice";
import OrderSummary from "../../components/orderSummary/OrderSummary";
import PaymentSummary from "../../components/paymentSummary/PaymentSummary";

import Loading from "../../components/loadingScreen/Loading";
import BottomFooter from "../../components/checkoutFooter/BottomFooter";
import PaymentOptions from "../../components/paymentOptions/PaymentOptions";
import {
  generateDynamicLogo,
  generateDynamicStyling,
} from "../../../Utils/utils";
import DeliveryAddress from "../../components/address/deliveryAddress";
import {
  handleGuestLogedIn,
  resetShopperInfo,
  selectGuestCheckout,
  selectMobileNumber,
} from "../../../slices/ShopperInfoSlice";
import ShopperLoginWebConatiner from "../../components/shopperLogin/ShopperLoginWebConatiner";
import CardContainer from "../../resuableComponents/cardContainer/CardContainer";
import {
  getSavedIntsruments,
  selectSavedInstruments,
  updateSelectedIntruments,
} from "../../../slices/SavedInstrumentSlice";
import { selectActivePaymentMethod } from "../../../slices/PaymentButtonSlice";
import { changeUpiVpa } from "../../../slices/UpiSlice";
import ErrorPage from "../../components/errorPages/errorPage";
import { selectUnauthorised } from "../../../slices/ApiErrorSlice";
import ErrorModal from "../../components/modals/errorModal/ErrorModal";
import { selectSavedAddresses } from "../../../slices/SavedAddressSlice";
import { CloseCircleOutlined } from "@ant-design/icons";
import ExpiryTimer from "../../resuableComponents/ExpiryTimer.jsx";
import { checkoutLoadedAnalytics } from "../../../Utils/uiAnalytics";
import PaymentSuccess from "../../components/paymentSuccess/PaymentSuccess";

export default function Layouts() {
  const sessionDetails = useSelector(
    (state) => state.paymentSessionDetails.sessionDetails
  );
  const unauthorised = useSelector((state) => selectUnauthorised(state));
  const isLoggedIn = useSelector((state) => state.shopperInfo.isLoggedIn);
  const guestCheckout = useSelector((state) => selectGuestCheckout(state));
  const addressCollpase = useSelector(
    (state) => state.paymentSessionDetails.addressCollpase
  );
  const activePaymentMethod = useSelector((state) =>
    selectActivePaymentMethod(state)
  );
  const isShippingAddressDisplay = useSelector(
    (state) => state.paymentSessionDetails.isShippingAddressDisplay
  );
  const { showEmailAddress, showFullName, showContactNumber } = useSelector((state) => state.additionalComponents);

  const expiryTimeStamp = useSelector((state) => selectExpiryTimeStamp(state));
  const [loading, setLoading] = useState(true);
  const [duration, setDuration] = useState(null);
  const [showContent, setShowContent] = useState(false); // New state for delay

  const isSessionExpired = useSelector(
    (state) => state.paymentSessionDetails.isSessionExpired
  );
  const callerType = useSelector(
    (state) => state.paymentSessionDetails.callerType
  );
  const isSessionInvalid = useSelector(
    (state) => state.paymentSessionDetails.isSessionInvalid
  );
  const isSessionPaid = useSelector(
    (state) => state.paymentSessionDetails.isSessionPaid
  );
  const technicalError = useSelector(
    (state) => state.paymentSessionDetails.technicalError
  );
  const updatedShopperDetails = useSelector(
    (state) => state.paymentSessionDetails.updatedShopperDetails
  );
  const sampleToken = useSelector(
    (state) => state.paymentSessionDetails.sampleToken
  );
  const isShowTimerExpiry = useSelector(
    (state) => state.additionalComponents.isShowTimerExpiry
  );
  const showLogin = useSelector(
    (state) => state.additionalComponents.showLogin
  );
  const isGuestLogedIn = useSelector(
    (state) => state.shopperInfo.isGuestLogedIn
  );
  const showOrderItem = useSelector(
    (state) => state.additionalComponents.showOrderItem
  );
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const savedInstruments = useSelector((state) =>
    selectSavedInstruments(state)
  );

  useEffect(() => {
    if (expiryTimeStamp) {
      const newDate = new Date();
      const expTime = new Date(expiryTimeStamp);
      const timeDiff = expTime - newDate;
      const diffInSeconds = Math.floor(timeDiff / 1000);
      setDuration(diffInSeconds);
    }
  }, [expiryTimeStamp]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (sessionDetails) {
      generateDynamicStyling(sessionDetails);
    }
    if (isLoggedIn && !guestCheckout) {
      dispatch(getSavedIntsruments({ checkoutToken: sessionDetails?.token }));
    }
    if (sessionDetails?.token) {
      checkoutLoadedAnalytics(sessionDetails, dispatch);
    }
  }, [sessionDetails, isLoggedIn, guestCheckout, dispatch]);

  useEffect(() => {
    if (savedInstruments?.length > 0) {
      dispatch(
        updatePaymentMethodDictionary({
          Recommended: savedInstruments,
        })
      );
    }
  }, [savedInstruments]);

  useEffect(() => {
    if (Object.keys(sessionDetails)?.length) {
      const delay = setTimeout(() => {
        setLoading(false);
        setShowContent(true);
      }, 400);

      return () => clearTimeout(delay);
    } else if (sessionDetails?.length === 0) {
      setLoading(false);
    }
  }, [sessionDetails]);

  useEffect(() => {
    if (activePaymentMethod != "UpiSaved") {
      dispatch(updateSelectedIntruments(null));
    }
    dispatch(changeUpiVpa(null));
  }, [activePaymentMethod]);

  useEffect(() => {
    if (sessionDetails) {
      const isCheckoutDomain = window.location.hostname.includes(
        "checkout.boxpay.tech"
      );
      const isNewCheckoutDomain = window.location.hostname.includes(
        "checkout-v2.boxpay.tech"
      );
      const isPaymentLinkDomain =
        window.location.hostname.includes("pl.boxpay.tech");
      if (
        (callerType === "PAYMENT_LINK" &&
          (isCheckoutDomain || isNewCheckoutDomain)) ||
        (callerType === "CHECKOUT" && isPaymentLinkDomain)
      ) {
        dispatch(setIsSessionInvalid(true));
      }
    }
  }, [sessionDetails, callerType]);

  useEffect(() => {
    if (unauthorised) {
      dispatch(resetShopperInfo());
    }
  }, [unauthorised]);

  const handleLogout = () => {
    dispatch(resetShopperInfo());
    dispatch(removeUpdatedShopperDetails());
    setOpenLogoutModal(false);
    sessionStorage.removeItem("latestSaved");
  };

  const handleTimerExpire = () => {
    dispatch(setIsSessionExpired(true));
  };
  const latestSavedAddress = useSelector(
    (state) => state.paymentSessionDetails.latestSavedAddress
  );
  const shopperRef = localStorage.getItem("shopperRef");
  useEffect(() => {
    if (shopperRef) {
      dispatch(updateUniqueReference(shopperRef));
    } else if (latestSavedAddress) {
      const selectedCardData = latestSavedAddress;
      const currentPhoneNumber = selectedCardData?.phoneNumber;
      const currentEmail = selectedCardData?.email;
      if (
        sessionDetails?.paymentDetails?.shopper?.phoneNumber !==
        currentPhoneNumber
      ) {
        dispatch(updateUniqueReference(currentPhoneNumber));
      }
      if (sessionDetails?.paymentDetails?.shopper?.email !== currentEmail) {
        dispatch(updateUniqueReference(currentEmail));
      }
      if (
        sessionDetails?.paymentDetails?.shopper?.phoneNumber ===
        currentPhoneNumber &&
        sessionDetails?.paymentDetails?.shopper?.email === currentEmail
      ) {
        dispatch(
          updateUniqueReference(
            sessionDetails?.paymentDetails?.shopper?.phoneNumber ||
            sessionDetails?.paymentDetails?.shopper?.uniqueReference
          )
        );
      }
      dispatch(updateShopperDetails({ selectedCardData }));
    }
  }, [sessionDetails, latestSavedAddress, shopperRef]);

  return (
    <>
      <ErrorModal
        icon={
          <CloseCircleOutlined style={{ color: "#FAAD14", fontSize: "22px" }} />
        }
        title={"Are you sure you want to logout?"}
        desc={"Your current session will be ended and changes will be lost."}
        showModal={openLogoutModal}
        primaryOnClick={() => handleLogout()}
        secondaryOnClick={() => setOpenLogoutModal(false)}
        primaryBtnText={"Yes, logout"}
        secondaryBtnText={"No"}
        showSecondaryButton={true}
      />

      {loading ? (
        <Loading
          text="Please wait. We are taking you to the checkout page"
          subText="Note: Kindly avoid using back button"
        />
      ) : (
        <>
          {isSessionPaid ? (
            <PaymentSuccess />
          ) : isSessionExpired ? (
            <ErrorPage
              imgUrl="/assets/errorImage/sessionExpired.svg"
              heading={
                callerType === "PAYMENT_LINK"
                  ? "The payment link has expired"
                  : "The session has expired"
              }
              subHeading={
                callerType === "PAYMENT_LINK"
                  ? "You may now close the window"
                  : "Please go back and try again"
              }
            />
          ) : isSessionInvalid ? (
            <ErrorPage
              imgUrl="/assets/errorImage/invalidSession.svg"
              heading={
                callerType === "PAYMENT_LINK"
                  ? "Invalid Payment Link"
                  : "Invalid Session"
              }
              subHeading={
                callerType === "PAYMENT_LINK"
                  ? "Please change the URL or check it again"
                  : "Please contact support for further assistance"
              }
            />
          ) : technicalError ? (
            <ErrorPage
              imgUrl="/assets/errorImage/technical.svg"
              heading="Technical Error"
              subHeading="Please try again in some time"
            />
          ) : (
            <div className="domestic-layout-root">
              {showContent && (
                <>
                  <div className="review-layout-content">
                    <div className="review-pay-container">
                      {
                        (showEmailAddress || showFullName || showContactNumber || isShippingAddressDisplay) && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            <Typography className="review-pay">
                              Review & Pay
                            </Typography>
                            {isShowTimerExpiry && duration && (
                              <>
                                <Typography
                                  className="regular16Heading"
                                  style={{ color: "var(--Neutral-Grey-1000)" }}
                                >
                                  within{" "}
                                  <span
                                    className="regular16Heading"
                                    style={{
                                      color: "var(--Red-900)",
                                      fontWeight: 600,
                                    }}
                                  >
                                    <ExpiryTimer
                                      duration={duration}
                                      callback={handleTimerExpire}
                                    />
                                  </span>
                                </Typography>
                              </>
                            )}
                          </div>
                        )
                      }

                      <div className="domestic-layout-left-side-header">
                        {isLoggedIn && (
                          <div
                            className="domestic-layout-left-side-header-logout"
                            onClick={() => {
                              setOpenLogoutModal(true);
                            }}
                          >
                            {generateDynamicLogo(
                              "logout",
                              "16px",
                              "16px",
                              sessionDetails?.merchantDetails?.checkoutTheme
                                ?.headerColor
                            )}
                            <Typography className="dynamic-title logout-font">
                              Logout
                            </Typography>
                          </div>
                        )}
                        {isGuestLogedIn && (
                          <div
                            className="domestic-layout-left-side-header-logout"
                            onClick={() => {
                              dispatch(handleGuestLogedIn(false));
                              dispatch(resetShopperInfo());
                            }}
                          >
                            {generateDynamicLogo(
                              "login",
                              "16px",
                              "16px",
                              sessionDetails?.merchantDetails?.checkoutTheme
                                ?.headerColor
                            )}
                            <Typography className="dynamic-title logout-font">
                              Login
                            </Typography>
                          </div>
                        )}
                      </div>
                    </div>

                    <div style={{ display: "none" }}></div>
                  </div>
                  <div className="domestic-layout-content">
                    <div className="domestic-layout-left-side">
                      {!isLoggedIn &&
                        !guestCheckout &&
                        !sampleToken &&
                        showLogin && (
                          <div style={{ marginBottom: "24px" }}>
                            <ShopperLoginWebConatiner
                              children={<ShopperLogin />}
                            />
                          </div>
                        )}
                      {isLoggedIn ||
                        guestCheckout ||
                        sampleToken ||
                        !showLogin ? (
                        <div style={{ marginBottom: (showEmailAddress || showFullName || showContactNumber || isShippingAddressDisplay) && "24px" }}>
                          <DeliveryAddress />
                        </div>
                      ) : (
                        isShippingAddressDisplay && (
                          <div
                            className="emptyCard"
                            style={{ marginBottom: "24px" }}
                          >
                            <CardContainer
                              content={
                                <div className="emptyCardHeading">
                                  <img
                                    src="/assets/address/truck.svg"
                                    alt="delivery"
                                    className="emptyCardIcon"
                                  />
                                  <Typography className="emptyCardHeading dynamic-font-family">
                                    Delivery Address
                                  </Typography>
                                </div>
                              }
                            />
                          </div>
                        )
                      )}
                      {(isLoggedIn || guestCheckout || showLogin === false) &&
                        addressCollpase &&
                        Object.keys(updatedShopperDetails)?.length ? (
                        <div>
                          <PaymentOptions />
                        </div>
                      ) : (
                        <div className="emptyCard">
                          <CardContainer
                            content={
                              <div className="emptyCardHeading">
                                <img
                                  src="/assets/cardHeader/card.svg"
                                  alt="card"
                                  className="emptyCardIcon"
                                />
                                <Typography className="emptyCardHeading dynamic-font-family">
                                  Payment Options
                                </Typography>
                              </div>
                            }
                          />
                        </div>
                      )}
                    </div>
                    <div className="domestic-layout-right-side">
                      <OrderSummary />
                      <PaymentSummary />
                    </div>
                  </div>
                  <Divider className="domestic-layout-content-footer-divider" />
                  <div className="domestic-layout-footer">
                    <BottomFooter />
                  </div>
                </>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
}
