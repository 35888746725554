import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  colorShadesGenerator,
  generatePaymentOptionsDictionary,
  getStroage,
  isEmpty,
  updateStroage,
} from "../Utils/utils";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const paymentSession = createAsyncThunk(
  "paymentSessionDetails/paymentSession",
  async (token) => {
    try {
      const response = await axios.get(`${baseUrl}/checkout/sessions/${token}`);
      return response?.data;
    } catch (error) {
      if (error?.response?.status === 404) {
        throw new Error("Session not found");
      } else {
        throw error;
      }
    }
  }
);
export const getAllPaymentMethods = createAsyncThunk(
  "paymentSessionDetails/getAllPaymentMethods",
  async ({ token, countryCode }) => {
    try {
      let url = `${baseUrl}/checkout/sessions/${token}/payment-methods`;
      if (countryCode) {
        url += `?customerCountryCode=${countryCode}`;
      }
      const response = await axios.get(url);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

const initialState = {
  sessionDetails: getStroage("session", "s_d") || {
    paymentDetails: {
      shopper: {},
    },
  },
  paymentMethodDictionary: getStroage("session", "paymentMethods") || {},
  sessionDetailsLoading: false,
  errorMessage: "",
  darkColor: "#fff",
  lightColor: "#fff",
  selectionColor: "#fff",
  baseColor: "#fff",
  headerTextColor: "#fff",
  addressCollpase: getStroage("session", "addressCollpase") || false,
  latestSavedAddress: getStroage("session", "latestSavedAddress") || {},
  isSessionExpired: false,
  isSessionInvalid: false,
  isSessionPaid: false,
  isShippingAddressDisplay: true,
  callerType: "",
  technicalError: false,
  updatedShopperDetails: {},
  sessionDetailsStatusReason: "",
  paymentMethodsLoading: [],
  paymentMethodsBasedOnCountry: [],
  paymentMethodsError: [],

  uniqueReference: "",
  sampleToken: false,
};
const extractStatusReason = (statusReason) => {
  if (statusReason && statusReason.includes(":")) {
    return statusReason.split(":").slice(1).join(":").trim();
  }
  return statusReason;
};

export const paymentSessionDetailsSlice = createSlice({
  name: "paymentSessionDetails",
  initialState,
  reducers: {
    updateUniqueReference: (state, action) => {
      state.uniqueReference = action.payload;
    },
    updateShopperDetails: (state, action) => {
      const {
        name = "",
        email,
        address1,
        address2,
        city,
        state: shopperState,
        countryCode,
        postalCode,
        gender,
        phoneNumber,
        labelName,
        labelType,
        deliveryAddress,
      } = action.payload.selectedCardData;

      const parts = name.trim().split(" ");
      const lname = parts.length > 1 ? parts.pop() : "";
      const fname = parts.join(" ");

      const newShopper = {
        firstName: isEmpty(fname) ? undefined : fname,
        lastName: isEmpty(lname) ? undefined : lname,
        email,
        gender,
        phoneNumber,
        deliveryAddress: {
          address1,
          address2,
          city,
          state: shopperState,
          countryCode,
          postalCode,
          labelName,
          labelType,
        },
        uniqueReference: state.uniqueReference ? state.uniqueReference : state.sessionDetails?.paymentDetails?.shopper?.uniqueReference,
      };
      state.updatedShopperDetails = newShopper;
    },

    updatePaymentMethods: (state, action) => {
      const newSessionDetails = {
        ...state.sessionDetails,
        sessionDetails: {
          ...state.sessionDetails,
          configs: {
            ...state.sessionDetails.configs,
            paymentMethods: action.payload,
          },
        },
      };

      return {
        ...state,
        sessionDetails: newSessionDetails,
      };
    },

    updateSavedAddressData: (state, action) => {
      state.latestSavedAddress = action.payload;
      sessionStorage.setItem("latestSavedAddress", JSON.stringify(action.payload));
    },

    removeUpdatedShopperDetails: (state, action) => {
      state.updatedShopperDetails = {};
    },
    removeAddressfield: (state) => {
      const updatedAddress = { ...state.latestSavedAddress };
      for (const key in updatedAddress) {
        if (key !== "labelType" && key !== "labelName") {
          delete updatedAddress[key];
        }
      }
      state.latestSavedAddress = updatedAddress;
      sessionStorage.setItem("latestSavedAddress", JSON.stringify(updatedAddress));
    },
    openAddressComponent: (state) => {
      state.addressCollpase = false;
    },
    collpaseAddressComponent: (state) => {
      updateStroage("session", "update", "addressCollpase", true);
      state.addressCollpase = true;
    },

    setIsSessionExpired: (state, action) => {
      state.isSessionExpired = action.payload;
    },

    setIsSessionInvalid: (state, action) => {
      state.isSessionInvalid = action.payload;
    },
    setIsSessionPaid: (state, action) => {
      state.isSessionPaid = action.payload;
    },
    updatePaymentMethodDictionary: (state, action) => {
      let newDict = action.payload || {};
      state.paymentMethodDictionary = state.paymentMethodDictionary || {};
      newDict = Object.assign({}, state.paymentMethodDictionary, newDict);
      updateStroage("session", "update", "paymentMethods", newDict);
      state.paymentMethodDictionary = newDict;
    },
    removePaymentMethods: (state) => {
      state.paymentMethodDictionary = null;
      sessionStorage.removeItem("paymentMethods");
    },
    storeSessionDetails: (state) => {
      updateStroage("session", "update", "s_d", state);
    },
    handleIsShippingAddressDisplay: (state, action) => {
      state.isShippingAddressDisplay = action.payload;
    },
    resetErrorMsg: (state, action) => {
      state.sessionDetailsStatusReason = "";
    },
  },
  extraReducers: {
    [paymentSession.pending]: (state) => {
      state.sessionDetailsLoading = true;
    },
    [paymentSession.fulfilled]: (state, action) => {
      updateStroage("session", "update", "s_d", action.payload);
      state.sessionDetailsLoading = false;
      state.sessionDetails = action.payload;
      if (
        action.payload &&
        action.payload?.status === "PAID" &&
        action.payload?.callerType
      ) {
        action.payload?.callerType === "CHECKOUT"
          ? window.location.replace(
            action.payload?.paymentDetails?.frontendReturnUrl
          )
          : window.location.replace(`/finalStatus`);
      }

      // if (
      //   (state.latestSavedAddress &&
      //     state.latestSavedAddress["postalCode"] !== undefined) ||
      //   action.payload?.paymentDetails?.shopper?.deliveryAddress !== null
      // ) {
      //   state.addressCollpase = true;
      // }

      state.baseColor =
        action.payload?.merchantDetails?.checkoutTheme?.headerColor;
      state.headerTextColor =
        action.payload?.merchantDetails?.checkoutTheme?.headerTextColor;
      const shades = colorShadesGenerator(
        action.payload?.merchantDetails?.checkoutTheme?.headerColor
      );

      state.sessionDetailsStatusReason = extractStatusReason(
        action.payload.lastTransactionDetails?.status?.reason
      );
      let dict = generatePaymentOptionsDictionary(
        action?.payload?.configs?.paymentMethods
      );
      state.paymentMethodDictionary = dict;
      updateStroage("session", "update", "paymentMethods", dict);

      if (typeof shades[12] !== "undefined") {
        state.darkColor = "#" + shades[12]?.hex;
      }
      if (typeof shades[10] !== "undefined") {
        state.lightColor = "#" + shades[10]?.hex;
      }
      if (typeof shades[1] !== "undefined") {
        state.selectionColor = "#" + shades[1]?.hex;
      }
      if (action.payload.status === "EXPIRED") {
        state.isSessionExpired = true;
      } else {
        state.isSessionExpired = false;
      }
      if (action.payload.callerType) {
        state.callerType = action.payload.callerType;
      }
      if (action.payload.token.includes("st_")) {
        state.sampleToken = true;
      }
    },
    [paymentSession.rejected]: (state, action) => {
      state.sessionDetailsLoading = false;
      state.sessionDetails = [];
      state.errorMessage = action.error.payload;
      state.sessionDetailsStatusReason = "";
      if (action.error?.message === "Session not found") {
        state.isSessionInvalid = true;
        state.technicalError = false;
      } else {
        state.errorMessage = action.error.message;
        state.isSessionInvalid = false;
        state.technicalError = true;
      }
    },
    [getAllPaymentMethods.pending]: (state) => {
      state.paymentMethodsLoading = true;
    },
    [getAllPaymentMethods.fulfilled]: (state, action) => {
      state.paymentMethodsBasedOnCountry = action.payload;
      let dict = generatePaymentOptionsDictionary(action?.payload);
      updateStroage("session", "update", "paymentMethods", dict);
      state.paymentMethodDictionary = dict;
      state.paymentMethodsLoading = false;
    },
    [getAllPaymentMethods.rejected]: (state, action) => {
      state.paymentMethodDictionary = {};
      state.paymentMethodsLoading = false;
      state.paymentMethodsBasedOnCountry = [];
      state.paymentMethodsError = action.error.payload;
    },
  },
});

export const selectCurrencyCode = (state) =>
  state?.paymentSessionDetails?.sessionDetails?.paymentDetails?.money
    ?.currencyCode;

export const selectCurrencySymbol = (state) =>
  state?.paymentSessionDetails?.sessionDetails?.paymentDetails?.money
    ?.currencySymbol;

export const selectToatalAmount = (state) =>
  state?.paymentSessionDetails?.sessionDetails?.paymentDetails?.money?.amount;

export const selectPaymentDetails = (state) =>
  state?.paymentSessionDetails?.sessionDetails?.paymentDetails;

export const selectLightColor = (state) =>
  state?.paymentSessionDetails?.lightColor;

export const selectDarkColor = (state) =>
  state?.paymentSessionDetails?.darkColor;

export const selectBaseColor = (state) =>
  state?.paymentSessionDetails?.baseColor;

export const selectHeaderTextColor = (state) => {
  let stateHeaderTxtColor = state?.paymentSessionDetails?.headerTextColor;
  let sessionHeaderTxtColor = getStroage('session', 's_d')?.merchantDetails?.checkoutTheme?.headerTextColor
  return sessionHeaderTxtColor || stateHeaderTxtColor;
}

export const selectSelectionColor = (state) =>
  state?.paymentSessionDetails?.selectionColor;

export const selectFontFamily = (state) =>
  state?.paymentSessionDetails?.sessionDetails?.merchantDetails?.checkoutTheme
    ?.font;

export const selectTemplate = (state) =>
  state?.paymentSessionDetails?.sessionDetails?.merchantDetails?.template;

export const SelectMerchantDetails = (state) =>
  state?.paymentSessionDetails?.sessionDetails?.merchantDetails;

export const selectExpiryTimeStamp = (state) => {
  return state?.paymentSessionDetails?.sessionDetails?.sessionExpiryTimestamp;
};
export const selectFrontendBackUrl = (state) => {
  return state?.paymentSessionDetails?.sessionDetails?.paymentDetails
    ?.frontendBackUrl;
};

export const selectToken = (state) => {
  return state?.paymentSessionDetails?.sessionDetails?.token;
};

export const {
  updateUniqueReference,
  updateShopperDetails,
  updatePaymentMethods,
  updateSavedAddressData,
  removeAddressfield,
  removeUpdatedShopperDetails,
  openAddressComponent,
  collpaseAddressComponent,
  setIsSessionExpired,
  setIsSessionInvalid,
  setIsSessionPaid,
  updatePaymentMethodDictionary,
  removePaymentMethods,
  handleIsShippingAddressDisplay,
  resetErrorMsg,
} = paymentSessionDetailsSlice.actions;

export default paymentSessionDetailsSlice.reducer;
