import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showSuccessPayment: false,
  successPaymentData: null,
};

export const successPaymentSlice = createSlice({
  name: "successPayment",
  initialState,
  reducers: {
    toggleSuccessPayment(state) {
      state.showSuccessPayment = !state.showSuccessPayment;
    },
    updateSuccessData(state, action) {
      state.successPaymentData = action.payload;
    },
  },
});
export const { toggleSuccessPayment, updateSuccessData } =
  successPaymentSlice.actions;

export const selectShowSuccessPayment = (state) =>
  state.successPayment.showSuccessPayment;
export const selectSuccesPaymentData = (state) =>
  state.successPayment.successPaymentData;

export default successPaymentSlice.reducer;
