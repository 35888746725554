export const countryPinCodeData = [
  {
    name: "India",
    dial_code: "+91",
    code: "IN",
    pinCodeLength: 6,
    phoneNumberLength: 10,
  },
  {
    name: "Singapore",
    dial_code: "+65",
    code: "SG",
    pinCodeLength: 6,
    phoneNumberLength: 8,
  },
];
