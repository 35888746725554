import React from "react";
import { Skeleton } from "antd";
import { useSelector } from "react-redux";
import "./orderSummary.css";
import SubscriptionOrderSummary from "./subscription/SubscriptionOrderSummary";
import ProductOrderSummary from "./productOrderSummary/ProductOrderSummary";
import PaymentLinkOrderSummary from "./paymentLinkOrderSummary/paymentLinkOrderSummary";
import DefaultOderSummary from "./defaultOrderSummary";

const OrderSummary = () => {
  const sessionDetails = useSelector(
    (state) => state.paymentSessionDetails.sessionDetails
  );
  const sessionDetailsLoading = useSelector(
    (state) => state.paymentSessionDetails.sessionDetailsLoading
  );
  const showOrderItem = useSelector(
    (state) => state.additionalComponents.showOrderItem
  );

  return (
    <div>
      {sessionDetailsLoading ? (
        <Skeleton active size="large" />
      ) : (
        <>
          {showOrderItem &&
          sessionDetails?.paymentDetails?.subscriptionDetails ? (
            <SubscriptionOrderSummary />
          ) : showOrderItem &&
            (sessionDetails?.title ||
              sessionDetails?.paymentDetails?.product ||
              sessionDetails?.description) ? (
            <PaymentLinkOrderSummary />
          ) : showOrderItem && sessionDetails?.paymentDetails?.order?.items ? (
            <ProductOrderSummary />
          ) : (
            showOrderItem &&
            sessionDetails?.paymentDetails?.context?.orderId && (
              <DefaultOderSummary />
            )
          )}
        </>
      )}
    </div>
  );
};

export default OrderSummary;
