import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const getPostalDetails = createAsyncThunk(
  "postalDetails/getPostalDetails",
  async ({ token, countryCode, postalCode }) => {
    try {
      const response = await axios.get(
        `${baseUrl}/checkout/sessions/${token}/postal-codes?postalCode=${postalCode}&countryCode=${countryCode}`
      );
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

const initialState = {
  postalDetails: {},
  postalDetailLoading: false,
  postalErrorMessage: "",
};

export const postalDetailsSlice = createSlice({
  name: "postalDetails",
  initialState,
  reducers: {
    removePostalData: (state) => {
      state.postalDetails = {};
    },
  },
  extraReducers: {
    [getPostalDetails.pending]: (state) => {
      state.postalDetailLoading = true;
    },
    [getPostalDetails.fulfilled]: (state, action) => {
      state.postalDetailLoading = false;
      state.postalDetails = action.payload;
    },
    [getPostalDetails.rejected]: (state, action) => {
      state.postalDetailLoading = false;
      state.postalDetails = [];
      state.postalErrorMessage = action.error.payload;
    },
  },
});
export const { removePostalData } = postalDetailsSlice.actions;

export default postalDetailsSlice.reducer;
