import React, { useEffect, useState } from "react";
import { selectSavedInstruments } from "../../../../../slices/SavedInstrumentSlice";
import { useDispatch, useSelector } from "react-redux";
import OrComponent from "../../../../resuableComponents/orUIComponent/OrComponent";
import UpiVpa from "../UpiVpa";
import ViewSavedInstrumentsHeader from "../../../modals/viewSavedInstrumentsModal/ViewSavedInstrumentsHeader";
import {
  changeActivePaymentMethod,
  changePaymentMethodInfo,
  selectActivePaymentMethod,
} from "../../../../../slices/PaymentButtonSlice";
import UpiQr from "../UpiQr";

const UpiWeb = ({ upiMethodInfo }) => {
  const [upiCollectInstruments, setUpiCollectInstruments] = useState([]);
  const activePaymentMethod = useSelector((state) =>
    selectActivePaymentMethod(state)
  );
  const dispatch = useDispatch();
  const savedInstruments = useSelector((state) =>
    selectSavedInstruments(state)
  );

  let upiCollect = upiMethodInfo?.find(
    (upiInfo) => upiInfo.brand === "UpiCollect"
  );
  let upiOr = upiMethodInfo?.find((upiInfo) => upiInfo.brand === "UpiQr");
  useEffect(() => {
    if (savedInstruments) {
      let upiCollect = savedInstruments?.filter(
        (instrumentInfo) => instrumentInfo?.brand === "UpiCollect"
      );
      setUpiCollectInstruments(upiCollect);
    }
  }, [savedInstruments]);

  useEffect(() => {
    dispatch(changePaymentMethodInfo(upiCollect));
  }, [activePaymentMethod]);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
      {upiOr && (
        <>
          <UpiQr upiData={upiOr} />
        </>
      )}
      {upiOr && upiCollectInstruments?.length > 0 && <OrComponent />}

      {upiCollectInstruments?.length > 0 && (
        <div>
          <ViewSavedInstrumentsHeader
            data={upiCollectInstruments}
            title={"Saved UPI"}
            type={"Upi"}
          />
        </div>
      )}

      {upiCollect && (upiOr || upiCollectInstruments?.length > 0) && (
        <OrComponent />
      )}

      {upiCollect && (
        <>
          <UpiVpa upiData={upiCollect} />
        </>
      )}
    </div>
  );
};

export default UpiWeb;
