import React from "react";
import DataContainer from "../components/dataContainer/DataContainer";
import { Typography } from "antd";
import "./home.css";

const privacy = () => {
  return (
    <div className="privacy-root">
      <DataContainer
        title={"Privacy Policy"}
        description={
          <>
            <Typography>
            The information provided by the user of https://www.camellias.co.in/ shall be in accordance to the respective laws. The information/data provided by the user may be used by all divisions/sister concerns/ventures of the https://www.camellias.co.in/to carry out the purpose of the project, whereas in no case it shall be communicated/ transformed/spread to any company/third party or whatsoever for any other commercial usage without the consent of the user.
            </Typography>
            <Typography>
            Updating/modification/deletion of user’s data/ information shall be done on the written request of the user himself or his representative or his authorized person only.
            </Typography>
            <Typography>
            By using this Site, the Users understand that all the information provided by you including and not limited to the company’s information, property listing details are being submitted in the Site and by registering on this Site, you agree that the information posted by you is a public document that can be accessed by other Users of the Site.
            </Typography>
            <Typography>
            https://www.camellias.co.in/ is committed to respecting the online privacy of its Users and doesn’t intend to sell, rent or share the Personal Information of Users to Third Party. The Site shall not transfer any such information unless it is legally required or to provide services to support your search for candidates. You agree to not hold https://www.camellias.co.in/ liable for any breach in privacy due to technical or any other means.
            </Typography>
            <Typography>
            https://www.camellias.co.in/strives to provide a safe, secure User experience. The Site is committed to protect your Privacy but cannot guarantee / promise the same and you understand and agree that you assume all responsibility and risk for your use of the Site.
            </Typography>
            <Typography>
            Being an Intermediary, the role of the Site is limited to providing a platform for the Users to interact and we have no control over the data usage practiced by the Users who use the services of the Site.
            </Typography>
            <Typography>
            By using the Services of the Site you consent to collection, storage, and use of the personal information you provide (including any changes thereto as provided by you) for any of the Services offered by the Site and consent that https://www.camellias.co.in/can contact your for offers, updates, service-related information, send newsletters, for feedback, surveys, etc.
            </Typography>
            <Typography>
            https://www.camellias.co.in/ advises its User to exercise complete caution while completing online transactions and it is the sole responsibility of the User to verify and validate the information present on the site as well as the people whom they choose to interact with. https://www.camellias.co.in/ shall not be responsible for any loss / damage due to negligence of the User. You have sole authority to access and update all the information posted by you on the Site and it is your responsibility to keep the same updated. It is strictly advised that Users respect the Privacy of other Users as well as keep their profiles updated. Access to services of the Site including updation / deletion of profile may be restricted / denied if it comes to the notice of https://www.camellias.co.in/ that this would violate anybody else’s rights or if so requested by a legal entity.
            </Typography>
          </>
        }
      />
    </div>
  );
};

export default privacy;
