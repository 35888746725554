import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import * as jose from "jose";
import { importKey } from "./importKey";
import { Input } from "antd";
import {
  cardCvcData,
  cardExpiryDateData,
  cardHolderNameData,
  cardNumberData,
} from "../slices/CardSlice";
import InnerHTML from "dangerously-set-html-content";

export default function PaymentResponseHandler() {
  const [response, setResponse] = useState(undefined);
  const paymentInitiationData = useSelector(
    (state) => state.paymentInitiation.paymentInitiationData
  );
  const cardNumber = useSelector((state) => cardNumberData(state));
  const cardHolderName = useSelector((state) => cardHolderNameData(state));
  const cardExpiryDate = useSelector((state) => cardExpiryDateData(state));
  const cardCvc = useSelector((state) => cardCvcData(state));
  useEffect(() => {
    if (paymentInitiationData === undefined && response === undefined) {
      return;
    }

    if (paymentInitiationData !== response) {
      setResponse(paymentInitiationData);
    }
  }, [paymentInitiationData]);
  const isValidUrl = (urlString) => {
    var urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // validate fragment locator
    return !!urlPattern.test(urlString);
  };

  const formRef = useRef("payment-response-form");
  const [processedResponse, setProcessedResponse] = useState();

  useEffect(() => {
    (async () => {
      const encryptionWork = {};
      const encryptAction = response?.actions?.find(
        (action) => action.type === "encrypt"
      );
      if (encryptAction) {
        const publicKey = await importKey(encryptAction.encryptionKey);

        const [expiry_month, expiry_year] = cardExpiryDate.split("/");
        const cardNo = cardNumber?.replace(/\s/g, "");
        const cardDetails = {
          number: cardNo,
          expiry_month: expiry_month,
          expiry_year: `20${expiry_year}`,
          holder_name: cardHolderName,
          cvv: cardCvc,
        };

        const jwe = await new jose.CompactEncrypt(
          new TextEncoder().encode(JSON.stringify(cardDetails))
        )
          .setProtectedHeader({
            alg: encryptAction.algo,
            enc: "A128GCM",
            kid: encryptAction.keyId,
            "x5t#S256": encryptAction.certificateFingerprint,
          })
          .encrypt(publicKey);

        encryptionWork[encryptAction.outputField] = jwe;

        setProcessedResponse((prev) => {
          const newResponse = { ...response };
          newResponse.actions = response?.actions?.map((action) => {
            const newAction = { ...action };
            newAction.data = { ...action.data, ...encryptionWork };
            return newAction;
          });
          return newResponse;
        });
      } else {
        setProcessedResponse(response);
      }
    })();
  }, [response]);

  useEffect(() => {
    if (
      processedResponse !== undefined &&
      formRef.current?.submit !== undefined
    ) {
      formRef.current.submit();
    }

    // this is needed as html react parser doesn't execute javascript
    if (document.getElementById("autosubmit-form") !== null) {
      document.getElementById("autosubmit-form").submit();
    }
    if (document.getElementById("payment_post") !== null) {
      document.getElementById("payment_post").submit();
    }
    if (document.getElementById("threedsChallengeRedirectForm") !== null) {
      document.getElementById("threedsChallengeRedirectForm").submit();
    }
  }, [processedResponse]);

  const addQueryParamsToObject = (url, data) => {
    if (data === undefined) {
      data = {};
    } else {
      data = JSON.parse(JSON.stringify(data));
    }

    if (url.includes("?")) {
      var queryString = url.split("?")[1];

      // Split the query string into an array of key-value pairs
      var params = queryString.split("&");

      // Loop through each key-value pair
      params.forEach(function (param) {
        var pair = param.split("=");
        var key = decodeURIComponent(pair[0]);
        var value = decodeURIComponent(pair[1] || "");

        // Add the key-value pair to the paramsObject
        data[key] = value;
      });
    }

    return data;
  };

  return (
    <>
      {processedResponse?.actions &&
        processedResponse?.actions?.length > 0 &&
        processedResponse?.actions
          ?.filter(
            (action) =>
              action?.type === "html" && action?.htmlPageString !== undefined
          )
          .map((action) => {
            return (
              <InnerHTML
                id="dynamic-html-container"
                html={action.htmlPageString}
              />
            );
          })}

      {processedResponse?.actions &&
        processedResponse?.actions?.length > 0 &&
        processedResponse?.actions
          ?.filter((action) => action?.type === "redirect")
          .map((action) => {
            if (isValidUrl(action?.url)) {
              return (
                <form
                  key={action?.url}
                  method={action?.method}
                  ref={formRef}
                  action={action?.url}
                >
                  {Object.entries(
                    addQueryParamsToObject(action?.url, action?.data)
                  ).map((entry) => {
                    return (
                      <Input
                        key={entry[0]}
                        name={entry[0]}
                        value={entry[1]}
                        type="hidden"
                      />
                    );
                  })}
                </form>
              );
            }
          })}

      {response?.actions
        ?.filter((action) => action?.type === "appRedirect")
        .map((action) => {
          const appUrl = atob(action?.url);
          return (
            <form
              key={appUrl}
              method={action?.method}
              ref={formRef}
              action={appUrl}
            >
              {Object.entries(addQueryParamsToObject(appUrl, action?.data)).map(
                (entry) => {
                  return (
                    <Input
                      key={entry[0]}
                      name={entry[0]}
                      value={entry[1]}
                      type="hidden"
                    />
                  );
                }
              )}
            </form>
          );
        })}
    </>
  );
}
