import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import NavBar from "./components/navBar/NavBar";
import "./kutumb.css";
import TnC from "./pages/TnC";
import Privacy from "./pages/Privacy";

const Kutumb = () => {
  return (
    <div className="app-root">
      <NavBar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/tnc" element={<TnC />} />
        <Route exact path="/privacy_policy" element={<Privacy />} />
      </Routes>
    </div>
  );
};

export default Kutumb;
