import { Form, Input, Select, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { CountryData } from "../../../staticData/countryData";
import { blockInvalidChar, isEmpty } from "../../../Utils/utils";
import { addressAnalyticsEvent } from "../../../Utils/uiAnalytics";
import { useDispatch, useSelector } from "react-redux";
import { countryPinCodeData } from "../../../staticData/countryPinCodeData";
import CustomButton from "../../resuableComponents/CustomButton";
import "./deliveryAddress.css";
import { selectIsMobileLayout } from "../../../slices/MobileLayoutSlice";
import {
  collpaseAddressComponent,
  getAllPaymentMethods,
  updateSavedAddressData,
  updateShopperDetails,
} from "../../../slices/PaymentSessionDetailsSlice";
import CollpaseAddress from "./collpaseAddress";
import { UserOutlined } from "@ant-design/icons";
import MobileAddressButton from "./mobileAddressButon";

export default function BasicDetails({
  addressFieldValue,
  setShowAddAddressOption,
  setSelectedCardButton,
  setAddressFieldValue,
  handleGuestData,
}) {
  const dispatch = useDispatch();
  const sessionDetails = useSelector(
    (state) => state.paymentSessionDetails.sessionDetails
  );
  const addressCollpase = useSelector(
    (state) => state.paymentSessionDetails.addressCollpase
  );
  const isShippingAddressDisplay = useSelector(
    (state) => state.paymentSessionDetails.isShippingAddressDisplay
  );
  const { showEmailAddress, showFullName, showContactNumber } = useSelector((state) => state.additionalComponents)
  const isMobileLayout = useSelector((state) => selectIsMobileLayout(state));
  const guestCheckout = useSelector((state) => state.shopperInfo.guestCheckout);
  const shopperinfo = useSelector((state) => state.shopperInfo.shopperinfo);
  const [form] = Form.useForm();
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [phoneDialCode, setPhoneDialCode] = useState("");
  const [payloadData, setPayloadData] = useState("");
  const [disableButton, setDisableButton] = useState(true);
  const filterCountry = (input, option) =>
    (option?.props?.children ?? "").toLowerCase().includes(input.toLowerCase());
  useEffect(() => {
    if (shopperinfo) {
      const dialCode = countryPinCodeData?.find((country) =>
        shopperinfo?.shopperRef?.startsWith(country.dial_code)
      );
      const phoneNumberWithoutDialCode = dialCode
        ? shopperinfo?.shopperRef.replace(dialCode.dial_code, "")
        : shopperinfo?.shopperRef;
      const normalizedPhoneNumber =
        sessionDetails?.paymentDetails?.shopper?.phoneNumber?.startsWith("+")
          ? sessionDetails?.paymentDetails?.shopper?.phoneNumber
          : `+${sessionDetails?.paymentDetails?.shopper?.phoneNumber}`;

      const country = CountryData.find((country) =>
        normalizedPhoneNumber.startsWith(country?.dial_code)
      );
      if (country) {
        setPhoneDialCode(country.dial_code);
      } else if (!country) {
        setPhoneDialCode("+91");
      }
      setPhoneNumber(phoneNumberWithoutDialCode);
      form.setFieldsValue({ phoneNumber: phoneNumberWithoutDialCode });
    }
  }, [shopperinfo, form]);
  useEffect(() => {
    if (sessionDetails && shopperinfo?.shopperRef === null) {
      const firstName = sessionDetails?.paymentDetails?.shopper?.firstName;
      const lastName = sessionDetails?.paymentDetails?.shopper?.lastName;
      const email = sessionDetails?.paymentDetails?.shopper?.email;
      const normalizedPhoneNumber =
        sessionDetails?.paymentDetails?.shopper?.phoneNumber?.startsWith("+")
          ? sessionDetails?.paymentDetails?.shopper?.phoneNumber
          : `+${sessionDetails?.paymentDetails?.shopper?.phoneNumber}`;

      const dialCode = CountryData?.find((country) =>
        normalizedPhoneNumber?.startsWith(country?.dial_code)
      );
      const phoneNumberWithoutDialCode = dialCode
        ? normalizedPhoneNumber.replace(dialCode?.dial_code, "")
        : sessionDetails?.paymentDetails?.shopper?.phoneNumber;
      const country = CountryData.find((country) =>
        normalizedPhoneNumber.startsWith(country?.dial_code)
      );
      if (country) {
        setPhoneDialCode(country.dial_code);
      } else if (!country) {
        setPhoneDialCode("+91");
      }
      if (firstName && lastName) {
        const fullName = `${firstName} ${lastName}`;
        setFullName(fullName);
        form.setFieldsValue({ fullName: fullName });
      } else if (firstName) {
        setFullName(firstName);
        form.setFieldsValue({ fullName: firstName });
      } else if (lastName) {
        setFullName(lastName);
        form.setFieldsValue({ fullName: lastName });
      } else {
        setFullName("");
        form.setFieldsValue({ fullName: "" });
      }
      setEmailAddress(email);
      form.setFieldsValue({ email: email });

      setPhoneNumber(phoneNumberWithoutDialCode);
      form.setFieldsValue({ phoneNumber: phoneNumberWithoutDialCode });
    }
  }, [sessionDetails, shopperinfo, form]);
  useEffect(() => {
    if (guestCheckout) {
      if (sessionDetails) {
        const normalizedPhoneNumber =
          sessionDetails?.paymentDetails?.shopper?.phoneNumber?.startsWith("+")
            ? sessionDetails?.paymentDetails?.shopper?.phoneNumber
            : `+${sessionDetails?.paymentDetails?.shopper?.phoneNumber}`;

        const dialCode = CountryData?.find((country) =>
          normalizedPhoneNumber?.startsWith(country.dial_code)
        );
        const phoneNumberWithoutDialCode = dialCode
          ? normalizedPhoneNumber.replace(dialCode.dial_code, "")
          : normalizedPhoneNumber;
        setPhoneNumber(phoneNumberWithoutDialCode);
        form.setFieldsValue({ phoneNumber: phoneNumberWithoutDialCode });
      }
    }
  }, [guestCheckout, sessionDetails, form]);
  useEffect(() => {
    if (guestCheckout) {
      if (sessionDetails?.paymentDetails?.shopper?.phoneNumber) {
        const phoneNumber = sessionDetails.paymentDetails.shopper.phoneNumber;
        const normalizedPhoneNumber = phoneNumber.startsWith("+")
          ? phoneNumber
          : `+${phoneNumber}`;

        const country = CountryData.find((country) =>
          normalizedPhoneNumber.startsWith(country.dial_code)
        );
        if (country) {
          setPhoneDialCode(country.dial_code);
        }
      } else {
        setPhoneDialCode("+91");
      }
    }
  }, [guestCheckout, sessionDetails]);

  const validateEmail = (rule, value, callback) => {
    const regex = /^[^\s@']+@[^\s@']+\.[^\s@']+$/;
    if (!value) {
      callback("Required");
    } else if (!regex.test(value)) {
      callback("Invalid Email");
      setDisableButton(true);
    } else {
      callback();
    }
  };

  const handleFullName = (e) => {
    setFullName(e.target.value);
    addressAnalyticsEvent(sessionDetails, dispatch);
  };
  const validatePhoneNumber = (telNo, country) => {
    if (telNo === "") {
      return "Required";
    } else if (country) {
      if (telNo.length !== country.phoneNumberLength) {
        return `Mobile number must be ${country.phoneNumberLength} digits`;
      } else if (telNo.charAt(0) === "0") {
        return "Invalid number";
      }
    }
    return "";
  };

  const handlePhoneChange = (e) => {
    const telNo = e.target.value;
    const re = /^[0-9\b]+$/;
    if (telNo === "" || re.test(telNo)) {
      setPhoneNumber(telNo);
      const country = countryPinCodeData.find(
        (country) => country.dial_code === phoneDialCode
      );
      const error = validatePhoneNumber(telNo, country);
      setPhoneError(error);
      addressAnalyticsEvent(sessionDetails, dispatch);
    }
  };

  const handleEmailAddress = (e) => {
    setEmailAddress(e.target.value);
    addressAnalyticsEvent(sessionDetails, dispatch);
  };
  const handleCountryChange = (value) => {
    setPhoneDialCode(value);
  };

  useEffect(() => {
    const hasError = (showFullName ? !fullName : false)
      || (showContactNumber ? !phoneNumber : false)
      || (showEmailAddress ? !emailAddress : false);
    if (hasError || phoneError?.length) setDisableButton(true);
    else setDisableButton(false);

    if (!showFullName && !showContactNumber && !showEmailAddress && !isShippingAddressDisplay) dispatch(collpaseAddressComponent());
  }, [fullName, phoneNumber, emailAddress, showFullName, showContactNumber, showEmailAddress, phoneError]);

  const handleBasicDetails = () => {
    const selectedCardData = {
      name: fullName,
      phoneNumber: isEmpty(phoneNumber) ? '' : phoneDialCode + phoneNumber,
      email: emailAddress,
    };
    dispatch(updateSavedAddressData(selectedCardData));
    dispatch(updateShopperDetails({ selectedCardData }));
    dispatch(collpaseAddressComponent());
    dispatch(
      getAllPaymentMethods({
        token: sessionDetails?.token,
      })
    );
  };

  useEffect(() => {
    if (showEmailAddress === true) {
      if (
        (sessionDetails?.paymentDetails?.shopper?.firstName ||
          sessionDetails?.paymentDetails?.shopper?.lastName) &&
        sessionDetails?.paymentDetails?.shopper?.phoneNumber &&
        sessionDetails?.paymentDetails?.shopper?.email
      ) {
        const firstName =
          sessionDetails?.paymentDetails?.shopper?.firstName || "";
        const lastName =
          sessionDetails?.paymentDetails?.shopper?.lastName || "";
        const name = [firstName, lastName].filter(Boolean).join(" ");

        const selectedCardData = {
          name,
          phoneNumber: sessionDetails?.paymentDetails?.shopper?.phoneNumber,
          email: sessionDetails?.paymentDetails?.shopper?.email,
        };
        dispatch(updateSavedAddressData(selectedCardData));
        dispatch(updateShopperDetails({ selectedCardData }));
        dispatch(collpaseAddressComponent());
      }
    }
    if (showEmailAddress === false) {
      if (
        (sessionDetails?.paymentDetails?.shopper?.firstName ||
          sessionDetails?.paymentDetails?.shopper?.lastName) &&
        sessionDetails?.paymentDetails?.shopper?.phoneNumber
      ) {
        const firstName =
          sessionDetails?.paymentDetails?.shopper?.firstName || "";
        const lastName =
          sessionDetails?.paymentDetails?.shopper?.lastName || "";
        const name = [firstName, lastName].filter(Boolean).join(" ");

        const selectedCardData = {
          name,
          phoneNumber: sessionDetails?.paymentDetails?.shopper?.phoneNumber,
          email: sessionDetails?.paymentDetails?.shopper?.email,
        };
        dispatch(updateSavedAddressData(selectedCardData));
        dispatch(updateShopperDetails({ selectedCardData }));
        dispatch(collpaseAddressComponent());
      }
    }
  }, [sessionDetails, showEmailAddress]);

  useEffect(() => {
    if (Object.keys(addressFieldValue)?.length) {
      const normalizedPhoneNumber = addressFieldValue?.phoneNumber?.startsWith(
        "+"
      )
        ? addressFieldValue?.phoneNumber
        : `+${addressFieldValue?.phoneNumber}`;

      const dialCode = CountryData?.find((country) =>
        normalizedPhoneNumber?.startsWith(country?.dial_code)
      );
      const phoneNumberWithoutDialCode = dialCode
        ? normalizedPhoneNumber.replace(dialCode?.dial_code, "")
        : normalizedPhoneNumber;
      setFullName(addressFieldValue.name);
      setPhoneNumber(phoneNumberWithoutDialCode);
      setPhoneDialCode(dialCode?.dial_code);
      setEmailAddress(addressFieldValue?.email);
      form.setFieldsValue({
        fullName: addressFieldValue?.name,
        phoneNumber: phoneNumberWithoutDialCode,
        email: addressFieldValue?.email,
      });
    }
  }, [addressFieldValue, form]);

  useEffect(() => {
    const formData = {
      name: fullName,
      email: emailAddress,
      phoneNumber: phoneNumber,
      phoneDialCode: phoneDialCode,
    };
    setPayloadData(formData);
  }, [fullName, emailAddress, phoneDialCode, phoneNumber]);

  return (
    <>
      {
        (showFullName || showContactNumber || showEmailAddress) && (
          <>
            {addressCollpase ? (
              <>
                <div className="deliveryForm">
                  <div className="deliveryAddressHeader">
                    <div className="headerDiv">
                      <UserOutlined />
                      <Typography className="deeliveryHeading dynamic-font-family">
                        Personal Details
                      </Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      backgroundColor: "white",
                      borderRadius: "0px 0px 12px 12px",
                    }}
                  >
                    <CollpaseAddress
                      setShowAddAddressOption={setShowAddAddressOption}
                      setSelectedCardButton={setSelectedCardButton}
                      setAddressFieldValue={setAddressFieldValue}
                    />
                  </div>
                </div>
              </>
            ) : (
              <div className="deliveryForm">
                <div className="deliveryAddressHeader">
                  <div className="headerDiv">
                    <UserOutlined />
                    <Typography className="deeliveryHeading dynamic-font-family">
                      Personal Details
                    </Typography>
                  </div>
                </div>
                <div
                  style={{
                    backgroundColor: "white",
                    paddingTop: "8px",
                    borderRadius: "0px 0px 12px 12px",
                  }}
                >
                  <Form
                    requiredMark={"optional"}
                    layout="vertical"
                    name="addresss"
                    form={form}
                  >
                    <div className="cardPadding">
                      <div className="deliveryAddressFields">
                        {
                          showFullName && (
                            <Form.Item
                              label={
                                <Typography className="regular14Heading deliveryTitle dynamic-font-family">
                                  Full Name*
                                </Typography>
                              }
                              name="fullName"
                              rules={[
                                {
                                  required: true,
                                  message: "Required",
                                },
                              ]}
                            >
                              <>
                                <Input
                                  placeholder="Full Name"
                                  value={fullName}
                                  onChange={handleFullName}
                                />
                              </>
                            </Form.Item>
                          )
                        }
                        {
                          showContactNumber && (
                            <Form.Item
                              label={
                                <Typography className="regular14Heading deliveryTitle dynamic-font-family">
                                  Mobile Number*
                                </Typography>
                              }
                              name="phoneNumber"
                              validateStatus={phoneError ? "error" : ""}
                              help={phoneError}
                              rules={[
                                {
                                  required: true,
                                },
                              ]}
                            >
                              <Space
                                direction="vertical"
                                size="middle"
                                style={{ width: "100%" }}
                              >
                                <Space.Compact>
                                  <Select
                                    className="contactNumber"
                                    showSearch
                                    filterOption={filterCountry}
                                    value={phoneDialCode}
                                    onChange={handleCountryChange}
                                  >
                                    {CountryData.map((country) => (
                                      <Select.Option
                                        key={country.code}
                                        value={country.dial_code}
                                      >
                                        {country.dial_code}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                  <Input
                                    type="number"
                                    value={phoneNumber}
                                    style={{ width: "100%" }}
                                    onKeyDown={blockInvalidChar}
                                    onChange={handlePhoneChange}
                                    placeholder="Mobile number"
                                    inputMode="numeric"
                                    pattern="[0-9]*"
                                  />
                                </Space.Compact>
                              </Space>
                            </Form.Item>
                          )
                        }
                      </div>
                      {showEmailAddress && (
                        <div className="deliveryAddressFields">
                          <Form.Item
                            label={
                              <Typography className="regular14Heading deliveryTitle dynamic-font-family">
                                Email*
                              </Typography>
                            }
                            name="email"
                            rules={[
                              {
                                required: true,
                                validator: validateEmail,
                              },
                            ]}
                          >
                            <>
                              <Input
                                placeholder="Email address"
                                value={emailAddress}
                                onChange={handleEmailAddress}
                              />
                            </>
                          </Form.Item>
                        </div>
                      )}
                    </div>
                    <div
                      className={isMobileLayout ? "" : "continueButtonDiv"}
                      style={{ display: isMobileLayout ? "none" : "" }}
                    >
                      <CustomButton
                        text={"Continue"}
                        buttonType={"primary-btn"}
                        padding={"10px 50px"}
                        onClick={() => handleBasicDetails()}
                        htmlType="submit"
                        disabled={disableButton}
                      />
                    </div>
                    <div style={{ display: isMobileLayout ? "" : "none" }}>
                      <MobileAddressButton
                        disabled={disableButton}
                        showCancelButton={false}
                        setShowAddAddressOption={setShowAddAddressOption}
                        setSelectedAddressItem={""}
                        selectedAddressItem={""}
                        payloadData={payloadData}
                        otherLabel={""}
                        selectedSavedAddress={""}
                        guestCheckout={""}
                        handleGuestData={handleGuestData}
                        handleBasicDetails={handleBasicDetails}
                      />
                    </div>
                  </Form>
                </div>
              </div>
            )}
          </>
        )
      }

    </>
  );
}
