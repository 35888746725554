import { Typography } from "antd";
import React from "react";
import PaymentIntrumentSelection from "../paymentInstrumentSelection/PaymentIntrumentSelection";
import PyamentButton from "../../paymentButton/PaymentButton";
import { selectIsMobileLayout } from "../../../../slices/MobileLayoutSlice";
import { useSelector } from "react-redux";

const Recommended = ({ recommendedMethodInfo }) => {
  const isMobileLayout = useSelector((state) => selectIsMobileLayout(state));
  const slicedRecommendedMethodInfo = recommendedMethodInfo.slice(0, 2);
  return (
    <div className="recommended-root">
      <Typography
        className="dynamic-font-family"
        style={{ display: isMobileLayout ? "none" : "" }}
      >
        Recommended payment options
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          marginTop: isMobileLayout ? "" : "12px",
        }}
      >
        <PaymentIntrumentSelection data={slicedRecommendedMethodInfo} />

        <PyamentButton />
      </div>
    </div>
  );
};

export default Recommended;
