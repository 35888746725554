import React, { useEffect, useState } from "react";
import { Skeleton, Typography } from "antd";
import "./deliveryAddress.css";
import {
  getAllSavedAddress,
  showGoBackButton,
} from "../../../slices/SavedAddressSlice";
import SavedAddress from "./savedAddress";
import { useDispatch, useSelector } from "react-redux";
import AddDeliveryAddress from "./addDeliveryAddress";
import { generateDynamicStyling } from "../../../Utils/utils";
import { PlusCircleOutlined } from "@ant-design/icons";
import {
  collpaseAddressComponent,
  updateShopperDetails,
  selectBaseColor,
  updateSavedAddressData,
  getAllPaymentMethods,
  updatePaymentMethodDictionary,
} from "../../../slices/PaymentSessionDetailsSlice";
import CollpaseAddress from "./collpaseAddress";
import { removePostalData } from "../../../slices/PostalCodeSlice";
import {
  handleGuestCheckout,
  selectMobileNumber,
  selectSessionToken,
} from "../../../slices/ShopperInfoSlice";
import { selectSavedInstruments } from "../../../slices/SavedInstrumentSlice";
import { addressAnalyticsEvent } from "../../../Utils/uiAnalytics";
import BasicDetails from "./basicDetails";

export default function DeliveryAddress() {
  const dispatch = useDispatch();
  const shopperRef = useSelector((state) => selectMobileNumber(state));
  const sessionToken = useSelector((state) => selectSessionToken(state));
  const addressCollpase = useSelector(
    (state) => state.paymentSessionDetails.addressCollpase
  );
  const allSavedAddresses = useSelector(
    (state) => state.savedAddressDetails.allSavedAddresses
  );
  const sessionDetails = useSelector(
    (state) => state.paymentSessionDetails.sessionDetails
  );
  const savedAddress = useSelector(
    (state) => state.savedAddressDetails.savedAddress
  );
  const updatedSavedAddressData = useSelector(
    (state) => state.savedAddressDetails.updatedSavedAddressData
  );
  const paymentMethodsBasedOnCountry = useSelector(
    (state) => state.paymentSessionDetails.paymentMethodsBasedOnCountry
  );
  const latestSavedAddress = useSelector(
    (state) => state.paymentSessionDetails.latestSavedAddress
  );
  const sampleToken = useSelector(
    (state) => state.paymentSessionDetails.sampleToken
  );
  const savedInstruments = useSelector((state) =>
    selectSavedInstruments(state)
  );
  const isShippingAddressDisplay = useSelector(
    (state) => state.paymentSessionDetails.isShippingAddressDisplay
  );
  const showLogin = useSelector(
    (state) => state.additionalComponents.showLogin
  );
  const guestCheckout = useSelector((state) => state.shopperInfo.guestCheckout);
  const baseColor = useSelector((state) => selectBaseColor(state));
  const [loading, setLoading] = useState(true);
  const [componentRendered, setComponentRendered] = useState(false);
  const [showAddAddressOption, setShowAddAddressOption] = useState(true);
  const [selectedCardData, setSelectedCardData] = useState("");
  const [addressFieldValue, setAddressFieldValue] = useState({});
  const [showCancelButton, setShowCancelButton] = useState(false);
  const [editAddressItem, setEditAddressItem] = useState("");
  const [selectedCardButton, setSelectedCardButton] = useState("");
  useEffect(() => {
    setComponentRendered(true);
  }, []);
  useEffect(() => {
    if (sessionDetails) {
      generateDynamicStyling(sessionDetails);
    }
  }, [sessionDetails]);

  useEffect(() => {
    if (componentRendered && !guestCheckout && !sampleToken && showLogin) {
      dispatch(getAllSavedAddress({ shopperRef, sessionToken }))
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    }
  }, [componentRendered, guestCheckout, dispatch, sampleToken, showLogin]);

  useEffect(() => {
    if (guestCheckout) {
      setLoading(false);
    }
  }, [guestCheckout]);
  useEffect(() => {
    if (sampleToken) {
      setLoading(false);
    }
  }, [sampleToken]);
  useEffect(() => {
    if (!showLogin) {
      setLoading(false);
    }
  }, [showLogin]);

  useEffect(() => {
    if (allSavedAddresses?.length > 0) {
      setShowAddAddressOption(false);
      setShowCancelButton(true);
      dispatch(showGoBackButton(true));
    } else {
      setShowAddAddressOption(true);
      setEditAddressItem("");
      setAddressFieldValue({});
      dispatch(removePostalData());
      setShowCancelButton(false);
      dispatch(showGoBackButton(false));
    }
  }, [allSavedAddresses, dispatch]);

  useEffect(() => {
    if (savedAddress && Object.keys(savedAddress)?.length) {
      const selectedCardData = savedAddress;
      setShowAddAddressOption(false);
      dispatch(updateSavedAddressData(selectedCardData));
      dispatch(updateShopperDetails({ selectedCardData }));
      dispatch(collpaseAddressComponent());
      dispatch(
        getAllPaymentMethods({
          token: sessionDetails?.token,
          countryCode: savedAddress?.countryCode,
        })
      );
    }
  }, [savedAddress]);

  const handleSelectedEditAddress = (item) => {
    setEditAddressItem(item);
  };

  const handleSelectedCardData = (item) => {
    setSelectedCardData(item);
    addressAnalyticsEvent(sessionDetails, dispatch);
  };
  const handleButtonClicked = (item) => {
    setSelectedCardButton(item);
  };

  useEffect(() => {
    if (Object.keys(editAddressItem)?.length) {
      setShowAddAddressOption(true);
      setAddressFieldValue(editAddressItem);
      dispatch(removePostalData());
      dispatch(
        getAllPaymentMethods({
          token: sessionDetails?.token,
          countryCode: editAddressItem?.countryCode,
        })
      );
    }
  }, [editAddressItem]);

  useEffect(() => {
    if (
      updatedSavedAddressData &&
      Object.keys(updatedSavedAddressData)?.length > 0
    ) {
      const selectedCardData = updatedSavedAddressData;

      setShowAddAddressOption(false);
      dispatch(updateSavedAddressData(selectedCardData));
      dispatch(updateShopperDetails({ selectedCardData }));
      dispatch(collpaseAddressComponent());
      dispatch(
        getAllPaymentMethods({
          token: sessionDetails?.token,
          countryCode: updatedSavedAddressData?.countryCode,
        })
      );
    }
  }, [updatedSavedAddressData]);

  useEffect(() => {
    if (
      selectedCardData &&
      Object.keys(selectedCardData)?.length > 0 &&
      selectedCardButton
    ) {
      dispatch(updateShopperDetails({ selectedCardData }));
      dispatch(collpaseAddressComponent());
      dispatch(
        getAllPaymentMethods({
          token: sessionDetails?.token,
          countryCode: selectedCardData?.countryCode,
        })
      );
      addressAnalyticsEvent(sessionDetails, dispatch);
    }
  }, [selectedCardData, selectedCardButton]);
  useEffect(() => {
    if (showCancelButton) {
      dispatch(showGoBackButton(true));
    } else {
      dispatch(showGoBackButton(false));
    }
  }, [showCancelButton]);

  const handleGuestData = (item) => {
    const selectedCardData = item;
    setShowAddAddressOption(false);
    dispatch(updateSavedAddressData(selectedCardData));
    dispatch(updateShopperDetails({ selectedCardData }));
    dispatch(collpaseAddressComponent());
  };

  useEffect(() => {
    if (paymentMethodsBasedOnCountry) {
      if (savedInstruments?.length > 0) {
        dispatch(
          updatePaymentMethodDictionary({
            Recommended: savedInstruments,
          })
        );
      } else {
        dispatch(updatePaymentMethodDictionary({}));
      }
    }
  }, [paymentMethodsBasedOnCountry, savedInstruments]);

  useEffect(() => {
    if (sampleToken || showLogin === false) {
      const { firstName, lastName, email, phoneNumber, deliveryAddress } = sessionDetails?.paymentDetails?.shopper;
      if ((firstName || lastName) && email && phoneNumber && firstName && deliveryAddress) {
        const selectedCardData = {
          name: (firstName || '') + " " + (lastName || ''),
          address1: deliveryAddress?.address1,
          address2: deliveryAddress?.address2,
          city: deliveryAddress?.city,
          state: deliveryAddress?.state,
          countryCode: deliveryAddress?.countryCode, postalCode: deliveryAddress?.postalCode,
          email: email,
          phoneNumber: phoneNumber,
        };
        dispatch(handleGuestCheckout(true));
        dispatch(updateSavedAddressData(selectedCardData));
        dispatch(updateShopperDetails({ selectedCardData }));

        dispatch(collpaseAddressComponent());
      }
    }
  }, [sampleToken, sessionDetails, showLogin]);

  if (loading) {
    return <Skeleton active size="large" />;
  }
  return (
    <>
      {isShippingAddressDisplay ? (
        <div className="deliveryForm">
          <div className="deliveryAddressHeader">
            <div className="headerDiv">
              <img
                src="/assets/address/delivery.svg"
                alt="delivery"
                className="truck"
              />
              <Typography className="deeliveryHeading dynamic-font-family">
                Delivery Address
              </Typography>
            </div>
            {!showAddAddressOption && !addressCollpase && (
              <div
                className="addNewAddress"
                onClick={() => {
                  setShowAddAddressOption(true);
                  setAddressFieldValue({});
                  dispatch(removePostalData());
                }}
              >
                <PlusCircleOutlined style={{ color: baseColor }} />
                <Typography
                  className="add-new-address"
                  style={{ color: baseColor }}
                >
                  Add New Address
                </Typography>
              </div>
            )}
          </div>
          <div>
            {addressCollpase && latestSavedAddress ? (
              <CollpaseAddress
                setShowAddAddressOption={setShowAddAddressOption}
                setSelectedCardButton={setSelectedCardButton}
                setAddressFieldValue={setAddressFieldValue}
              />
            ) : (
              <div className="mainAddressDiv">
                {!showAddAddressOption ? (
                  <SavedAddress
                    handleSelectedEditAddress={handleSelectedEditAddress}
                    handleSelectedCardData={handleSelectedCardData}
                    handleButtonClicked={handleButtonClicked}
                    setAddressFieldValue={setAddressFieldValue}
                  />
                ) : (
                  <AddDeliveryAddress
                    addressFieldValue={addressFieldValue}
                    selectedAddressItem={editAddressItem}
                    setShowAddAddressOption={setShowAddAddressOption}
                    showCancelButton={showCancelButton}
                    setSelectedAddressItem={setEditAddressItem}
                    handleGuestData={handleGuestData}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <BasicDetails
          addressFieldValue={addressFieldValue}
          setShowAddAddressOption={setShowAddAddressOption}
          setSelectedCardButton={setSelectedCardButton}
          setAddressFieldValue={setAddressFieldValue}
          handleGuestData={handleGuestData}
        />
      )}
    </>
  );
}
