import React, { useEffect, useState } from "react";
import timer_worker_script from "../../web-workers/timerWorker";
import { formatOtpTime } from "../../Utils/utils";

const ExpiryTimer = ({ duration, callback }) => {
  const [countdown, setCountdown] = useState(duration);
  let showHours = true;

  useEffect(() => {
    const timerWorker = new Worker(timer_worker_script);
    timerWorker.postMessage({ duration: duration });
    timerWorker.onmessage = ({ data }) => {
      if (data === "done") {
        setCountdown(0);
        clearInterval(timerWorker);
        callback();
        timerWorker.terminate();
      } else {
        setCountdown(data);
      }
    };
  }, []);

  return <>{formatOtpTime(countdown, showHours)}</>;
};

export default ExpiryTimer;
