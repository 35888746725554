import React from "react";
import "./subscriptionOrderSummary.css";
import PlansOrderSummary from "./plansOrderSummary/plansOrderSummary";
import SubscriptionProductOrderSummary from "./productOrderSummary/subscriptionProductOrderSummary";
import { useSelector } from "react-redux";

export default function SubscriptionOrderSummary() {
  const sessionDetails = useSelector(
    (state) => state.paymentSessionDetails.sessionDetails
  );
  return (
    <>
      {sessionDetails?.paymentDetails?.subscriptionDetails?.type === "Plan" && (
        <PlansOrderSummary />
      )}
      {sessionDetails?.paymentDetails?.subscriptionDetails?.type ===
        "Product" && <SubscriptionProductOrderSummary />}
    </>
  );
}
