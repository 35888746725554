import React from "react";
import PaymentIntrumentSelection from "../paymentInstrumentSelection/PaymentIntrumentSelection";
import PyamentButton from "../../paymentButton/PaymentButton";

export default function OtherEmi({ emiMethodInfo }) {
  const filteredEmiMethodInfo = emiMethodInfo?.filter(
    (method) => method.brand === "CardlessEMI"
  );

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <PaymentIntrumentSelection data={filteredEmiMethodInfo} />
        <PyamentButton />
      </div>
    </>
  );
}
