import React, { useEffect, useState } from "react";
import Layouts from "../ant-design/templates/domesticCheckout/Layouts";
import MobileLayout from "../ant-design/templates/domesticCheckout/mobileLayout/MobileLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  handleIsShippingAddressDisplay,
  paymentSession,
  selectTemplate,
  setIsSessionInvalid,
  setIsSessionPaid,
} from "../slices/PaymentSessionDetailsSlice";
import {
  generateDynamicStyling,
  getStroage,
  updateStroage,
} from "../Utils/utils";
import {
  selectIsMobileLayout,
  toggleMobileLayout,
  toggleTabletLayout,
} from "../slices/MobileLayoutSlice";
import Navbar from "../ant-design/resuableComponents/navBar/Navbar";
import PaymentSuccess from "../ant-design/components/paymentSuccess/PaymentSuccess";
import { Route, Routes } from "react-router-dom";
import {
  handleContactNumberField,
  handleEmailAddress,
  handleExpiryTime,
  handleFullNameField,
  handleLoginComponent,
  handleMerchantLogo,
  handleOrderItem,
  handleTotalPaymentSummaryField,
} from "../slices/HandleAdditionalComponentsSlice";
import { showFailedModal } from "../slices/PaymentInitiationSlice";

const BoxPayCheckout = () => {
  const isMobileLayout = useSelector((state) => selectIsMobileLayout(state));
  const [token, setToken] = useState(null);
  const dispatch = useDispatch();

  const sessionDetails = useSelector(
    (state) => state.paymentSessionDetails.sessionDetails
  );
  const transactionStatusData = useSelector(
    (state) => state.transactionStatus.transactionStatusData
  );
  const template = useSelector((state) => selectTemplate(state));

  useEffect(() => {
    const currentUrl = window.location.pathname;
    const searchParams = new URLSearchParams(document.location.search);
    if (searchParams.get("token")) {
      if (searchParams.get("token") !== getStroage("session", "tkn")) {
        updateStroage("session", "clear");
      }
      setToken(searchParams.get("token"));
    } else if (currentUrl === "/finalStatus") {
      setToken(sessionStorage.getItem("token"));
    } else if (currentUrl) {
      let result = currentUrl.lastIndexOf("/");
      if (result > -1) {
        const token = currentUrl.substring(result + 1);
        if (token !== getStroage("session", "tkn")) {
          updateStroage("session", "clear");
        }
        setToken(token);
      }
    }
    window.addEventListener("pageshow", function (event) {
      var historyTraversal =
        event.persisted ||
        (typeof window.performance != "undefined" &&
          window.performance.navigation.type === 2);
      if (historyTraversal) {
        // Handle page restore.
        window.location.reload();
      }
    });
  }, []);

  useEffect(() => {
    if (!token && token === null) {
      return;
    }
    updateStroage("session", "update", "tkn", token);
    if (token) dispatch(paymentSession(token));
    else {
      dispatch(setIsSessionInvalid(true));
    }
  }, [token]);

  useEffect(() => {
    if (sessionDetails) {
      generateDynamicStyling(sessionDetails);
    }
  }, [sessionDetails]);

  useEffect(() => {
    if (sessionDetails?.token === "PAID") {
      dispatch(setIsSessionPaid(true));
    } else {
      dispatch(setIsSessionPaid(false));
    }
  }, [sessionDetails]);

  useEffect(() => {
    if (sessionDetails?.status === "PROCESSING") {
      dispatch(setIsSessionPaid(true));
    } else {
      dispatch(setIsSessionPaid(false));
    }
  }, [sessionDetails]);

  useEffect(() => {
    if (
      sessionDetails?.configs?.additionalFieldSets &&
      sessionDetails?.paymentDetails
    ) {
      const additionalFieldSets = sessionDetails.configs.additionalFieldSets;

      if (additionalFieldSets.includes("SHOPPER_LOGIN")) {
        dispatch(handleLoginComponent(true));
      } else {
        dispatch(handleLoginComponent(false));
      }

      if (additionalFieldSets.includes("SHIPPING_ADDRESS")) {
        dispatch(handleIsShippingAddressDisplay(true));
      } else {
        dispatch(handleIsShippingAddressDisplay(false));
      }

      if (additionalFieldSets.includes("MERCHANT_LOGO")) {
        dispatch(handleMerchantLogo(true));
      } else {
        dispatch(handleMerchantLogo(false));
      }

      if (additionalFieldSets.includes("TIMER")) {
        dispatch(handleExpiryTime(true));
      } else {
        dispatch(handleExpiryTime(false));
      }

      if (additionalFieldSets.includes("ORDER_ITEM_DETAILS")) {
        dispatch(handleOrderItem(true));
      } else {
        dispatch(handleOrderItem(false));
      }

      if (additionalFieldSets.includes("SHOPPER_EMAIL")) {
        dispatch(handleEmailAddress(true));
      } else {
        dispatch(handleEmailAddress(false));
      }

      if (additionalFieldSets.includes("SHOPPER_NAME")) {
        dispatch(handleFullNameField(true));
      } else {
        dispatch(handleFullNameField(false));
      }

      if (additionalFieldSets.includes("SHOPPER_PHONE")) {
        dispatch(handleContactNumberField(true));
      } else {
        dispatch(handleContactNumberField(false));
      }

      if (sessionDetails.paymentDetails.onDemandAmount === true) {
        dispatch(handleTotalPaymentSummaryField(true));
      } else {
        dispatch(handleTotalPaymentSummaryField(false));
      }
    }
  }, [sessionDetails]);

  const templateDictionary = {
    domestic_checkout: {
      navBar: <Navbar />,
      checkout: isMobileLayout ? <MobileLayout /> : <Layouts />,
      success: <PaymentSuccess />,
    },
  };

  const randomParamsForHmh = new URLSearchParams(document.location.search);
  const hmh = randomParamsForHmh.get("hmh");

  const randomParams = new URLSearchParams(document.location.search);
  const pns = randomParams.get("pns");
  useEffect(() => {
    if (pns) {
      dispatch(showFailedModal(true));
    }
    if (hmh) {
      const url = new URL(window.location.href);
      url.searchParams.set("hmh", hmh);
      window.history.pushState({}, "", url.toString());
    }
  }, [pns]);

  const getTemplate = (template) => {
    let layout, success, navBar;
    if (template in templateDictionary) {
      layout = templateDictionary[template]?.checkout;
      success = templateDictionary[template]?.success;
      navBar = templateDictionary[template]?.navBar;
    }
    return (
      <>
        {Object.keys(sessionDetails)?.length > 0 && !hmh && navBar}
        <Routes>
          <Route exact path="/" element={layout} />
          <Route exact path="/:token" element={layout} />
          <Route exact path="/finalStatus" element={success} />
        </Routes>
      </>
    );
  };

  return getTemplate("domestic_checkout");
};

export default BoxPayCheckout;
