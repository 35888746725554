import React from "react";
import MobilePaymentNavigation from "../../mobilePaymentNavigation/MobilePaymentNavigation";
import { PlusCircleOutlined } from "@ant-design/icons";
import UpiVpa from "../UpiVpa";
import UpiIntent from "./UpiIntent";

const UpiMobile = ({ upiMethodInfo }) => {
  let upiCollect = upiMethodInfo?.find(
    (upiInfo) => upiInfo.brand === "UpiCollect"
  );

  let upiIntent = upiMethodInfo?.find(
    (upiInfo) => upiInfo.brand === "UpiIntent"
  );
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      {upiIntent && (
        <div className="upi-intent-root">
          <UpiIntent upiData={upiIntent} />
        </div>
      )}
      {upiCollect && (
        <MobilePaymentNavigation
          icon={<PlusCircleOutlined style={{ color: "#4F4D55" }} />}
          title={"Pay via UPI ID"}
          subTitleStyling={true}
          children={<UpiVpa upiData={upiCollect} />}
          collapseKey="Upi"
        />
      )}
    </div>
  );
};

export default UpiMobile;
