/* eslint-disable jsx-a11y/alt-text */
export const getIcon = (brandName) => {
  const mappings = [
    {
      brand: "Mastercard",
      icon: (
        <img
          src="/assets/paymentOptions/Mastercard.svg"
          className="cardLogo"
          style={{
            marginLeft: "2px",
          }}
        />
      ),
    },
    {
      brand: "VISA",
      icon: (
        <img
          src="/assets/paymentOptions/Visa.svg"
          className="cardLogo"
          style={{
            marginLeft: "2px",
          }}
        />
      ),
    },
    {
      brand: "Diners",
      icon: (
        <img
          src="/assets/paymentOptions/diner.svg"
          className="cardLogo"
          style={{
            marginLeft: "2px",
          }}
        />
      ),
    },
    {
      brand: "AmericanExpress",
      icon: (
        <img
          src="/assets/paymentOptions/americanExpress.svg"
          style={{
            marginLeft: "2px",
          }}
          className="cardLogo"
        />
      ),
    },
    {
      brand: "Discover",
      icon: (
        <img
          src="/assets/paymentOptions/Discover.svg"
          className="cardLogo"
          style={{
            marginLeft: "2px",
          }}
        />
      ),
    },
    {
      brand: "JCB",
      icon: (
        <img
          src="/assets/paymentOptions/jcb.svg"
          className="cardLogo"
          style={{
            marginLeft: "2px",
          }}
        />
      ),
    },
    {
      brand: "Maestro",
      icon: (
        <img
          src="/assets/paymentOptions/Maestro.svg"
          className="cardLogo"
          style={{
            marginLeft: "2px",
          }}
        />
      ),
    },
    {
      brand: "Bankcard",
      icon: (
        <img
          src="/assets/paymentOptions/bankCard.svg"
          className="cardLogo"
          style={{
            marginLeft: "2px",
          }}
        />
      ),
    },
    {
      brand: "UATP",
      icon: (
        <img
          src="/assets/paymentOptions/uatp.svg"
          className="cardLogo"
          style={{
            marginLeft: "2px",
          }}
        />
      ),
    },
    {
      brand: "RUPAY",
      icon: (
        <img
          src="/assets/paymentOptions/rupay.svg"
          className="cardLogo"
          style={{
            marginLeft: "2px",
          }}
        />
      ),
    },
  ];

  const icons = mappings
    ?.filter((mapping) => {
      return brandName?.match(mapping?.brand);
    })
    ?.map((mapping) => {
      return mapping?.icon;
    });
  return icons;
};
