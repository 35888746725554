import { uiAnalyticsEvents } from "../slices/UIAnalyticsSlice";

export const checkoutLoadedAnalytics = (sessionDetails, dispatch) => {
  const currentTimestamp = new Date().toUTCString();
  const currentISOTime = new Date(currentTimestamp).toISOString();

  if (
    Object.keys(sessionDetails)?.length &&
    (sessionDetails?.status === "PENDING" ||
      sessionDetails?.status === "FAILED")
  ) {
    if (sessionDetails?.sessionExpiryTimestamp > currentISOTime) {
      const payload = {
        callerToken: sessionDetails?.token,
        uiEvent: "CHECKOUT_LOADED",
      };
      dispatch(uiAnalyticsEvents(payload));
    }
  }
};

export const addressAnalyticsEvent = (sessionDetails, dispatch) => {
  const currentTimestamp = new Date().toUTCString();
  const currentISOTime = new Date(currentTimestamp).toISOString();
  if (sessionDetails?.sessionExpiryTimestamp > currentISOTime) {
    const payload = {
      callerToken: sessionDetails?.token,
      uiEvent: "ADDRESS_UPDATED",
    };
    dispatch(uiAnalyticsEvents(payload));
  }
};

export const categorySelectedEvent = (
  sessionDetails,
  paymentType,
  dispatch
) => {
  const currentTimestamp = new Date().toUTCString();
  const currentISOTime = new Date(currentTimestamp).toISOString();
  if (sessionDetails?.sessionExpiryTimestamp > currentISOTime) {
    const payload = {
      callerToken: sessionDetails?.token,
      uiEvent: "PAYMENT_CATEGORY_SELECTED",
      eventAttrs: {
        paymentType: paymentType,
      },
    };

    dispatch(uiAnalyticsEvents(payload));
  }
};

export const methodSelectedEvent = (
  sessionDetails,
  paymentType,
  paymentSubType,
  dispatch
) => {
  const currentTimestamp = new Date().toUTCString();
  const currentISOTime = new Date(currentTimestamp).toISOString();
  if (sessionDetails?.sessionExpiryTimestamp > currentISOTime) {
    const payload = {
      callerToken: sessionDetails?.token,
      uiEvent: "PAYMENT_METHOD_SELECTED",
      eventAttrs: {
        paymentType: paymentType,
        paymentSubType: paymentSubType,
      },
      if(paymentSubType) {
        payload.paymentSubType = paymentSubType;
      },
    };

    dispatch(uiAnalyticsEvents(payload));
  }
};

export const instrumentProvidedEvent = (
  sessionDetails,
  paymentType,
  paymentSubType,
  dispatch
) => {
  const currentTimestamp = new Date().toUTCString();
  const currentISOTime = new Date(currentTimestamp).toISOString();
  if (sessionDetails?.sessionExpiryTimestamp > currentISOTime) {
    const payload = {
      callerToken: sessionDetails?.token,
      uiEvent: "PAYMENT_INSTRUMENT_PROVIDED",
      eventAttrs: {
        paymentType: paymentType,
        paymentSubType: paymentSubType,
      },
    };

    dispatch(uiAnalyticsEvents(payload));
  }
};

export const paymentInitiatedEvent = (
  sessionDetails,
  paymentType,
  paymentSubType,
  dispatch
) => {
  const currentTimestamp = new Date().toUTCString();
  const currentISOTime = new Date(currentTimestamp).toISOString();
  if (sessionDetails?.sessionExpiryTimestamp > currentISOTime) {
    const payload = {
      callerToken: sessionDetails?.token,
      uiEvent: "PAYMENT_INITIATED",
      eventAttrs: {
        paymentType: paymentType,
        paymentSubType: paymentSubType,
      },
    };

    dispatch(uiAnalyticsEvents(payload));
  }
};
