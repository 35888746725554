import { Typography } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { selectSelectedIntrument } from "../../../../slices/SavedInstrumentSlice";
import {
  selectCurrencyCode,
  selectCurrencySymbol,
} from "../../../../slices/PaymentSessionDetailsSlice";
import getSymbolFromCurrency from "currency-symbol-map";

export default function EmiPlanDetails() {
  const selectedInstrument = useSelector((state) =>
    selectSelectedIntrument(state)
  );
  const currencyCode = useSelector((state) => selectCurrencyCode(state));
  const sessionDetails = useSelector(
    (state) => state.paymentSessionDetails.sessionDetails
  );

  return (
    <>
      <div>
        <div style={{ display: "flex" }}>
          <Typography className="emiDetailsHeading dynamic-font-family">
            Total Cost
          </Typography>
          <Typography className="emiDetailsHeading emiDetailsMargin dynamic-font-family">
            <span
              style={{
                fontFamily: "sans-serif",
                fontWeight: 400,
              }}
            >
              {getSymbolFromCurrency(currencyCode)}{" "}
            </span>
            {selectedInstrument?.emiMethod?.totalAmount}
          </Typography>
        </div>
        <div className="detailsStyling">
          <Typography className="emiDetailsHeading dynamic-font-family">
            Monthly EMI
          </Typography>
          <Typography className="emiDetailsHeading emiDetailsMargin dynamic-font-family">
            <span
              style={{
                fontFamily: "sans-serif",
                fontWeight: 400,
              }}
            >
              {getSymbolFromCurrency(currencyCode)}{" "}
            </span>
            {selectedInstrument?.emiMethod?.emiAmountLocaleFull}
          </Typography>
        </div>
        <div className="detailsStyling">
          <Typography className="emiDetailsHeading dynamic-font-family">
            Total Interest (Charged by Bank)
          </Typography>
          <Typography className="emiDetailsHeading emiDetailsMargin dynamic-font-family">
            <span
              style={{
                fontFamily: "sans-serif",
                fontWeight: 400,
              }}
            >
              {getSymbolFromCurrency(currencyCode)}{" "}
            </span>
            {selectedInstrument?.emiMethod?.interestChargedAmount}
          </Typography>
        </div>
        {selectedInstrument?.emiMethod?.processingFee && (
          <div className="detailsStyling">
            <Typography className="emiDetailsHeading dynamic-font-family">
              Processing fee
            </Typography>
            <Typography className="emiDetailsHeading emiDetailsMargin dynamic-font-family">
              <span
                style={{
                  fontFamily: "sans-serif",
                  fontWeight: 500,
                }}
              >
                {getSymbolFromCurrency(currencyCode)}{" "}
              </span>
              {selectedInstrument?.emiMethod?.processingFee?.amount}
            </Typography>
          </div>
        )}
        <div style={{ display: "flex", margin: "8px 0px 12px 0px" }}>
          <Typography className="noteHeading dynamic-font-family">
            Your card will be charged for an amount of{" "}
            <span style={{ fontWeight: 700 }}>
              <span
                style={{
                  fontFamily: "sans-serif",
                }}
              >
                {getSymbolFromCurrency(currencyCode)}{" "}
              </span>
              {sessionDetails?.paymentDetails?.money?.amountLocaleFull}.
            </span>{" "}
            You will be charged an interest of{" "}
            <span style={{ fontWeight: 700 }}>
              <span
                style={{
                  fontFamily: "sans-serif",
                }}
              >
                {getSymbolFromCurrency(currencyCode)}{" "}
              </span>
              {selectedInstrument?.emiMethod?.interestChargedAmountLocaleFull}{" "}
            </span>
            by the bank making the total payable amount as{" "}
            <span style={{ fontWeight: 700 }}>
              <span
                style={{
                  fontFamily: "sans-serif",
                }}
              >
                {getSymbolFromCurrency(currencyCode)}{" "}
              </span>
              {selectedInstrument?.emiMethod?.totalAmountLocaleFull}.
            </span>
          </Typography>
        </div>
        {selectedInstrument?.emiMethod?.processingFee && (
          <>
            <div style={{ display: "flex", margin: "8px 0px 12px 0px" }}>
              <Typography className="noteHeading dynamic-font-family">
                A processing fee of{" "}
                <span style={{ fontWeight: 700 }}>
                  <span
                    style={{
                      fontFamily: "sans-serif",
                    }}
                  >
                    {getSymbolFromCurrency(currencyCode)}{" "}
                  </span>
                  {
                    selectedInstrument?.emiMethod?.processingFee
                      ?.amountLocaleFull
                  }{" "}
                  + GST
                </span>{" "}
                as applicable by the bank on the successful EMI transactions.{" "}
              </Typography>
            </div>
          </>
        )}
      </div>
    </>
  );
}
