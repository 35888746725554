import { Typography } from "antd";
import React from "react";
import "./dataContainer.css";

const DataContainer = ({ title, description }) => {
  return (
    <div className="data-container-root">
      <div className="header">
        <Typography className="data-container-title">{title}</Typography>
      </div>
      <div className="description">{description}</div>
    </div>
  );
};

export default DataContainer;
