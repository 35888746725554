import React, { useState, useEffect } from "react";
import { Collapse, Typography } from "antd";
import { useSelector } from "react-redux";
import { generateDynamicStyling, numberToCurrency } from "../../../Utils/utils";
import {
  selectCurrencyCode,
  selectCurrencySymbol,
  selectToatalAmount,
} from "../../../slices/PaymentSessionDetailsSlice";
import "../fontStyling.css";
import getSymbolFromCurrency from "currency-symbol-map";

export const PaymentSummaryMobileContainer = ({ children }) => {
  const currencyCode = useSelector((state) => selectCurrencyCode(state));
  const currencySymbol = useSelector((state) => selectCurrencySymbol(state));
  const amount = useSelector((state) => selectToatalAmount(state));
  const showTotalPaymentSummaryField = useSelector(
    (state) => state.additionalComponents.showTotalPaymentSummaryField
  );

  const [activeKey, setActiveKey] = useState(
    showTotalPaymentSummaryField ? "paymentSummary" : null
  );

  useEffect(() => {
    setActiveKey(showTotalPaymentSummaryField ? "paymentSummary" : null);
  }, [showTotalPaymentSummaryField]);

  const handleCollapseChange = (key) => {
    setActiveKey(key);
  };

  const getExtra = () => {
    return (
      <Typography
        className="semiBold12Heading dynamic-font-family"
        style={{ color: "var(--Neutral-Grey-1000)" }}
      >
        <span className="currency-symbol">
          {getSymbolFromCurrency(currencyCode)}{" "}
        </span>
        {numberToCurrency(amount, currencyCode)}
      </Typography>
    );
  };

  return (
    <Collapse
      expandIconPosition="end"
      accordion
      activeKey={activeKey}
      onChange={handleCollapseChange}
      items={[
        {
          key: "paymentSummary",
          label: (
            <Typography className="domestic-ps-mobile-header dynamic-font-family">
              Payment Summary
            </Typography>
          ),
          children: <div className="dynamic-font-family">{children}</div>,
          extra: !showTotalPaymentSummaryField && getExtra(),
        },
      ]}
    />
  );
};
