import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const getTransactionStatus = createAsyncThunk(
  "transactionStatus/getTransactionStatus",
  async ({ token, transactionId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${baseUrl}/checkout/sessions/${token}/transactions/${transactionId}/status`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
  data: null,
  loading: false,
  error: null,
  transactionStatusData: undefined,
};

export const transactionStatusSlice = createSlice({
  name: "transactionStatus",
  initialState,
  reducers: {
    resetTransactionStatus(state, action) {
      state.transactionStatusData = undefined;
    },
  },
  extraReducers: {
    [getTransactionStatus.pending]: (state) => {
      state.loading = true;
    },
    [getTransactionStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.transactionStatusData = action.payload;
    },
    [getTransactionStatus.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.payload;
    },
  },
});
export const { resetTransactionStatus } = transactionStatusSlice.actions;

export const selectTransactionData = (state) =>
  state.transactionStatus.transactionStatusData;
export default transactionStatusSlice.reducer;
