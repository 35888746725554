import React from "react";
import { Typography } from "antd";
import "./errorPage.css";

export default function ErrorPage({ imgUrl, heading, subHeading }) {
  return (
    <div className="errorMainDiv">
      <img src={imgUrl} alt="img" />
      <Typography className="errormainHeading">{heading}</Typography>
      <Typography className="errorSubHeading">{subHeading}</Typography>
    </div>
  );
}
