import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cardNumber: "",
  cardHolderName: "",
  cardExpiryDate: "",
  cardCvc: "",
  latestBrandName: "",
  buttonDisableState: false,
};

export const cardDetailsSlice = createSlice({
  name: "cardDetails",
  initialState,
  reducers: {
    changeCardNumber(state, action) {
      state.cardNumber = action.payload;
    },
    changeCardHolderName(state, action) {
      state.cardHolderName = action.payload;
    },
    changeExpiryDate(state, action) {
      state.cardExpiryDate = action.payload;
    },
    changeCardCvc(state, action) {
      state.cardCvc = action.payload;
    },
    changeBrandName(state, action) {
      state.latestBrandName = action.payload;
    },
    cardPayButtonState(state, action) {
      state.buttonDisableState = action.payload;
    },
  },
});
export const {
  changeCardNumber,
  changeCardHolderName,
  changeExpiryDate,
  changeCardCvc,
  changeBrandName,
  cardPayButtonState,
} = cardDetailsSlice.actions;

export const cardNumberData = (state) => state.cardDetails.cardNumber;
export const cardHolderNameData = (state) => state.cardDetails.cardHolderName;
export const cardExpiryDateData = (state) => state.cardDetails.cardExpiryDate;
export const cardCvcData = (state) => state.cardDetails.cardCvc;
export const cardbrandName = (state) => state.cardDetails.latestBrandName;
export const disableStateOfButton = (state) =>
  state.cardDetails.buttonDisableState;

export default cardDetailsSlice.reducer;
