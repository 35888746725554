/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Tooltip, Typography } from "antd";
import {
  formatExpiry,
  isValidCardNumber,
  isValidExpiryDate,
  formatCardNumber,
  isEmpty
} from "../../../../Utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { getCardBinData, resetCardBinData } from "../../../../slices/BinSlice";
import { CreditCardFilled } from "@ant-design/icons";
import { getIcon } from "./getCardLogos";
import { selectIsMobileLayout } from "../../../../slices/MobileLayoutSlice";
import PyamentButton from "../../paymentButton/PaymentButton";
import {
  changeCardNumber,
  changeCardHolderName,
  changeExpiryDate,
  changeCardCvc,
  changeBrandName,
  cardPayButtonState,
} from "../../../../slices/CardSlice";
import { selectSelectedIntrument } from "../../../../slices/SavedInstrumentSlice";
import { handleEmiPayButton } from "../../../../slices/EmiSlice";
import { instrumentProvidedEvent } from "../../../../Utils/uiAnalytics";

const CARD_CHECK_LENGTH = 9;
const CARD_MAX_LENGTH = 16;

export default function CardPaymentForm(props) {
  const { paymentType } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const sessionDetails = useSelector((state) => state.paymentSessionDetails.sessionDetails);
  const isMobileLayout = useSelector((state) => selectIsMobileLayout(state));
  const cardBinData = useSelector((state) => state.binDetails.cardBinData);
  const [cardNumber, setCardNumber] = useState("");
  const [nameOnCard, setNameOnCard] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvcOnCard, setCvcOnCard] = useState("");
  const [brandName, setBrandName] = useState("");
  const [methodEnabled, setMethodEnabled] = useState("");
  const [cardNumberError, setCardNumberError] = useState("");
  const [expiryDateError, setExpiryDateError] = useState("");
  const [nameError, setNameError] = useState("");
  const [cvcError, setCvcError] = useState("");
  const selectedInstrument = useSelector((state) => selectSelectedIntrument(state));
  const token = sessionDetails?.token;
  const icons = getIcon(brandName);
  const isPasted = useRef(false);

  const getCVVlength = () => {
    return brandName === "AmericanExpress" ? 4 : 3
  }
  useEffect(() => {
    if (!isEmpty(sessionDetails?.paymentDetails?.shopper)) {
      const { firstName, lastName } = sessionDetails?.paymentDetails?.shopper;
      const fullName = `${firstName || ''} ${lastName || ''}`
      setNameOnCard(fullName);
    }
  }, [sessionDetails]);
  const handleCardNumber = (e) => {
    e.target.value = e.target.value.replace(/\D/g, "");
    e.target.value = e.target.value.slice(0, CARD_MAX_LENGTH);
    const { value } = e.target;

    /* adds space every 4 digits 22223333 -> 2222 3333 */
    const formattedValue = formatCardNumber(value, brandName);
    setCardNumber(formattedValue);
    dispatch(changeCardNumber(value));

    // AUTOFILL workaround
    if (value.length === CARD_MAX_LENGTH) {
      dispatch(getCardBinData({ token, inputData: value }));
      if (!isValidCardNumber(value) || isEmpty(value)) {
        setCardNumberError(isEmpty(value) ? "Required" : "Invalid card number");
      } else {
        setCardNumberError(''); dispatch(resetCardBinData());
      }
    }
    // AUTOFILL workaround

    /*** when card length greater than CARD_CHECK_LENGTH then check card scheme and also if the payment is allowed for this card. ***/
    if ((value.length >= CARD_CHECK_LENGTH && isEmpty(cardBinData)) || (isPasted.current && value.length >= CARD_CHECK_LENGTH)) {
      dispatch(getCardBinData({ token, inputData: value }));
    } else if (value.length < CARD_CHECK_LENGTH) {
      dispatch(resetCardBinData());
    }

    /* set form data */
    form.setFieldsValue({ cardNumber: formattedValue });
    if (formattedValue?.length < CARD_CHECK_LENGTH) setBrandName("");
    instrumentProvidedEvent(sessionDetails, paymentType, brandName, dispatch);
  };
  useEffect(() => {
    /* sets card scheme icon if the card is supported */
    const sanitizedCard = cardNumber.replaceAll(" ", "");
    if (!isEmpty(cardBinData)) {
      const brands = cardBinData?.paymentMethod?.brand;
      const methodEnabled = cardBinData?.methodEnabled;

      setMethodEnabled(methodEnabled);
      setBrandName(brands);
    } else if (sanitizedCard?.length < CARD_CHECK_LENGTH || sanitizedCard?.length === 1) {
      setBrandName("");
      form.setFieldsValue({ cvcOnCard: "" });
      setCvcOnCard("");
    }
  }, [cardBinData]);
  const handleNameOnCard = (e) => {
    const name = e.target.value;
    if (name) setNameError(""); // AUTOFILL workaround
    setNameOnCard(name);
    dispatch(changeCardHolderName(name));
    instrumentProvidedEvent(
      sessionDetails,
      paymentType,
      brandName,
      dispatch
    );
  };
  const handleExpiryDate = (e) => {
    const { value } = e.target;

    // AUTOFILL workaround
    if (value.length === 5) {
      if ((!isValidExpiryDate(value) || isEmpty(value))) setExpiryDateError(isEmpty(value) ? "Required" : "Invalid expiry");
      else setExpiryDateError('')
    }
    // AUTOFILL workaround

    const formattedValue = formatExpiry(value);
    setExpiryDate(formattedValue);
    dispatch(changeExpiryDate(formattedValue));
    form.setFieldsValue({ expiryDate: formattedValue });
    instrumentProvidedEvent(
      sessionDetails,
      paymentType,
      brandName,
      dispatch
    );
  };
  const handleCvcCard = (e) => {
    let inputValue = e.target.value.replace(/\D/g, "");
    setCvcOnCard(inputValue);
    dispatch(changeCardCvc(inputValue));
    form.setFieldsValue({ cvcOnCard: inputValue });
    instrumentProvidedEvent(
      sessionDetails,
      paymentType,
      brandName,
      dispatch
    );
  };
  const getPayButtonState = () => {
    if (isValidCardNumber(cardNumber.replaceAll(" ", ""))
      && isValidExpiryDate(expiryDate)
      && !isEmpty(nameOnCard)
      && getCVVlength() === cvcOnCard.length) {
      dispatch(cardPayButtonState(false));
    } else {
      dispatch(cardPayButtonState(true));
    }
  }
  useEffect(() => {
    if (brandName && methodEnabled === false) {
      setCardNumberError("This card is not supported for the payment");
      dispatch(cardPayButtonState(true));
    } else {
      getPayButtonState();
    }
  }, [
    cardNumber,
    nameOnCard,
    expiryDate,
    cvcOnCard,
    cardNumberError,
    expiryDateError,
    cvcError,
    brandName,
    methodEnabled,
  ]);
  useEffect(() => {
    if (brandName) {
      dispatch(changeBrandName(brandName));
    }
  }, [brandName]);
  useEffect(() => {
    if (paymentType === "Emi") {
      if (selectedInstrument?.brand !== "CardlessEmi") {
        if (
          cardNumber &&
          nameOnCard &&
          expiryDate &&
          cvcOnCard &&
          selectedInstrument
        ) {
          dispatch(handleEmiPayButton(false));
        }
        if (
          cardNumber?.length === 0 ||
          nameOnCard?.length === 0 ||
          expiryDate?.length === 0 ||
          cvcOnCard?.length === 0 ||
          cardNumberError?.length ||
          expiryDateError?.length ||
          cvcError?.length ||
          nameOnCard?.length === 0
        ) {
          dispatch(handleEmiPayButton(true));
        }

        if (brandName && methodEnabled === false) {
          dispatch(handleEmiPayButton(true));
        }
      }
    }
  }, [
    paymentType,
    selectedInstrument,
    cardNumber,
    nameOnCard,
    expiryDate,
    cvcOnCard,
    cardNumberError,
    expiryDateError,
    cvcError,
    brandName,
    methodEnabled,
  ]);
  useEffect(() => {
    return () => {
      dispatch(resetCardBinData());
    }
  }, [])

  return (
    <>
      <Form
        requiredMark={"optional"}
        layout="vertical"
        name="addresss"
        form={form}
        autoComplete="off"
      >
        <div className="domestic-pi-selection-root cardNumberField">
          <Form.Item
            label={
              <Typography className="regular14Heading deliveryTitle dynamic-font-family">
                Card Number*
              </Typography>
            }
            name="cardNumber"
            validateTrigger="onBlur"
            rules={[
              {
                required: true,
                message: "Required",
              },
              {
                validator: (_, value) => {
                  if (!isValidCardNumber(value) || isEmpty(value)) return setCardNumberError(isEmpty(value) ? "Required" : "Invalid card number"), Promise.reject();
                  else return setCardNumberError(""), Promise.resolve();
                },
              },
            ]}
            validateStatus={cardNumberError ? "error" : ""}
            help={cardNumberError}
          >
            <>
              <Input
                autoComplete="new-password"
                placeholder="Enter your card number here"
                value={cardNumber}
                id="cardNumberInput"
                onFocus={() => setCardNumberError("")}
                onChange={handleCardNumber}
                onPaste={() => isPasted.current = true}
                inputMode="numeric"
                pattern="[0-9]*"
                addonAfter={
                  icons && icons?.length > 0 ? (
                    icons[0]
                  ) : (
                    <CreditCardFilled className="defaultCardColor" />
                  )
                }
              />
            </>
          </Form.Item>
          <Form.Item
            label={
              <Typography className="regular14Heading deliveryTitle dynamic-font-family">
                Name on Card*
              </Typography>
            }
            name="nameOnCard"
            validateTrigger="onBlur"
            rules={[
              {
                required: true,
                message: "Required",
              },
              {
                validator: (_, value) => {
                  if (!value) return setNameError("Required"), Promise.reject();
                  else return setNameError(""), Promise.resolve();
                },
              },
            ]}
            validateStatus={nameError ? "error" : ""}
            help={nameError}
          >
            <>
              <Input
                placeholder="Enter your name"
                value={nameOnCard}
                onFocus={() => setNameError("")}
                onChange={handleNameOnCard}
              />
            </>
          </Form.Item>
          <div className="cvvDiv">
            <Form.Item
              label={
                <Typography className="regular14Heading deliveryTitle dynamic-font-family">
                  Expiry Date*
                </Typography>
              }
              name="expiryDate"
              validateTrigger="onBlur"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
                {
                  validator: (_, value) => {
                    if (!isValidExpiryDate(value) || isEmpty(value)) return setExpiryDateError(isEmpty(value) ? "Required" : "Invalid expiry"), Promise.reject("Required");
                    else return setExpiryDateError(""), Promise.resolve();
                  },
                },
              ]}
              validateStatus={expiryDateError ? "error" : ""}
              help={expiryDateError}
            >
              <>
                <Input
                  placeholder="Month/Year"
                  value={expiryDate}
                  onChange={handleExpiryDate}
                  onFocus={() => setExpiryDateError("")}
                  inputMode="numeric"
                  pattern="[0-9]*"
                />
              </>
            </Form.Item>
            <Form.Item
              label={
                <>
                  <Typography className="regular14Heading deliveryTitle dynamic-font-family">
                    Card CVV*
                  </Typography>
                  <Tooltip
                    placement="right"
                    title="It's a 3-digit code on the back of your card.
In case of American Express, it's a 4-digit code on the front of your card."
                  >
                    <img
                      src="/assets/address/help-circle.svg"
                      alt="help-circle"
                      style={{ marginLeft: "4px" }}
                    />
                  </Tooltip>
                </>
              }
              name="cvcOnCard"
              validateTrigger="onBlur"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
                {
                  validator: (_, value) => {
                    let cvcLength = getCVVlength();
                    // let cvcLength = brandName === "AmericanExpress" ? 4 : 3;
                    if (value.length !== cvcLength || isEmpty(value))
                      return setCvcError(isEmpty(value) ? "Required" : "Invalid CVV"), Promise.reject("Required");

                  },
                },
              ]}
              validateStatus={cvcError ? "error" : ""}
              help={cvcError}
            >
              <>
                <Input
                  type="password"
                  placeholder="Enter Card CVV"
                  value={cvcOnCard}
                  onChange={handleCvcCard}
                  maxLength={getCVVlength()}
                  onFocus={() => setCvcError("")}
                  inputMode="numeric"
                  pattern="[0-9]*"
                />
              </>
            </Form.Item>
          </div>
          <div
            className="buttonDiv"
            style={{ display: isMobileLayout ? "none" : "" }}
          >
            <PyamentButton />
          </div>
        </div>
      </Form>
    </>
  );
}
