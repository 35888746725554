import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showLogin: "",
  isMerchantLogo: "",
  showOrderItem: "",
  showEmailAddress: "",
  showFullName: "",
  showContactNumber: "",
  showTotalPaymentSummaryField: false,
  totalPaymentSummaryValue: "",
  showTotalPaymentSummaryError: "",
};

export const additionalComponentsSlice = createSlice({
  name: "additionalComponents",
  initialState,
  reducers: {
    handleLoginComponent(state, action) {
      state.showLogin = action.payload;
    },
    handleMerchantLogo(state, action) {
      state.isMerchantLogo = action.payload;
    },
    handleExpiryTime(state, action) {
      state.isShowTimerExpiry = action.payload;
    },
    handleOrderItem(state, action) {
      state.showOrderItem = action.payload;
    },
    handleEmailAddress(state, action) {
      state.showEmailAddress = action.payload;
    },
    handleFullNameField(state, action) {
      state.showFullName = action.payload;
    },
    handleContactNumberField(state, action) {
      state.showContactNumber = action.payload;
    },
    handleTotalPaymentSummaryField(state, action) {
      state.showTotalPaymentSummaryField = action.payload;
    },
    handleTotalPaymentSummaryValue(state, action) {
      state.totalPaymentSummaryValue = action.payload;
    },
    handleTotalPaymentSummaryError(state, action) {
      state.showTotalPaymentSummaryError = action.payload;
    },
  },
});
export const {
  handleLoginComponent,
  handleMerchantLogo,
  handleExpiryTime,
  handleOrderItem,
  handleEmailAddress,
  handleFullNameField,
  handleContactNumberField,
  handleTotalPaymentSummaryField,
  handleTotalPaymentSummaryValue,
  handleTotalPaymentSummaryError,
} = additionalComponentsSlice.actions;

export default additionalComponentsSlice.reducer;
