import React from "react";
import UtilityForm from "../components/form/UtilityForm";
import "./home.css";

const Home = () => {
  return (
    <div className="home-root">
      <div className="form-item">
        <UtilityForm />
      </div>
    </div>
  );
};

export default Home;
