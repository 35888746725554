import { Button, ConfigProvider, Input, Select, Typography } from "antd";
import React, { useState } from "react";
import { utilityOptions } from "../../constants";
import "./utilityForm.css";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const UtilityForm = () => {
  const [selectUtiltiy, setSelectedUtility] = useState();
  const [amount, setAmount] = useState();
  const [appartment, setAppartment] = useState();
  const [error, setError] = useState(null);

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const onChange = (value) => {
    setSelectedUtility(value);
  };

  const createSession = async () => {
    const response = await fetch(`${baseUrl}/kutumb/checkout/sessions`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        "money": {
          "amount": amount,
          "currencyCode": "INR"
        },
        "utilityType": selectUtiltiy,
        "apartmentNumber": appartment,
        "referer": window.location.href.replace("http:", "https:")
      })
    });

    return response.json();
  }

  const handleSubmit = () => {
    if (!selectUtiltiy || !amount || !appartment) {
      setError("Please enter all the details");
    } else {
      setError(null);

      createSession()
        .then(res => {
          window.location.href = res.url;
        });
    }
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <div className="form-root">
      <div className="form-header">
        <Typography className="form-header-text">Pay Utility Bills</Typography>
      </div>
      <div className="form-content">
        <Select
          style={{
            height: "59px",
          }}
          className="kutumb-form-field-search"
          showSearch
          placeholder="Utility Type"
          optionFilterProp="children"
          onChange={onChange}
          filterOption={filterOption}
          options={utilityOptions}
        />
        <Input
          className="kutumb-form-field"
          placeholder="Apartment Number"
          type="number"
          onChange={(e) => {
            setAppartment(e.target.value);
          }}
        />
        <Input
          className="kutumb-form-field"
          placeholder="Amount"
          type="number"
          onChange={(e) => {
            setAmount(e.target.value);
          }}
        />
      </div>
      <div className="form-button">
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#C07C3F",
              borderRadius: 2,
              colorBgContainer: "#BFBDC1",
            },
          }}
        >
          <Button
            type="primary"
            block
            className="kutumb-btn"
            onClick={handleSubmit}
          >
            Pay Now
          </Button>
        </ConfigProvider>
        {(!selectUtiltiy || !amount || !appartment) && error && (
          <div className="error" >
            <ExclamationCircleOutlined />
            <Typography className="error">{error}</Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default UtilityForm;
