import React, { useEffect, useState } from "react";
import "./card.css";
import { useDispatch, useSelector } from "react-redux";
import { selectSavedInstruments } from "../../../../slices/SavedInstrumentSlice";
import ViewSavedInstrumentsHeader from "../../modals/viewSavedInstrumentsModal/ViewSavedInstrumentsHeader";
import OrComponent from "../../../resuableComponents/orUIComponent/OrComponent";
import CardPaymentForm from "./cardPaymentForm";
import { methodSelectedEvent } from "../../../../Utils/uiAnalytics";

export default function CardPaymentOption({ cardMethodInfo }) {
  const dispatch = useDispatch();
  const [componentRender, setComponentRender] = useState(false);
  const savedInstruments = useSelector((state) =>
    selectSavedInstruments(state)
  );
  const sessionDetails = useSelector(
    (state) => state.paymentSessionDetails.sessionDetails
  );
  const [cardInstruments, setCardInstruments] = useState([]);

  useEffect(() => {
    setComponentRender(true);
  }, []);
  useEffect(() => {
    if (componentRender) {
      methodSelectedEvent(sessionDetails, "Card", "", dispatch);
    }
  }, [componentRender]);
  useEffect(() => {
    if (savedInstruments) {
      let cardMethod = savedInstruments?.filter(
        (instrumentInfo) => instrumentInfo?.brand === "Card"
      );
      setCardInstruments(cardMethod);
    }
  }, [savedInstruments]);
  let cardMethod = cardMethodInfo?.find(
    (cardInfo) => cardInfo.brand === "Card"
  );

  return (
    <div className="deliveryForm">
      {cardInstruments?.length > 0 && (
        <ViewSavedInstrumentsHeader
          data={cardInstruments}
          title={"Saved Card"}
          type={"Card"}
        />
      )}
      {cardMethod && cardInstruments?.length > 0 && <OrComponent />}
      <CardPaymentForm />
    </div>
  );
}
