import { createSlice } from "@reduxjs/toolkit";

export const apiErrorSlice = createSlice({
  name: "apiError",
  initialState: {
    error: null,
    unauthorised: null,
  },
  reducers: {
    setApiError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => action.type.endsWith("/rejected"),
      (state, action) => {
        state.error = action.error.message;
        if (
          action?.payload?.status === 401 ||
          action?.error?.message?.includes("401")
        ) {
          state.unauthorised = true;
        }
      }
    );
  },
});

export const { setApiError } = apiErrorSlice.actions;

export const selectApiError = (state) => state.apiError.error;
export const selectUnauthorised = (state) => state.apiError.unauthorised;

export default apiErrorSlice.reducer;
